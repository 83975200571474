import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)
const payment = {
  namespaced: true,
  state: {
  },
  mutations: {

  },
  actions: {
    OrderPayment(context, data){
      try {
          return  axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/order/sign/payment", data,{
              headers:{
                'Content-Type': 'application/json',
                'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
              }
            })
        } catch (error) {
          return null
        }
      },
    },

  getters:{
  }
}

export default payment;