import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import CryptoJS from 'crypto-js'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)
const Product = {
    namespaced : true,
    state:{
        AllProduct:[],
        AllProductType:[],
        AllBestSellerProduct:[],
        AllLatestProduct:[],
        AllRecommendProduct:[],
        selectedproduct: window.localStorage.getItem('selectedProduct')?window.localStorage.getItem('selectedProduct'):'',
        CheckIf_AllProduct_NotGetData: true,
        CheckIf_AllProductType_NotGetData: true,
        CheckIf_AllBestSeller_NotGetData: true,
        CheckIf_AllLatestProduct_NotGetData: true,
        CheckIf_AllRecommendProduct_NotGetData: true,
        IsLastProductItem : false
    },
    mutations:{
        SetAllProduct(state, data){
            state.AllProduct = data
        },
        SetAllProductType(state, data){
            state.AllProductType = data
        },
        SetIsLastProductItem(state, data){
            state.IsLastProductItem = data
        },
        SetAllBestSellerProduct(state, data){
            state.AllBestSellerProduct = data
        },
        SetAllLatestProduct(state, data){
            state.AllLatestProduct = data
        },
        SetAllRecommendProduct(state, data){
            state.AllRecommendProduct = data
        },
        SetSelectedProduct(state, data){
          state.selectedproduct = data
        },


        SetCheckIf_AllProduct_NotGetData(state, data){
          state.CheckIf_AllProduct_NotGetData = data
        },
        SetCheckIf_ProductType_NotGetData(state, data){
          state.CheckIf_AllProductType_NotGetData = data
        },
        SetCheckIf_AllBestSeller_NotGetData(state, data){
          state.CheckIf_AllBestSeller_NotGetData = data
        },
        SetCheckIf_AllLatestProduct_NotGetData(state, data){
          state.CheckIf_AllLatestProduct_NotGetData = data
        },
        SetCheckIf_AllRecommendProduct_NotGetData(state, data){
          state.CheckIf_AllRecommendProduct_NotGetData = data
        }
    },
    actions:{
        GetAllProduct(context, limit){
                 axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/mix/all/"+limit+"/limit", {
                  headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                  }
                }).then((res)=>{
                    const Respon = res['data'];
                    // console.log(Respon);
                    if(Respon['success'] && Respon['code'] == 200){
                      var ResponAllProduct = Respon['data']['all'];
                      var priceList=[];
                      var unitList=[];
                      var imageList=[];
                      if(ResponAllProduct.length > 0){
                        for(let i=0; i < ResponAllProduct.length; i++){
                          priceList = eval("["+ ResponAllProduct[i]["price_detail"] +"]");
                          unitList = eval("["+ ResponAllProduct[i]["unit_detail"] +"]");
                          imageList = JSON.parse( ResponAllProduct[i]["product_img"]);
                          ResponAllProduct[i]["priceList"] = priceList;
                          ResponAllProduct[i]["unitList"] = unitList;
                          ResponAllProduct[i]["imageList"] = imageList;
                        }
                        context.commit('SetAllProduct',ResponAllProduct)
                      }else{
                          context.commit('SetCheckIf_AllProduct_NotGetData',false);
                      }
                    }
                })
        }, 
        GetNewProduct(context, limit){
              return  axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/mix/all/"+limit+"/limit", {
                  headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                  }
                })
        }, 

        /*
       GetAllProductByCategory(context, data){
                axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/by/category/"+data.id+"/"+data.limmit+"/limit", {  // get all product but not separated by category
                  headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                  }
                }).then((res)=>{
                    const Respon = res['data'];
                    console.log("000000000000000000000");
                    console.log(data);
                    console.log(Respon);
                    if(Respon['success'] && Respon['code'] == 200){
                      var ResponAllProduct = Respon['data']['all'];
                      var priceList=[];
                      var unitList=[];
                      var imageList=[];
                      if(ResponAllProduct.length > 0){
                        for(let i=0; i < ResponAllProduct.length; i++){
                          priceList = eval("["+ ResponAllProduct[i]["price_detail"] +"]");
                          unitList = eval("["+ ResponAllProduct[i]["unit_detail"] +"]");
                          imageList = JSON.parse( ResponAllProduct[i]["product_img"]);
                          ResponAllProduct[i]["priceList"] = priceList;
                          ResponAllProduct[i]["unitList"] = unitList;
                          ResponAllProduct[i]["imageList"] = imageList;
                        }
                        context.commit('SetAllProduct',ResponAllProduct)
                      }else{
                          context.commit('SetCheckIf_AllProduct_NotGetData',false);
                      }
                    }
                }).catch(err=>{
                    console.log(err);
                })
        }, */
        
        GetAllProductType(context, data){
                context.commit('SetAllProductType',[]);  // Discuss
                axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/by/type/"+data.type+"/"+data.limit+"/limit", {
                  headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                  }
                }).then((res)=>{
                    var Respon = res['data'];
                    // console.log(Respon);
                    if(Respon['success'] && Respon['code'] == 200){
                      var AllProductInType = Respon['data']['products'];
                      var priceList=[];
                      var unitList=[];
                      var imageList=[];
                      if(AllProductInType.length > 0){
                        for(let i=0; i< AllProductInType.length;i++){
                          priceList = eval('['+AllProductInType[i]["price_detail"]+']');
                          unitList = eval('['+AllProductInType[i]["unit_detail"]+']');
                          imageList = JSON.parse(AllProductInType[i]['product_img']);
                          AllProductInType[i]['priceList'] = priceList;
                          AllProductInType[i]['unitList'] = unitList;
                          AllProductInType[i]['imageList'] = imageList;
                        }


                      if(AllProductInType.length <= 23){
                        context.commit('SetIsLastProductItem',true)
                      }else{
                        context.commit('SetIsLastProductItem',false)
                      }

                      context.commit('SetAllProductType',AllProductInType)
                      }else{
                        context.commit('SetCheckIf_ProductType_NotGetData',false);
                      }
                    }
                })
        },

        GetNewProductType(context, data){
              return  axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/by/type/"+data.type+"/"+data.limit+"/limit", {
                  headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                  }
                })
        },

        GetAllBestSellerProduct(context, limit){
              axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/best/seller/"+limit+"/limit", {
                headers:{
                  'Content-Type': 'application/json',
                  'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                  'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                  'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                }
              }).then((res)=>{
                  var Respon = res["data"];
                  if(Respon['success'] && Respon['code'] == 200){
                    var AllBestSellerProduct = Respon["data"]["bestSeller"];
                    var priceList=[];
                    var unitList=[];
                    var imageList=[];
                    if(AllBestSellerProduct.length > 0){
                      for(let i=0; i< AllBestSellerProduct.length;i++){
                        priceList = eval('['+AllBestSellerProduct[i]["price_detail"]+']');
                        unitList = eval('['+AllBestSellerProduct[i]["unit_detail"]+']');
                        imageList = JSON.parse(AllBestSellerProduct[i]['product_img']);
                        AllBestSellerProduct[i]['priceList'] = priceList;
                        AllBestSellerProduct[i]['unitList'] = unitList;
                        AllBestSellerProduct[i]['imageList'] = imageList;
                      }
                    context.commit('SetAllBestSellerProduct',AllBestSellerProduct)
                    }else{
                      context.commit('SetCheckIf_AllBestSeller_NotGetData',false);
                  }
                  }
              })
        },

        GetAllLatestProduct(context, limit){
              axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/latest/"+limit+"/limit", {
                headers:{
                  'Content-Type': 'application/json',
                  'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                  'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                  'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                }
              }).then((res)=>{
                  var Respon = res["data"];
                  if(Respon['success'] && Respon['code'] == 200){
                    var AllLatestProduct = Respon["data"]["latest"];
                  //  console.log(AllLatestProduct);
                    var priceList=[];
                    var unitList=[];
                    var imageList=[];
                    if(AllLatestProduct.length > 0){
                      for(let i=0; i< AllLatestProduct.length;i++){
                        priceList = eval('['+AllLatestProduct[i]["price_detail"]+']');
                        unitList = eval('['+AllLatestProduct[i]["unit_detail"]+']');
                        imageList = JSON.parse(AllLatestProduct[i]['product_img']);
                        AllLatestProduct[i]['priceList'] = priceList;
                        AllLatestProduct[i]['unitList'] = unitList;
                        AllLatestProduct[i]['imageList'] = imageList;
                      }
                    context.commit('SetAllLatestProduct',AllLatestProduct)
                    }else{
                      context.commit('SetCheckIf_AllLatestProduct_NotGetData',false);
                    }
                  }
              })
        },
        GetAllRecommendProduct(context, limit){
              axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/recommend/"+limit+"/limit", {
                headers:{
                  'Content-Type': 'application/json',
                  'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                  'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                  'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                }
              }).then((res)=>{
                  var Respon = res["data"];
                  if(Respon['success'] && Respon['code'] == 200){
                    var AllRecommendProduct = Respon["data"]["recommend"];
                  //  console.log(AllRecommendProduct);
                    var priceList=[];
                    var unitList=[];
                    var imageList=[];
                    if(AllRecommendProduct.length > 0){
                      for(let i=0; i< AllRecommendProduct.length;i++){
                        priceList = eval('['+AllRecommendProduct[i]["price_detail"]+']');
                        unitList = eval('['+AllRecommendProduct[i]["unit_detail"]+']');
                        imageList = JSON.parse(AllRecommendProduct[i]['product_img']);
                        AllRecommendProduct[i]['priceList'] = priceList;
                        AllRecommendProduct[i]['unitList'] = unitList;
                        AllRecommendProduct[i]['imageList'] = imageList;
                      }
                    context.commit('SetAllRecommendProduct',AllRecommendProduct)
                    }else{
                      context.commit('SetCheckIf_AllRecommendProduct_NotGetData',false);
                    }
                  }
              })
        },

        SetSelectedProduct(context, data){
          // Set selected product category and type to localStorage
          var encrypted = CryptoJS.AES.encrypt(JSON.stringify({group: data.group, type: data.type}), server['cryptoJSkey']).toString();
          window.localStorage.setItem('selectedProduct',encrypted);
          context.commit('SetSelectedProduct', localStorage.getItem('selectedProduct'));
        },
        DoSetIsLastProductItem(context, data){
          context.commit('SetIsLastProductItem',data)
        }
    },
    getters:{
        AllProduct: state => state.AllProduct,
        AllProductType: state => state.AllProductType,
        AllBestSellerProduct: state => state.AllBestSellerProduct,
        AllLatestProduct: state => state.AllLatestProduct,
        AllRecommendProduct: state => state.AllRecommendProduct,
        selectedproduct: state => state.selectedproduct,
        CheckIf_AllProduct_NotGetData: state => state.CheckIf_AllProduct_NotGetData,
        CheckIf_AllProductType_NotGetData: state => state.CheckIf_AllProductType_NotGetData,
        CheckIf_AllBestSeller_NotGetData: state => state.CheckIf_AllBestSeller_NotGetData,
        CheckIf_AllLatestProduct_NotGetData: state => state.CheckIf_AllLatestProduct_NotGetData,
        CheckIf_AllRecommendProduct_NotGetData: state => state.CheckIf_AllRecommendProduct_NotGetData,
        IsLastProductItem: state => state.IsLastProductItem,
    }
}
export default Product;