<template>
  <div class="shop-content">
    <div class="my-carousel">
      <v-carousel cycle>
        <v-carousel-item v-for="(image,i) in ShopDetail['ImageList']" :key="i" :src="server_ip+image"></v-carousel-item>
      </v-carousel>
    </div>

    <v-container>
            <div class="section">
                <div class="text-center pt-5 pb-5">
                    <h2 v-if="ShopDetail['shop_la']">{{($i18n.locale == 'la')?ShopDetail["shop_la"]:ShopDetail["shop_en"]}}</h2>
                    <div v-else class="pl-5 pr-5">
                        <v-sheet
                          color="rgb(241, 241, 241)">
                            <v-skeleton-loader
                              class="mx-auto"
                              max-width="400"
                              type="heading"
                            ></v-skeleton-loader>
                        </v-sheet>
                    </div>
                </div>
                <hr />
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <v-row v-if="ShopDetail['province_en']">
                            <v-col cols="12" md="12" style="word-wrap: break-word;">
                                <div class="pl-5" v-if="ShopDetail['province_la']">
                                    <h3><v-icon left color="#ff7a8e">remove_from_queue</v-icon>  {{$i18n.t('MyShop.Address')}} </h3>
                                    <div>
                                        {{($i18n.locale == 'la')?ShopDetail['province_la']:ShopDetail['province_en']}}, {{($i18n.locale == 'la')?ShopDetail['district_la']:ShopDetail['district_en']}}, {{ShopDetail["village"]}}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="6" style="word-wrap: break-word;" v-if="ShopDetail['tel']">
                                <div class="pl-5">
                                    <h3><v-icon left color="#ff7a8e">phone_forwarded</v-icon> {{$i18n.t('MyShop.Phone')}} </h3>
                                    <div>
                                        {{ShopDetail["tel"]}}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="6" style="word-wrap: break-word;" v-if="ShopDetail['whatsapp_link']">
                                <div class="pl-5">
                                    <h3><v-icon left color="#ff7a8e">add_ic_call</v-icon>  {{$i18n.t('MyShop.whatsapp')}} </h3>
                                    <div>
                                        <a :href="my_whatsapp_link+ShopDetail['whatsapp_link']" class="social-link">{{ShopDetail["whatsapp_link"]}}</a>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="6" style="word-wrap: break-word;" v-if="ShopDetail['messenger_link']">
                                <div class="pl-5">
                                    <h3><v-icon left color="#ff7a8e">facebook</v-icon>  {{$i18n.t('MyShop.Messeger')}} </h3>
                                    <div>
                                        <a :href="my_messenger_link+ShopDetail['messenger_link']" class="social-link">tsu Messenger</a>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="6" style="word-wrap: break-word;" v-if="ShopDetail['youtube_link']">
                                <div class="pl-5">
                                    <h3><v-icon left color="#ff7a8e">play_circle_filled</v-icon>  {{$i18n.t('MyShop.Youtube')}} </h3>
                                    <div>
                                        <a :href="my_youtube_link+ShopDetail['youtube_link']" class="social-link">7tsu Channel</a>
                                    </div>
                                </div>
                            </v-col>
                            
                        </v-row>
                        <div v-else class="pl-5 pr-5">
                            <v-sheet
                                color="rgb(241, 241, 241)">
                                <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="card"
                                ></v-skeleton-loader>
                            </v-sheet>
                        </div>
                    
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <gmap-map :center="{lat: ShopDetail.lat,lng: ShopDetail.lng}" :zoom="5" style="width:100%;  height: 200px;"  v-if="ShopDetail.lat && ShopDetail.lng">
                            <gmap-marker  :position="{lat: ShopDetail.lat,lng: ShopDetail.lng}"
                            @click="navigateToGoogleMap()"
                            ></gmap-marker>
                        </gmap-map>
                        <div v-else class="pl-5 pr-5">
                            <div v-if="No_Map_found">
                                <div class="text-center">
                                    <h1 class="No-Data">No Data</h1>
                                </div>
                            </div>
                            <v-sheet
                                color="rgb(241, 241, 241)" v-else>
                                <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="card"
                                ></v-skeleton-loader>
                            </v-sheet>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="section">
                <div v-if="Category.length > 0 && AllShopProduct.length > 0">
                    <v-row>
                        <v-col cols="12" md="12">
                            <div>
                                <v-text-field 
                                v-bind:placeholder="$t('MyShop.Search')" 
                                v-model="searchtext" 
                                class="search-box" 
                                v-on:keyup.enter="SearchAllShopProduct()"
                                outlined 
                                dense>
                                    <template v-slot:append>
                                        <v-icon class="cursor-pointer" @click="SearchAllShopProduct()"> search </v-icon>
                                    </template>
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-for="(category, i) in Category" :key="i">
                        <div v-if="AllShopProduct.filter((obj) => obj.category_id === category.cate_id ).length > 0">
                            <div class="ml-3">
                                <div class="pt-2 pb-2">
                                    <h3>{{($i18n.locale == 'la')?'ໝວດ'+category['category_la']:category['category_en']+' group'}}</h3>
                                </div>
                                 <v-row>
                                    <v-col cols="12" md="3" lg="2" sm="4"  v-for="(product, key) in AllProduct.filter((obj) => obj.category_id === category.cate_id )" :key="key">
                                        <v-card class="rounded-0 product-card">
                                            <v-hover v-slot:default="{ hover }">
                                                <div :elevation="hover ? 6 : 2">
                                                <div class="pl-2 pr-2">
                                                    <v-img :aspect-ratio="16/9"  :src="server_ip + product['imageList'][0]" ></v-img>
                                                </div>
                                                <v-card-text>
                                                    <div class="mb-2"><b>{{($i18n.locale=='la')?product["product_la"]:product["product_en"]}}</b></div>
                                                    <div class="mb-1 red--text" v-if="parseInt(product['priceList'][0]['advert_price']) > parseInt(product['priceList'][0]['price'])">
                                                    <del>
                                                        ${{product['priceList'][0]['advert_price']}}
                                                    </del>
                                                    </div>
                                                    <div class="green--text">${{product['priceList'][0]['price']}}</div>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn icon @click="AddToCard(
                                                            {
                                                                category_id:product['category_id'], 
                                                                type_id:product['ptype_id'], 
                                                                product_la:product['product_la'],
                                                                product_en:product['product_en'],

                                                                productId:product['product_id'],
                                                                unitNo:product['priceList'][0]['unit_no'],
                                                                priceNo:product['priceList'][0]['price_id'],
                                                                price:product['priceList'][0]['price'],
                                                                quantity:product['priceList'][0]['min_qty'], 

                                                                imageList:product['imageList'],
                                                                unitList:product['unitList'],
                                                                priceList:product['priceList'],
                                                            })
                                                        ">
                                                        <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="ProductDetail('/product/detail/',product.product_id)" color="primary">
                                                        {{$i18n.t('HomePage.Detail')}}
                                                    </v-btn>
                                                </v-card-actions>
                                                </div>
                                            </v-hover>
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mt-5">
                                <hr>
                            </div>
                        </div>
                    </div>

                    <v-row>
                        <v-col cols="12" v-if="gettingnewprod">
                                <div class="text-center pt-15 pb-5">
                                    <v-progress-circular
                                        :size="70"
                                        :width="7"
                                        color="rgb(19, 150, 171)"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="pa-5 load-new-product" v-if="!IsLastProductItem">
                               <span style="cursor: pointer;" @click="LoadNewProduct()"> <v-icon>expand_more</v-icon> <b class="load-new-product-span">{{$i18n.t("AllProductPage.Showmore")}}</b> </span>
                                <v-progress-linear
                                    color="rgb(19, 150, 171)"
                                    buffer-value="0"
                                    stream
                                    reverse
                                    style="width: 200px;"
                                    v-if="gettingnewprod"
                                ></v-progress-linear>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div v-else class="section">
                    <v-row v-if="CheckIfNotGetData">
                        <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                            <div class="pa-1">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <div class="text-center">
                            <h1 class="No-Data">No Data</h1>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add product Dialog -->
            <div>
                <v-dialog width="300" v-model="addcardDialog">
                    <v-card>
                    <v-card-title style="background-color: #1396ab; color:white">
                        {{$i18n.t('AddCardDialog.CardTitle')}}
                        <v-spacer></v-spacer>
                        <v-icon color="white"  @click="addcardDialog = !addcardDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                <div class="text-center mt-10 pb-0">
                                    <hr>
                                    <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="addcardDialog = !addcardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                    <hr>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions ,mapGetters} from 'vuex'
import server from '@/config/server.js'
import CryptoJS from 'crypto-js'
import Footer from'../../views/Footer.vue'
export default {
    data: () => ({
            shop_id:'',
            productLimmit:0,
            gettingnewprod: false,
            IsLastProductItem: false,
            server_ip: server['_7tsuAPI'],
            addcardDialog: false,
            searchtext:'',
            No_Map_found: false,
            curLocation:{
                lat:"",
                lng:""
            },
            decrypt_key: server['cryptoJSkey'],
            my_whatsapp_link:"https://api.whatsapp.com/send/?phone=",
            my_messenger_link:"https://www.messenger.com/t/",
            my_youtube_link:"https://www.youtube.com/channel/"
    }),
 
    computed: {
        ...mapGetters({
            Category:"menus/category",
            ShopDetail:"shop/ShopDetail",
            AllShopProduct:"shop/AllShopProduct",
            TotalShopProduct:"shop/TotalShopProduct",
            AllProductSearch:"shop/AllProductSearch",
            CheckIfNotGetData:"shop/CheckIfNotGetData"
        }),
        ShopInfo() {
            return this.AllShop.filter((obj)=>obj.shop_id == this.shop_id);
        },
        AllProduct(){
            if(this.searchtext && this.AllProductSearch.length > 0){
                return this.AllProductSearch
            }else{
                return this.AllShopProduct;
            }
        },
    },

    mounted() {
        this.DoShowMainAppbar(false);
        this.DoShowBackbtn(true);
    },

    created(){
        window.scrollTo(0,0);
         var encryptedId = this.$route.params.shop_id;
        var bytes = CryptoJS.AES.decrypt(encryptedId, this.decrypt_key);
          var decryptId =bytes.toString(CryptoJS.enc.Utf8);
          if(!decryptId) {
              this.$router.back();
              return;
          }

        this.shop_id = decryptId;
        this.MyShopDetail(this.shop_id);
        var data = {
            shopId: this.shop_id,
            limmit: 0
        }
        this.GetAllShopProduct(data);

        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition (
                position => {
                    this.curLocation.lat = position.coords.latitude;
                    this.curLocation.lng = position.coords.longitude;
                },
                error =>{
                    console.log(error.message);
                }
            );
        };

        setTimeout(() => {
            this.No_Map_found = true
        }, 5000);
    },

    destroyed() {
        this.DoShowMainAppbar(true);
        this.DoShowBackbtn(false);
    },

    methods: {
        ...mapActions("switchPage", ["DoShowMainAppbar", "DoShowBackbtn"]),
        ...mapActions("shoppingCart", ["AddToShoppingCart"]),
        ...mapActions("shop", ["MyShopDetail", "GetAllShopProduct", "DoSearchShopProduct", "GetNewShopProduct"]),

        ProductDetail(url, id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
        },
        
        AddToCard(data){
            this.AddToShoppingCart(data);
            this.addcardDialog = true;
        },
        SearchAllShopProduct(){
            if(this.searchtext){
                var data = {
                    searchData: this.searchtext,
                    shopId: this.$route.params.shop_id,
                    limmit: 0
                };
                this.DoSearchShopProduct(data);
            }
        },
        navigateToGoogleMap() {
            window.open('https://www.google.com/maps/dir/'+this.curLocation.lat+','+this.curLocation.lng+'/'+this.ShopDetail.lat+','+this.ShopDetail.lng, "_blank");
        },

        LoadNewProduct(){
            console.log(this.AllProduct.length);
            this.gettingnewprod = true;
            var data = {
                    shopId: this.shop_id,
                    limmit: this.AllProduct.length
                }
            this.GetNewShopProduct(data).then((res)=>{
                console.log(res);
                this.gettingnewprod = false;
                const Respone = res['data'];
                if(Respone['success'] && Respone['code'] == 200){
                var ResoneShop = Respone["data"]["products"];
                var _TotalShop = Respone["data"]["totalProduct"];
                var priceList=[];
                var unitList=[];
                var imageList=[];
                if(ResoneShop.length > 0){
                    for(let i=0; i < ResoneShop.length; i++){
                    priceList = eval("["+ ResoneShop[i]["price_detail"] +"]");
                    unitList = eval("["+ ResoneShop[i]["unit_detail"] +"]");
                    imageList = JSON.parse( ResoneShop[i]["product_img"]);
                    ResoneShop[i]["priceList"] = priceList;
                    ResoneShop[i]["unitList"] = unitList;
                    ResoneShop[i]["imageList"] = imageList;
                    }
                    ResoneShop.forEach(element => {
                        this.AllProduct.push(element);
                    });

                    if(this.AllProduct.length >= Respone['data']["totalProduct"]){
                        this.IsLastProductItem = true;
                    }
                }
                }
            })
                
        }
    }
};
</script>

<style scoped>
    .shop-content {
         background-color: #f5f5f5;
    }

    .section{
        background-color: white;
        margin-right: 5px;
        margin-top: 30px;
    }

    .content-side{
        width: 100%;
        background-color: white;
        border-left: grey 1px solid;
    }

    .my-carousel {
        position: relative;
        top: 0%;
    }
    
    .product-card:hover{
        box-shadow: 0px 1px 5px 0px grey !important;
    }

    .search-box{
        width: 500px;
        float: right;
        position: relative;
    }
    .No-Data{
        padding: 20%;
        opacity: 0.2;
    }
    .social-link{
        text-decoration: none;
    }
</style>