import Vue from 'vue'
import Vuex from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'

Vue.use(Vuex)

 const DirectCheckOut = {
  namespaced: true,
  state: {
    MyDirectCart:localStorage.getItem("DirectCart")?localStorage.getItem("DirectCart"):''
  },
  mutations: {
    SetToMyDirectCart(state, data){
          const ToArray = [];
          ToArray.push(data);
          const encryptData = CryptoJS.AES.encrypt(JSON.stringify(ToArray), server["cryptoJSkey"]).toString();
        if(state.MyDirectCart){
            state.MyDirectCart = '';
            state.MyDirectCart = encryptData;
        }else{
            state.MyDirectCart = encryptData;
        }
        window.localStorage.setItem("DirectCart", encryptData);
    },

    SetMyDirectCartEmpty(state, data){
      state.MyDirectCart = data;
      window.localStorage.setItem("DirectCart", '');
    }
  },

  actions: {
    AddToDirectCart(context, data){
        console.log(data);
      context.commit('SetToMyDirectCart', data)
    },

    ClearDirectCart(context, data){
      context.commit("SetMyDirectCartEmpty", data);
    },
  },

  getters:{
    MyDirectCart: state => state.MyDirectCart
  }
}

export default DirectCheckOut;
