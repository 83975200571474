export default {
     _7tsuAPI: 'https://api7t.7tsu.com',
    //_7tsuAPI: 'http://localhost:7777',
    socketIp: 'https://socket.7tsu.com',
    // socketIp: 'http://localhost:7775',
    secrettoken: 'mmdckwowoepojjwplkdmfoieirjimlkps',
    maintoken7tsu:'mcnoejsmoeijoiiwwmklmowijefokwmmdpfoe',
    token7tsu:'7cpjoifmgkljoirijjeyuwofflfmmopnpjfkmsu',
    atoken7tsu:'7tsu.xmoodmmdlkfjpolwjjpejrocmljoiw',

    // Crypto-js
    cryptoJSkey:'djfksdfjdsghsrg4564561vdsljdsfg+fg879dsfgsdfgjweriidfjsdfosdfg98df7sdfkjsdfsdf56jjfgdhsjdhjwe0011dd12dr5e4t5e6r546gd',
    
    locale: getLanguage(),
    fallbackLocale: getLanguage()
}

function getLanguage() {
    var lang = window.localStorage.getItem('lang');
    if (!((lang == 'la') || (lang == 'en'))) {
        window.localStorage.setItem('lang', 'la');
    }
    return window.localStorage.getItem('lang');
}