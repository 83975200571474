
<template>
  <div class="pl-5 pr-5">
    <v-stepper v-model="stepper" class="my-stepper" v-if="DeliveryInfo.cusId">
        <v-stepper-header style="background-color: white">
            <v-stepper-step :complete="stepper > 1" step="1">
            {{$i18n.t('History.payment.step1')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 2" step="2">
              {{$i18n.t('History.payment.step2')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper >= 3" step="3">
              {{$i18n.t('History.payment.step3')}}
            </v-stepper-step>
        </v-stepper-header>
        
        <v-stepper-items class="v-stepper-item">
            <v-stepper-content step="1" class="pa-0">
                <div class="section">
                  <div style="position: relative;">
                    <div class="pa-1">
                      <h1 class="text-center">{{$t('History.payment.payment_title')}}</h1>
                    </div>
                    <div>
                      <div class="text-right pt-2 pb-2"><b>{{$i18n.t('History.payment.approve_date')}}:</b>
                        <span v-if="OrderDetail[0]['approve_date']">
                          {{OrderDetail[0]["approve_date"].split('T')[0]}}
                        </span>
                        <span v-else>
                        ---
                        </span>

                        <span v-if="OrderDetail[0]['approve_time']">
                          : {{OrderDetail[0]["approve_time"].split('T')[0]}}
                        </span>
                        <span v-else>
                          : ---
                        </span>
                      </div>
                      <v-row>
                        <v-col cols="12" md="8" sm="8" xs="12">
                          <div>
                            <span><b>{{$i18n.t('History.payment.cus_name')}}:</b></span>
                            <span class="pl-2">{{OrderDetail[0]['cus_name']}}</span>
                          </div>
                          <div>
                            <span><b>{{$i18n.t('History.payment.cus_contry')}}:</b></span>
                            <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['country_la']:OrderDetail[0]['country_en']}}</span>
                          </div>
                          <div>
                            <span><b>{{$i18n.t('History.payment.cus_address')}}:</b></span>
                            <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['country_la']:OrderDetail[0]['country_en']}}</span>
                          </div>
                          <div>
                            <span><b>{{$i18n.t('History.payment.cus_tel')}}:</b></span>
                            <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['tel']:OrderDetail[0]['tel']}}</span>
                          </div>
                          <div>
                            <span><b>{{$i18n.t('History.payment.cus_email')}}:</b></span>
                            <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['email']:OrderDetail[0]['email']}}</span>
                          </div>
                        </v-col>

                        <v-col cols="12" md="4" sm="4" xs="12">
                          <div class="dhl-item my-text-right">
                            <span><b>{{$i18n.t('History.payment.dhl_destination')}}:</b></span>
                            <span class="pl-2">{{OrderDetail[0]['dhl_destination']}}</span>
                          </div>
                          <div class="dhl-item my-text-right">
                            <span><b>{{$i18n.t('History.payment.dhl_tracking')}}:</b></span>
                            <span class="pl-2">{{OrderDetail[0]['dhl_tracking']}}</span>
                          </div>
                          <div class="dhl-item my-text-right">
                            <span><b>{{$i18n.t('History.payment.dhl_fee')}}:</b></span>
                            <span class="pl-2 red--text"><u>${{OrderDetail[0]['dhl_fee']}}</u></span>
                          </div>
                          <div class="dhl-item my-text-right">
                            <span><b>{{$i18n.t('History.payment.credit_status')}}:</b></span>
                              <span  class="orange--text pl-3" v-if="OrderDetail[0]['row_status'] == 'pay'">{{$i18n.t('History.Detail.waitting_to_payment')}}</span>
                              <span  class="orange--text pl-3" v-else-if="OrderDetail[0]['credit_status'] == 't'">{{$i18n.t('History.Detail.credit_transport')}}</span>
                              <span class="green--text pl-3" v-else-if="OrderDetail[0]['credit_status'] == 'y'">{{$i18n.t('History.Detail.credit_success')}}</span>
                              <span class="red--text pl-3" v-else>{{$i18n.t('History.Detail.credit_cencel')}}</span>
                          </div>
                          <div class="dhl-item my-text-right">
                            <span><b>{{$i18n.t('History.Detail.approve_comment')}}:</b></span>
                            <span class="pl-2">{{OrderDetail[0]['approve_comment']}}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  <div>
                      <v-data-table hide-default-footer :headers="TB_HisDetailHeader" :items="OrderDetail[0]['BuyList']"  >
                          <template v-slot:[`item.subprice`]="{item}">
                              {{item.qty * item.price}}
                          </template>
                          <template v-slot:[`item.qty`]="{item}">
                              {{item.qty}} {{item.unit_la}}
                          </template>
                          <template v-slot:[`item.row_date`]="{item}">
                              {{item.row_date}}: {{item.row_time}}
                          </template>
                          
                          <template v-slot:[`body.append`]>
                            <tr style="background: #eeeeee">
                              <td colspan="8">
                                <div class="tbl-footer">
                                  <span class="mr-5 orange--text"><b>{{$i18n.t('History.payment.total_money')}} :</b></span>
                                  <span class="mr-5 orange--text"><b>${{total_money + OrderDetail[0]['dhl_fee']}}</b></span>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                  </div>
                  <div>
                    <form action="https://testsecureacceptance.cybersource.com/oneclick/pay" target="payment_iframe" method="post">
                      <div>
                          <input type="hidden" name="access_key" required :value="paymentForm.access_key">	
                          <input type="hidden" name="profile_id" required :value="paymentForm.profile_id"> 
                          <input type="hidden" name="transaction_uuid" required :value="paymentForm.transaction_uuid">	
                          <input type="hidden" name="signed_date_time" required :value="paymentForm.signed_date_time">	
                          <input type="hidden" name="locale" required :value="paymentForm.locale">	
                          <input type="hidden" name="transaction_type" required :value="paymentForm.transaction_type">	
                          <input type="hidden" name="reference_number" required :value="paymentForm.reference_number">	
                          <input type="hidden" name="currency" required :value="paymentForm.currency">	
                          <input type="hidden" name="device_fingerprint_id" required :value="paymentForm.device_fingerprint_id">	
                          <input type="hidden" name="amount" required :value="paymentForm.amount">	
                          <input type="hidden" name="bill_to_address_country" required :value="paymentForm.bill_to_address_country">	
                          <input type="hidden" name="bill_to_forename" required :value="paymentForm.bill_to_forename">	
                          <input type="hidden" name="bill_to_surname" required :value="paymentForm.bill_to_surname">	
                          <input type="hidden" name="bill_to_email" required :value="paymentForm.bill_to_email">	
                          <input type="hidden" name="bill_to_phone" required :value="paymentForm.bill_to_phone">	
                          <input type="hidden" name="bill_to_address_city" required :value="paymentForm.bill_to_address_city">	
                          <input type="hidden" name="bill_to_address_line1" required :value="paymentForm.bill_to_address_line1">	
                          <input type="hidden" name="bill_to_address_postal_code" required :value="paymentForm.bill_to_address_postal_code">	
                          <input type="hidden" name="merchant_secure_data1" required :value="paymentForm.merchant_secure_data1">	
                          <input type="hidden" name="merchant_secure_data2" required :value="paymentForm.merchant_secure_data2">	
                          <input type="hidden" name="merchant_secure_data3" required :value="paymentForm.merchant_secure_data3">	
                          <input type="hidden" name="signed_field_names" required :value="paymentForm.signed_field_names">	
                          <input type="hidden" name="signature" required :value="paymentForm.signature">	
                      </div>
                      <input ref="btnPayment" type="submit" value="" style="position: absolute; z-index: 0; opacity: 0;">
                    </form>
                  </div>
                  <div class="pt-5 pb-5 text-right">
                    <v-btn class="warning rounded-0" style="width: 200px; margin-right: 20px" @click="$router.back()"> 
                       <v-icon left>arrow_back</v-icon>
                      {{$i18n.t("History.payment.cancel_btn")}}
                    </v-btn>
                    <v-btn class="primary rounded-0" style="width: 200px" @click="doConfirm()" :loading="progressLoading">
                      <v-icon left>touch_app</v-icon>
                      {{$i18n.t("History.payment.pay_btn")}}
                    </v-btn>
                  </div>
                  </div>
                </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
                <div class="section">
                  <v-row style="background-color: #f5f5f5">
                    <v-col cols="12">
                        <v-btn class="primary rounded-0 back-btn" @click="$router.back()" > <v-icon left>arrow_back</v-icon> back</v-btn>
                        <div class="payment-content pl-2 pr-2">
                            <h2 style="text-align: center; padding-top: 20px">{{$i18n.t('Delivery.step3.GuestCheckOut_title')}}</h2>
                            <hr />
                            <div class="pa-2" style="text-align: center;">
                                <div>
                                    <img src="@/assets/card/visa.png" width="50px" style="margin-right: 10px;">
                                    <img src="@/assets/card/mastercard.png" width="50px" style="margin-right: 10px;">
                                    <img src="@/assets/card/jcb.png" width="50px" style="margin-right: 10px;">
                                    <img src="@/assets/card/amex.png" width="50px">
                                </div>
                                <iframe name="payment_iframe" style="width: 100%; height: 900px;"></iframe>
                                <v-row v-if="paymentFailed">
                                    <v-col>
                                        <v-btn color="error" block large @click="cancelOrder()" class="rounded-0">
                                            <v-icon left>delete_outline</v-icon>
                                              {{$i18n.t('Delivery.step3.clearBtn')}}
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="warning" block large @click="repay()" class="rounded-0">
                                            <v-icon left>replay</v-icon>
                                              {{$i18n.t('Delivery.step3.repaymentBtn')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="pa-0">
              <div v-if="stepper == 3">
                  <div class="section">
                      <div style="padding: 10px">
                          <div class="">
                              <h2 style="text-align: center">{{$i18n.t('Delivery.step4.OrderTitle')}}</h2>
                              <v-row>
                                  <v-col cols="6">
                                      <div class="TimeNewRoman-Phetsarath-OT"><b><u>7TSU</u></b></div>
                                      <div class="TimeNewRoman-Phetsarath-OT">{{$i18n.t("Delivery.step4.tsu7Pro")}}</div>
                                      <div>{{$i18n.t("Delivery.step4.tsu7Dis")}}</div>
                                      <div>{{$i18n.t("Delivery.step4.tsu7Vil")}}</div>
                                  </v-col>
                                  <v-col class="text-right" cols="6">
                                      <div style="position: relative; text-align: right">
                                          <b><u>{{$i18n.t('Delivery.step4.CusInfo')}}</u></b>
                                      </div>
                                      <div style="position: relative; text-align: right">
                                          {{$i18n.t("Delivery.step4.OrderDate")}}: {{getDate(Date.now())}} {{getTime(Date.now())}}
                                      </div>

                                      <div style="position: relative; text-align: right">
                                          {{$i18n.t("Delivery.step4.cusname")}}:
                                          {{DeliveryInfo.cusName}}
                                      </div>

                                      <div style="position: relative; text-align: right">
                                          {{$i18n.t("Delivery.step4.custel")}}:
                                          {{DeliveryInfo.cusTel}}
                                      </div>
                                  </v-col>
                              </v-row>
                          </div>
                          <v-data-table hide-default-footer :headers="TB_HisDetailHeader" :items="OrderDetail[0]['BuyList']"  >
                            <template v-slot:[`item.subprice`]="{item}">
                                {{item.qty * item.price}}
                            </template>
                            <template v-slot:[`item.qty`]="{item}">
                                {{item.qty}} {{item.unit_la}}
                            </template>
                            <template v-slot:[`item.row_date`]="{item}">
                                {{item.row_date}}: {{item.row_time}}
                            </template>
                            
                            <template v-slot:[`body.append`]>
                              <tr style="background: #eeeeee">
                                <td colspan="8">
                                  <div class="tbl-footer">
                                    <span class="mr-5"><b>{{$i18n.t('History.payment.total_price')}} :</b></span>
                                    <span class="mr-5"><b>${{total_money + OrderDetail[0]['dhl_fee']}}</b></span>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                          <div class="text-right pr-2 pt-10">
                              <v-btn 
                                  @click="doPrint_PDF('#print_content', '#printBill')" 
                                  color="primary" outlined  width="150"
                                  class="mr-2 rounded-0">
                                  {{$i18n.t('Delivery.step4.PrintPDF')}}
                              </v-btn>
                              <v-btn @click="paySuccess()" color="success" class="rounded-0" outlined width="150" :loading="backtohistory_progressLoading">
                                  {{$i18n.t('Delivery.step4.successBtn')}}
                              </v-btn>
                          </div>
                      </div>
                  </div>

                  <div id="print_content">
                      <div id="printBill" style="padding: 10px">
                          <div>
                              <div>
                                  <h2 style="text-align: center">{{$i18n.t('Delivery.step4.OrderTitle')}}</h2>
                                  <v-row>
                                      <v-col cols="6">
                                          <div class="TimeNewRoman-Phetsarath-OT"><b><u>7TSU</u></b></div>
                                          <div class="TimeNewRoman-Phetsarath-OT">{{$i18n.t("Delivery.step4.tsu7Pro")}}</div>
                                          <div>{{$i18n.t("Delivery.step4.tsu7Dis")}}</div>
                                          <div>{{$i18n.t("Delivery.step4.tsu7Vil")}}</div>
                                      </v-col>
                                      <v-col class="text-right" cols="6">
                                          <div style="position: relative; text-align: right">
                                          <b><u>{{$i18n.t('Delivery.step4.CusInfo')}}</u></b>
                                          </div>
                                          <div style="position: relative; text-align: right">
                                              {{$i18n.t("Delivery.step4.OrderDate")}}: {{getDate(Date.now())}} {{getTime(Date.now())}}
                                          </div>

                                          <div style="position: relative; text-align: right">
                                              {{$i18n.t("Delivery.step4.cusname")}}:
                                              {{DeliveryInfo.cusName}}
                                          </div>
                                          <div style="position: relative; text-align: right">
                                              {{$i18n.t("Delivery.step4.custel")}}:
                                              {{DeliveryInfo.cusTel}}
                                          </div>
                                      </v-col>
                                  </v-row>
                              </div>
                              <v-data-table hide-default-footer :headers="TB_HisDetailHeader" :items="OrderDetail[0]['BuyList']"  >
                                <template v-slot:[`item.subprice`]="{item}">
                                    {{item.qty * item.price}}
                                </template>
                                <template v-slot:[`item.qty`]="{item}">
                                    {{item.qty}} {{item.unit_la}}
                                </template>
                                <template v-slot:[`item.row_date`]="{item}">
                                    {{item.row_date}}: {{item.row_time}}
                                </template>
                                
                                <template v-slot:[`body.append`]>
                                  <tr style="background: #eeeeee">
                                    <td colspan="8">
                                      <div class="tbl-footer">
                                        <span class="mr-5"><b>{{$i18n.t('History.payment.total_price')}} :</b></span>
                                        <span class="mr-5"><b>${{total_money + OrderDetail[0]['dhl_fee']}}</b></span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                          </div>
                      </div>
                  </div>
              </div>
          </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

    <v-dialog :width="$isMobile()?'350':'320'" v-model="CheckOutConfirmDialog" >
      <v-card class="rounded-0">
          <v-card-title style="background-color: #1396ab; color:white">
              <v-spacer></v-spacer>
              <v-icon color="white" @click="CheckOutConfirmDialog = !CheckOutConfirmDialog">close</v-icon>
          </v-card-title>
          <v-card-text>
              <div class="text-center pt-10">
                  <h2>{{$i18n.t('History.payment.ConfirmText')}}</h2>
              </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <div class="pb-5">
              <v-btn color="warning" width="100" class="rounded-0 mr-2" @click="CheckOutConfirmDialog = !CheckOutConfirmDialog" small>
                  <v-icon left>close</v-icon>
                  {{$i18n.t('History.payment.cancel_btn')}}
              </v-btn>
              <v-btn color="primary" width="100" class="rounded-0" @click="Paymentprocess()" small>
                  <v-icon left>touch_app</v-icon>
                  {{$i18n.t('History.payment.pay_btn')}}
              </v-btn>
            </div>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog  persistent  v-model="resDialog" v-if="responeMessage['message']" width="350">
      <v-card>
          <v-card-title  :style="responeMessage['success'] ? 'background: #4caf50;color: white;' : 'background:#d32f2f;color: white;' " ><v-icon color="white" large>warning_amber</v-icon></v-card-title>
          <v-card-text class="text-center red--text">
            <div class="pt-5">
              <h2 class="pt-5">{{ responeMessage["message"]['la'] }}</h2>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <div class="pb-5">
              <v-btn  color="error" class="rounded-0" @click="resDialog = !resDialog" style="width: 100px" small>OK</v-btn>
            </div>
          </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>
<script>
import { mapActions, mapGetters } from "vuex";
import server from '@/config/server.js'
import tsu7socket from '@/config/socketio.js'
export default {
  data() {
    return {
        dialog: false,
        OrderDetail:[],
        decrypt_key: server['cryptoJSkey'],
        stepper:1,
        resDialog : false,
        progressLoading : false,
        CheckOutConfirmDialog: false,
        CheckOutSuccess: false,
        paymentFailed: false,
        checkRequest: false,
        backtohistory_progressLoading: false,

        total_money:0,
        responeMessage: {},
        DeliveryInfo: {},
        paymentForm: {
                "access_key": "uiueiurie",
                "profile_id": "",
                "transaction_uuid": "",
                "signed_date_time": "",
                "locale": "",
                "transaction_type": "",
                "reference_number": "",
                "currency": "",
                "device_fingerprint_id": "",
                "amount": "",
                "bill_to_address_country": "",
                "bill_to_forename": "",
                "bill_to_surname": "",
                "bill_to_email": "",
                "bill_to_phone": "",
                "bill_to_address_city": "",
                "bill_to_address_line1": "",
                "bill_to_address_postal_code": "",
                "merchant_secure_data1": "",
                "merchant_secure_data2": "",
                "merchant_secure_data3": "",
                "signed_field_names": "",
                "signature": ""
            },
    };
  },
    created(){
          var DeliverInfo = localStorage.getItem('deliveryinfo')?localStorage.getItem('deliveryinfo'):'';
          var Detail = localStorage.getItem('orderDetail')?localStorage.getItem('orderDetail'):'';
          if(!DeliverInfo &&  !Detail){
             this.$router.back();
              return;
          }
          this.DeliveryInfo = JSON.parse(DeliverInfo);
          this.OrderDetail = JSON.parse(Detail);

          // sum all order item price to total_money
          this.OrderDetail[0]['BuyList'].forEach(element => {
            this.total_money += element["qty"] * element["price"];
          });

      if(window.innerWidth <= 599){
          $('.dhl-item').removeClass('my-text-right');
      }else{
          $('.dhl-item').addClass('my-text-right');
      }
      window.scrollTo(0,0);
    },
  mounted (){
        window.addEventListener("resize", function(){
            if(window.innerWidth <= 599){
               $('.dhl-item').removeClass('my-text-right');
            }else{
               $('.dhl-item').addClass('my-text-right');
            }
        });
  },

  computed: {
    TB_HisDetailHeader() {
      var headers = [
        { text: this.$t("History.Detail.ShopName"), value: "shop_la" },
        { text: this.$t("History.Detail.Product"), value: "product_la", sortable: false},
        { text: this.$t("History.Detail.Category"), value: "category_la"},
        { text: this.$t("History.Detail.Type"), value: "type_la", sortable: false},
        { text: this.$t("History.Detail.Quantity"), value: "qty", sortable: false },
        { text: this.$t("History.Detail.Price"), value: "price", sortable: false },
        { text: this.$t("History.Detail.SubPrice"), value: "subprice", sortable: false },
        { text: this.$t("History.Detail.approve_date"), value: "row_date", sortable: false },
      ];
      return headers;
    },


  },

  methods: {
    ...mapActions("payment",["OrderPayment"]),

    getDate(rowDate) {
        if(!rowDate) { return '...';};
        var date = new Date(rowDate);
        return (
            (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            (date.getMonth() + 1 >= 10
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
            "/" + date.getFullYear()
        );
    },
    getTime(timestamp){
        if(!timestamp) { return '...';};
        var date = new Date(timestamp);
        return ((date.getHours() >= 10)?date.getHours():'0'+date.getHours()) +":"+
            ((date.getMinutes() >= 10)?date.getMinutes():'0'+date.getMinutes()) +":"+
            ((date.getSeconds() >= 10)?date.getSeconds():'0'+date.getSeconds());
    },
    navigateToDHL_Tracking(_tracking) {
      window.open('https://www.dhl.com/en/express/tracking.html?AWB='+_tracking+'&brand=DHL', "_blank");
    },

    repay() {
      this.paymentFailed = false;
      this.stepper = 1;
    },
    doConfirm() {
      this.CheckOutConfirmDialog = true;
    },

    Paymentprocess(){
        this.CheckOutConfirmDialog = false;
        this.progressLoading = true;
        this.OrderPayment(this.DeliveryInfo).then((res)=>{
          // console.log(res);
          const Respon = res["data"];
          this.responeMessage = res["data"];
          if(Respon["success"] == true && Respon["code"]  == 200){
              var data = Respon['data'];
              this.paymentForm = data;

              tsu7socket.on('/7tsu/real/payment/client/listening/'
                  +data['transaction_uuid']+
                  data['device_fingerprint_id']+data['reference_number'], 
                  (payResponse) => {
                      tsu7socket.off('/7tsu/real/payment/client/listening/'+
                      payResponse['req_transaction_uuid']+
                      payResponse['req_device_fingerprint_id']+payResponse['req_reference_number']);

                      if((payResponse['reason_code'] == '100') && (payResponse['auth_response'] == '00')) {
                          this.stepper = 3;
                          this.CheckOutSuccess = true;
                      } else {
                          this.responeMessage = {
                              success: false,
                              code: 500, 
                              massage:{
                                  la:'ຊຳລະເງິນລົ້ມເຫຼວ',
                                  en: 'Payment failed'
                              }
                          };
                          this.paymentFailed = true;
                          this.resDialog = true;
                          this.progressLoading = false;
                          console.log(this.responeMessage, this.resDialog)
                      }
                  })
              setTimeout(() => {
                  this.stepper = 2;
                  this.$refs.btnPayment.click();
                  this.progressLoading = false;
                  this.checkRequest = false;
              }, 1000)

          
              // this.resDialog = true;
              // this.progressLoading = false;
              // this.checkRequest = false;


          }else{
              this.resDialog = true;
              this.progressLoading = false;
              this.checkRequest = false;
          }
      }).catch(() => {
          this.responeMessage = {
              success: false, 
              code: 500, 
              massage:{
                  la:'ຂາດການເຊື່ອມຕໍ່ລະບົບ',
                  en: 'Connect to server failed'
              }
          };
          this.resDialog = true;
          this.progressLoading = false;
          this.checkRequest = false;
      });

    },

    paySuccess(){
      this.backtohistory_progressLoading = true;
      setTimeout(() => {
        this.backtohistory_progressLoading = false;
       this.$router.push({path: '/order/history'});
      }, 1000);
    },
    doPrint_PDF(print_content, printBill) {
            // eslint-disable-next-line no-undef
            $(print_content).css("display", "block");
            // eslint-disable-next-line no-undef
            $(printBill).print({
                globalStyles: true,
                mediaPrint: false,
                stylesheet: null,
                noPrintSelector: ".no-print",
                iframe: true,
                append:  null,
                base: true,
                prepend: null,
                manuallyCopyFormValues: true,
                // eslint-disable-next-line no-undef
                deferred: $.Deferred(),
                timeout: 750,
                title: null,
                doctype: "<!doctype html>",
            });
            // eslint-disable-next-line no-undef
            $(print_content).css("display", "none");
            },
  },
};
</script>

<style scoped>
.content-header {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
}

.my-text-right{
  text-align: right;
}

.No-Data{
      padding: 20%;
      opacity: 0.2;
  }
.my-stepper{
  background-color: #f5f5f5;
  margin-bottom: 60px;
  height: 100%;
  margin-top:10px
}

.section{
        background-color: white;
        margin-top: 20px;
        padding: 10px;
        box-shadow: 0px 1px 3px 0px grey;
    }
.payment-content{
        background-color: white;
    }
.back-btn{
  position: absolute;
  margin-top: 20px;
}
.tbl-footer{
  width: 100%; 
  text-align: right; 
  font-size: 18px
}

#print_content {
        display: none;
    }
</style>
