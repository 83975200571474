<template>
    <div class="card-content">
        <v-container>
            <div>
                <v-row>
                    <v-col cols="12" md="9" lg="9" sm="12" xs="12">
                        <div class="section">
                            <div class="pa-3 TimeNewRoman-Phetsarath-OT">
                                <h3>{{$i18n.t('ShopingCart.CartTitle')}}</h3>
                            </div>
                            <div  class="pa-5" v-for="(order, index) in ShoppingCart" :key="index">
                                <v-row>
                                    <v-col cols="12" md="1" sm="2" xs="2"><img :src="server + order['imageList'][0]" width="100%"></v-col>
                                    <v-col cols="12" md="7" sm="6" xs="12">
                                    <h3 class="TimeNewRoman-Phetsarath-OT">{{($i18n.locale == 'la')?order['product_la']:order['product_en']}}</h3>
                                        <div style="width: 300px">
                                            <v-text-field 
                                                dense 
                                                v-model="order.quantity" 
                                                required
                                                :rules="QtyRules_la"
                                                id="centered-input"
                                                :color="quantity_input_color" 
                                                @mouseup="ChangeColor()" 
                                                @keyup="OrderQuantityChange(index)"
                                                onkeypress="if(isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;"
                                                >
                                                <template v-slot:prepend-inner>
                                                    <v-btn color="error" class="rounded-0" width="30px" height="40px" @click="QuantityDown(index)" >
                                                        <v-icon>remove</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:append>
                                                    <v-autocomplete
                                                        v-model="order.unitNo"
                                                        required
                                                        :rules="Unit_rules"
                                                        :items="order['unitList']" 
                                                        :item-text="($i18n.locale=='la')?'unit_la':'unit_en'"
                                                        item-value="unit_no" 
                                                        outlined
                                                        dense  
                                                        class="rounded-0 product-unit-box"
                                                        @change="ChangeItemUnit(index,order.unitNo)"
                                                        >
                                                    </v-autocomplete>                                                    
                                                    <v-btn color="primary" class="rounded-0 pt-0" width="30px" height="40px" @click="QuantityUp(index)">
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                            <span></span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="2" sm="2" xs="2">
                                    <h3 class="TimeNewRoman-Phetsarath-OT ">{{$i18n.t('ShopingCart.ItemPrice')}}</h3>
                                    <div>
                                       <span class="green--text">$</span>{{order.price}}
                                    </div>
                                    </v-col>
                                    <v-col cols="12" md="2" sm="2" xs="2">
                                    <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ShopingCart.SubtotalPrice')}}</h3>
                                    <div class="TimeNewRoman-Phetsarath-OT">
                                        <span style="color: green" class="TimeNewRoman-Phetsarath-OT"> ${{order.SUBTOTALPRICE}}</span>
                                        {{order.price * order.quantity}}
                                            <v-menu offset-y  :close-on-content-click="DELETEITEM">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span color="black" dark v-bind="attrs" v-on="on" icon >
                                                    <v-icon class="delete-icon-class">delete</v-icon>
                                                </span>
                                            </template>
                                            <v-list style="width: 300px;">
                                                <div style="padding: 5px;">
                                                    <h3 class="text-center TimeNewRoman-Phetsarath-OT" style="color: red">{{$i18n.t('ShopingCart.DelConfirmText')}}</h3>
                                                    <div class="text-center mt-5">
                                                        <v-btn color="error" class=" text-center TimeNewRoman-Phetsarath-OT" small @click="DeleteItem(index)">
                                                            <v-icon left style="font-size: 20px">delete</v-icon> {{$i18n.t('ShopingCart.OkBtn')}}
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    </v-col>
                                </v-row>
                                <hr>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                        <div class="section pa-5 TimeNewRoman-Phetsarath-OT">
                            <h3>{{$i18n.t('ShopingCart.OrderSummary')}}</h3>
                            <hr>
                            <div class="mt-3">
                                <div class="pt-2 pl-2">
                                    <b>{{$i18n.t('ShopingCart.Tax')}} :</b> <span class="green--text">$0</span>
                                </div>
                                <div class="pt-2 pl-2">
                                <b>{{$i18n.t('ShopingCart.TotalPrice')}} :</b> <span class="green--text">${{AllProductPrice}}</span>
                                </div>
                            </div>
                            <hr>
                            <div class="text-center mt-5">
                                <v-row>
                                    <v-col md="6" sm="6" xs="6">
                                        <v-btn 
                                            color="orange" 
                                            outlined 
                                            class="ma-2" 
                                            width="100%" 
                                            :loading="ReShoppingProgress"
                                            @click="NavigateToPage('/')"
                                            >
                                                <v-icon left>cached</v-icon>
                                                {{$i18n.t('ShopingCart.Shoping')}}
                                            </v-btn>
                                    </v-col>
                                    <v-col md="6" sm="6" xs="6">
                                        <v-btn 
                                            color="info" 
                                            outlined 
                                            class="ma-2" 
                                            width="100%" 
                                            :loading="CheckOutProgress"
                                            @click="DoCheckOut('/order/process')"
                                            >
                                                <v-icon left>local_grocery_store</v-icon>
                                                {{$i18n.t('ShopingCart.CheckOut')}}
                                            </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <div class="section">
                    <div class="pt-3">                
                        <div class="section">
                            <div>
                                <h2 class="TimeNewRoman-Phetsarath-OT"><b>{{$i18n.t('ShopingCart.RecommentProduct')}}</b></h2>
                                <div class="product-thesame-store">
                                    <v-row class="pt-3" v-if="RecommendProduct.length > 0">
                                        <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(item, key) in RecommendProduct" :key="key" class="my_col">
                                            <v-card class="rounded-0 product-card">
                                                <v-hover v-slot:default="{ hover }">
                                                    <div :elevation="hover ? 6 : 2">
                                                    <div class="pl-2 pr-2">
                                                        <v-img :aspect-ratio="16/9"  :src="server + item['imageList'][0]" ></v-img>
                                                    </div>
                                                    <v-card-text>
                                                        <div class="mb-2"><b>{{($i18n.locale=='la')?item["product_la"]:item["product_en"]}}</b></div>
                                                        <div class="mb-1 red--text" v-if="parseInt(item['priceList'][0]['advert_price']) > parseInt(item['priceList'][0]['price'])">
                                                            <del>
                                                                ${{item['priceList'][0]['advert_price']}}
                                                            </del>
                                                        </div>
                                                        <div class="green--text">${{item['priceList'][0]['price']}}</div>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn icon color="primary" @click="AddToCard(
                                                                {
                                                                category_id:item['category_id'], 
                                                                type_id:item['ptype_id'], 
                                                                product_la:item['product_la'],
                                                                product_en:item['product_en'],

                                                                productId:item['product_id'],
                                                                unitNo:item['priceList'][0]['unit_no'],
                                                                priceNo:item['priceList'][0]['price_id'],
                                                                price:item['priceList'][0]['price'],
                                                                quantity:item['priceList'][0]['min_qty'], 

                                                                imageList:item['imageList'],
                                                                unitList:item['unitList'],
                                                                priceList:item['priceList'],
                                                                })">
                                                                <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                                            </v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn 
                                                            @click="ProductDetail('/product/detail/', item.product_id)"
                                                            text 
                                                            color="primary">
                                                                {{$i18n.t('ShopingCart.Detail_btn')}}
                                                        </v-btn>
                                                    </v-card-actions>
                                                    </div>
                                                </v-hover>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <v-dialog width="300" v-model="AddToCardDialog">
                    <v-card>
                        <v-card-title style="background-color: #1396ab; color:white">
                            {{$i18n.t('AddCardDialog.CardTitle')}}
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="AddToCardDialog = !AddToCardDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                <div class="text-center mt-10 pb-0">
                                    <hr>
                                    <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCardDialog = !AddToCardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                    <hr>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <!-- Confirm Dialog -->
                <v-dialog width="300px" v-model="CardEmptyDialog" >
                    <v-card class="rounded-0">
                        <v-card-title style="background-color: orange; color:white">
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="CardEmptyDialog = !CardEmptyDialog">close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="text-center pa-7">
                                <h2>{{$i18n.t('ShopingCart.CardEmptyText')}}</h2>
                            </div>
                            <div class="text-center">
                            
                                <v-btn color="warning" class="rounded-0 mr-5" @click="CardEmptyDialog = !CardEmptyDialog">{{$i18n.t('ShopingCart.OkBtn')}}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog width="300" v-model="UserLogin_Noti">
                    <v-card>
                        <v-card-title style="background-color: orange;">
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="UserLogin_Noti = !UserLogin_Noti" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="warning" style="font-size: 50px">warning</v-icon></div>
                                <div class="text-center mb-4 red--text">{{$i18n.t('LoginNotificationDialog.noti_text')}}</div>
                                <div class="text-center pa-5">
                                    <!-- <v-btn color="red" small outlined width="100px" @click="NavigateToPage('/user')">{{$i18n.t('EmptyCardItemDialog.ok_btn')}}</v-btn> -->
                                    <v-btn
                                        :loading="loading5"
                                        :disabled="loading5"
                                        small outlined width="100px"
                                        color="red"
                                        @click="NavigateToLogin('/user')"
                                        >
                                        {{$i18n.t('EmptyCardItemDialog.ok_btn')}}
                                    </v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
              </v-dialog>

            </div>
        </v-container>
    </div>
</template>

<script>
import { mapActions ,mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
export default {
    data() {
        return {
            tab: null,
            CLOSEEDITEQTY: false,
            DELETEITEM: false,
            server: server['_7tsuAPI'],
            quantity_input_color:'primary',
            AddToCardDialog: false,
            CardEmptyDialog: false,
            UserLogin_Noti:false,
            loader: null,
            loading5: false,
            CheckOutProgress: false,
            ReShoppingProgress: false,

            ShoppingCartResponText: this.$i18n.t("ShopingCardText"),
            uuid: localStorage.getItem("uuid")?localStorage.getItem("uuid"):"",
            QtyRules_la:[v => !!v || 'ຈຳນວນຕ້ອງໃຫຍ່ກວ່າ ຫຼື ເທົ່າກັບ 1'],
            Unit_rules:[  v => !!v || 'ຫົວໜ່ວຍຫ້າມຫວ່າງ'],
            decrypt_key: server['cryptoJSkey'],
            FormData:{
                productId:'',
                unitNo:'',
                priceNo:'',
                price:0,
                quantity:1
            }
        }
    },

     computed: {
     ...mapGetters({
        AllProduct:"product/AllProduct",
        ShoppingCart:"shoppingCart/ShoppingCart",
        RecommendProduct:"mixproduct/RecommendProduct",
        Token:"generalInfo/Token",
        CustomerProfile: 'generalInfo/CustomerProfile',
    }),

    AllProductPrice(){
        var totalPrice = 0;
            for(var i=0; i < this.ShoppingCart.length; i++){
                totalPrice += this.ShoppingCart[i]["quantity"] * this.ShoppingCart[i]["price"]
            }
            return totalPrice;
        }
    },

    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },

    created(){
        window.scrollTo(0,0);
        if(this.ShoppingCart.length == 0){
            this.$router.back();
        }
    },

     methods: {
        ...mapActions('productdetail',['GetProductDetail']),
        ...mapActions("shoppingCart",["AddToShoppingCart", "UpdateCardItem"]),
        ...mapActions("generalInfo",["DoLoginStatus"]),
            CloseEditQuentity() {
                this.CLOSEEDITEQTY = true,
                setTimeout(() => {
                    this.CLOSEEDITEQTY = false;
                }, 100);
            },
            DeleteItem(index) {
                this.ShoppingCart.splice(index, 1);
                this.UpdateCardItem(this.ShoppingCart)
                this.DELETEITEM = true,
                setTimeout(() => {
                    this.DELETEITEM = false;
                }, 100);
            },
            NavigateToPage(router){
                this.ReShoppingProgress = true;
                setTimeout(() => {
                    this.ReShoppingProgress = false;
                    this.$router.push({path: router})
                }, 500);
            },
            DoCheckOut(router){
                if(this.ShoppingCart.length > 0){
                this.CheckOutProgress = true;
                    if(this.CustomerProfile.length){
                            var bytes = CryptoJS.AES.decrypt(this.CustomerProfile,this.decrypt_key);
                            var CusInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                            if(CusInfo["cus_id"]){
                                this.CheckOutProgress = false;
                                this.$router.push({path:router});
                            }else{
                                this.CheckOutProgress = false;
                               this.UserLogin_Noti = true
                            }
                        }else{
                            this.CheckOutProgress = false;
                           this.UserLogin_Noti = true
                        }
                    
                }else{
                    this.CheckOutProgress = false;
                    this.CardEmptyDialog = true;
                }
            },

            NavigateToLogin(router){
                this.loader = 'loading5'
                setTimeout(() => {
                   // this.DoLoginStatus("CustomerAddedToCart");
                    this.$router.push({path: router})
                }, 600);
            },

            QuantityUp(index){
                this.quantity_input_color = 'primary';
                if(isNaN(parseInt(this.ShoppingCart[index]["quantity"]))){
                  this.ShoppingCart[index]["quantity"] = 1;
                }else{
                    this.ShoppingCart[index]["quantity"] = parseInt(this.ShoppingCart[index]["quantity"]) + 1;
                }
                
                // don't forget to check product price here 
                var productPrice = this.ShoppingCart[index]["priceList"].filter(
                (obj) => obj.unit_no == this.ShoppingCart[index]["unitNo"] && 
                            this.ShoppingCart[index]["quantity"] >= obj.min_qty  && 
                            this.ShoppingCart[index]["quantity"] <= obj.max_qty
                    );
                if(productPrice.length > 0){
                    this.ShoppingCart[index]["price"] = productPrice[0]["price"]
                }
            },
            
            QuantityDown(index){
                this.quantity_input_color = 'error';
                if(isNaN(parseInt(this.ShoppingCart[index]["quantity"]))){
                  this.ShoppingCart[index]["quantity"] = 1;
                }
                if(parseInt(this.ShoppingCart[index]["quantity"]) > 1){
                  this.ShoppingCart[index]["quantity"] =  parseInt(this.ShoppingCart[index]["quantity"]) - 1;
                }

                // don't forget to check product price here 
                var productPrice = this.ShoppingCart[index]["priceList"].filter(
                (obj) => obj.unit_no == this.ShoppingCart[index]["unitNo"] && 
                            this.ShoppingCart[index]["quantity"] >= obj.min_qty  && 
                            this.ShoppingCart[index]["quantity"] <= obj.max_qty
                    );
                if(productPrice.length > 0){
                    this.ShoppingCart[index]["price"] = productPrice[0]["price"]
                }

            },
            ChangeItemUnit(index, unitNo){
                console.log("index "+index);
                console.log("unitNo "+unitNo);
                  var productPrice = this.ShoppingCart[index]["priceList"];
                   if(productPrice.length > 0){
                       for(var i=0; i< productPrice.length; i++){
                           if( 
                               productPrice[i].unit_no == unitNo && 
                               this.ShoppingCart[index].quantity >= productPrice[i].min_qty && 
                               this.ShoppingCart[index].quantity <= productPrice[i].max_qty
                            ){
                                this.ShoppingCart[index]["priceNo"] = productPrice[i]["price_id"];
                                this.ShoppingCart[index]["price"] = productPrice[i]["price"];
                                this.ShoppingCart[index]["unitNo"] = productPrice[i]["unit_no"]
                            /*
                            
                            [ 
                                { "category_id": 5, "type_id": 12, "product_la": "test", "product_en": "Test", "productId": 5, "unitNo": 4, "priceNo": 10, "price": 20, "quantity": 1, 
                                    "imageList": [ "/image/shop/product/product_161378274519596qCzmVdxu.png", 
                                                    "/image/shop/product/product_1613782745204v8fRo1Ose4.png", 
                                                    "/image/shop/product/product_1613782745213N3En26Avpq.png" ], 
                                    "unitList": [ 
                                            { "unit_no": 4, "unit_la": "ຄູ່", "unit_en": "Pair" }, 
                                            { "unit_no": 5, "unit_la": "ຖົງ", "unit_en": "Bag" } 
                                        ], 
                                    "priceList": [ 
                                                { "price_id": 10, "min_qty": 1, "max_qty": 10, "unit_no": 4, "unit_la": "ຄູ່", "unit_en": "Pair", "price": 20, "advert_price": 30, "detail": "", "profit_type": "CASH", "profit_value": 2 }, 
                                                { "price_id": 11, "min_qty": 1, "max_qty": 10, "unit_no": 5, "unit_la": "ຖົງ", "unit_en": "Bag", "price": 18, "advert_price": 30, "detail": "", "profit_type": "PERCENT", "profit_value": 10 } 
                                            ] 
                                } 
                            ]
                            
                            */    
                            }
                       }
                   }
            },

            OrderQuantityChange(index){
                if(parseInt(this.ShoppingCart[index]["quantity"]) == 0){
                  this.ShoppingCart[index]["quantity"] =  1;
                }
                var productPrice = this.ShoppingCart[index]["priceList"].filter(
                    (obj) => obj.unit_no == this.ShoppingCart[index]["unitNo"] && 
                             this.ShoppingCart[index]["quantity"] >= obj.min_qty  && 
                             this.ShoppingCart[index]["quantity"] <= obj.max_qty
                        );
                    if(productPrice.length > 0){
                        console.log(productPrice);
                        this.ShoppingCart[index]["price"] = productPrice[0]["price"];
                    }
            },
           
            ChangeColor(){
                 this.quantity_input_color = 'primary';
            },

            AddToCard(data){
            this.AddToCardDialog = true;
            this.AddToShoppingCart(data);
            },

            ProductDetail(url, id){
                
             const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
             this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
            
            this.ProductDetailID = id;
            this.GetProductDetail(this.ProductDetailID).then((res)=>{
            const Respon = res['data'];
            if(Respon['success'] && Respon['code'] == 200){
                var Detail = Respon['data'];
                var priceList = [];
                var unitList = [];
                var imageList = [];
                    priceList = eval('['+Detail['price_detail']+']');
                    unitList  = eval('['+Detail['unit_detail']+']');
                    imageList  = JSON.parse(Detail['product_img']);
                    Detail['priceList'] = priceList;
                    Detail['unitList'] = unitList;
                    Detail['imageList'] = imageList;
                    if(Detail['unitList'].length){
                        this.FormData.unitNo = Detail['unitList'][0]['unit_no'];
                    }
                    // console.log(imageList[0]);
                    this.DetailShowImg = imageList[0];
                    this.productDetail = Detail;
                }
            })
            },


     }
}
</script>

<style scoped>
    .card-content{
        background-color: #f5f5f5;
        padding-bottom: 10px;
    }
    .section{
        background-color: white;
        margin-top: 15px;
    }
    
    .delete-icon-class{
        font-size: 20px;
        margin-left: 20px;
        margin-bottom: 5px;
        color: red;
    }
    .v-tooltip__content {
        pointer-events: initial;
    }
    .Edit-qty:hover{
        cursor: pointer;
    }

    .product-unit-box{
        width: 80px;
        bottom: 0;
    }

    .product-card:hover{
       box-shadow: 0px 1px 5px 0px grey !important;
    }



  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>