import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)
const Menus = {
  namespaced: true,
  state: {
    Category:[],
    ProductType_Menu:[]
  },
  mutations: {
    SetCategory(state, data){
      state.Category = data
    },
    SetProductType_Menu(state, data){
      state.ProductType_Menu = data
    },
  },
  actions: {
    GetMenus(context){
        axios.get(server['_7tsuAPI'] + "/api/7tsu/customer/another/category",{
          headers:{
            'Content-Type': 'application/json',
            'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
            'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
            'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
          }
        }).then((res)=>{
            const Respon = res['data'];
            if(Respon['success'] && Respon['code'] == 200){
              context.commit('SetCategory',Respon['data']);
            }
        })

        axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/another/product/type",{
          headers:{
            'Content-Type': 'application/json',
            'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
            'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
            'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
          }
        }).then((res)=>{
          const Respon = res['data'];
          if(Respon['success'] && Respon['code'] == 200){
            context.commit('SetProductType_Menu',Respon['data'])
          }
        })
      },
    },

  getters:{
    category: state => state.Category,
    submenu: state => state.ProductType_Menu
  }
}

export default Menus;