<template>
  <div>
    <div style="position: sticky; top: 0px; z-index:9">
      <v-card >
        <div  v-if="ShowMainAppbar">
          <!-- <div>
            {{UserInfo}}
          </div> -->
          <!-- Top Menu -->
          <div class="toolbar-main-menu d-none d-md-block d-lg-block">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0"  @click="NavigateToPage('/')">
                    <v-icon left class="BTN-MENU">home</v-icon> {{$i18n.t('MainPage.Home')}}
                  </v-btn>
              </template>
            </v-menu>

            <v-menu >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0"  @click="NavigateToPage('/shop')">
                    <v-icon left class="BTN-MENU">storefront</v-icon> {{$i18n.t('MainPage.Shopes')}}
                  </v-btn>
              </template>
            </v-menu>

            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0"  @click="NavigateToPage('/product/all')">
                    <v-icon left class="BTN-MENU">extension</v-icon> {{$i18n.t('MainPage.Product')}}
                  </v-btn>
              </template>
            </v-menu>

            <v-menu open-on-hover bottom offset-y :close-on-content-click="CLOSEMENUCONTENT" style="">
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0">
                    <v-icon left class="BTN-MENU">view_list</v-icon> {{$i18n.t('MainPage.Category')}}
                  </v-btn>
              </template>
              <v-list v-if="Category" style="width: 300px">
                  <div v-for="(category, j) in Category" :key="j">
                    <v-list-group  no-action v-if="SubMenu.filter((obj)=>obj.category_id === category['cate_id']).length > 0">
                      <template v-slot:activator>
                        <v-list-item-title> {{($i18n.locale == 'la')?category['category_la']:category['category_en']}}</v-list-item-title>
                      </template>
                      <v-list-item v-for="(submenuItem, k) in SubMenu.filter((obj)=>obj.category_id === category['cate_id'])" 
                          :key="k"
                          @click="NavigateToProductType('/product/type',submenuItem.category_id, submenuItem.type_id)"
                        >
                        <v-list-item-title > {{($i18n.locale == 'la')?submenuItem['type_la']:submenuItem['type_en']}}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </div>
              </v-list>
            </v-menu>

            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0"  @click="NavigateToPage('/policy')">
                    <v-icon left class="BTN-MENU">gavel</v-icon> {{$i18n.t('MainPage.policy')}}
                  </v-btn>
              </template>
            </v-menu>

            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0"  @click="NavigateToPage('/about')">
                    <v-icon left class="BTN-MENU">supervised_user_circle</v-icon> {{$i18n.t('MainPage.AboutUs')}}
                  </v-btn>
              </template>
            </v-menu>

            <v-menu open-on-hover bottom offset-y :close-on-content-click="CLOSEMENUCONTENT" style="">
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0" v-if="UserInfo">
                    <v-icon left class="BTN-MENU">person</v-icon>{{UserInfo.first_name}}
                  </v-btn>
                  <v-btn color="black" dark v-bind="attrs" v-on="on" text class="rounded-0" v-else>
                    <v-icon left class="BTN-MENU">person</v-icon>{{$i18n.t('MainPage.User')}}
                  </v-btn>
              </template>
              <v-list style="width: 200px">
                <v-list-item v-if="!UserHasLogIn">
                  <v-list-item-title @click="NavigatToRegisterUserPage()"><v-icon left class="BTN-MENU">person_add</v-icon> {{$i18n.t('HomePage.Register')}}</v-list-item-title>
                </v-list-item>
                
                <v-list-item v-if="!UserHasLogIn">
                  <v-list-item-title @click="NavigatToLoginPage()"><v-icon left class="BTN-MENU">how_to_reg</v-icon> {{$i18n.t('HomePage.Login')}}</v-list-item-title>
                </v-list-item>
            
                <v-list-item v-if="UserInfo" >
                  <v-list-item-title @click="NavigateToHistoryPage()"><v-icon left class="BTN-MENU">restore</v-icon> {{$i18n.t('HomePage.History')}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="UserHasLogIn">
                  <v-list-item-title @click="LogoutConfirmDialog = !LogoutConfirmDialog"><v-icon left class="BTN-MENU">power_settings_new</v-icon> {{$i18n.t('HomePage.Logout')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>  <!-- End Top Menu -->

          <v-app-bar class="rounded-0" color="#1396AB" dark>
            <span class="cursor-pointer d-none d-md-block d-lg-block"  @click="NavigateToPage('/')">
                  <img src="@/assets/images/7tsulogoNew3.png"  width="50px" />
            </span>
            <v-toolbar-title @click="NavigateToPage('/')">
              <span  class="cursor-pointer d-none d-md-block d-lg-block ml-3 TimeNewRoman-Phetsarath-OT">7TSU</span>
              <v-app-bar-nav-icon @click.stop="LEFTNAVIGATIONDRAWER = !LEFTNAVIGATIONDRAWER" style="color:white" class="d-md-none d-lg-none d-sm-block d-xs-block mr-4"></v-app-bar-nav-icon>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field  v-model="SearchText" :label="$i18n.t('Search')" outlined dense class="mt-0 mr-5" v-on:keyup.enter="SearchAllProduct()">
                <template v-slot:append>
                  <v-icon class="cursor-pointer" @click="SearchAllProduct()"> search </v-icon>
                </template>
            </v-text-field>
            <v-badge overlap color="red" v-if="CardItem > 0 && (cart_status)">
                <template v-slot:badge>
                  {{CardItem}}
                </template>
                <v-icon class="mr-2 cursor-pointer" @click="NavigateToPage('/order/cart')">local_grocery_store</v-icon>
            </v-badge>
            <v-icon class="mr-2 cursor-pointer" @click="NoCardItemDialog = !NoCardItemDialog" v-else>local_grocery_store</v-icon>
            
            <span v-if="UserInfo">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge overlap color="red">
                    <template v-slot:badge>
                      {{PayItem}}
                    </template>
                    <v-icon class="mr-2 ml-2 cursor-pointer"  v-bind="attrs" v-on="on"  @click="NavigateToHistoryPage()">restore</v-icon>
                </v-badge>
                </template>
                <span>{{$i18n.t('MainPage.HisToolTip')}}</span>
              </v-tooltip>
            </span>
            <span>
              <v-btn icon color="white" @click="setLang('en')" v-if="lang == 'la'">
                <img src="@/assets/images/en.png" style="width: 25px" />
              </v-btn>
              <v-btn icon color="white" @click="setLang('la')" v-else>
                <img src="@/assets/images/la.png" style="width: 25px" />
              </v-btn>
            </span>

            <!-- NoCardItemDialog Dialog -->
              <v-dialog width="300" v-model="NoCardItemDialog">
                  <v-card>
                    <v-card-title style="background-color: orange;">
                        <v-spacer></v-spacer>
                        <v-icon color="white"  @click="NoCardItemDialog = !NoCardItemDialog" left>mdi-close</v-icon>
                      </v-card-title>
                      <v-card-text>
                          <div class="mt-4">
                              <div class="text-center mb-4"><v-icon color="warning" style="font-size: 50px">warning</v-icon></div>
                              <div class="text-center mb-4 red--text "><h2>{{$i18n.t('EmptyCardItemDialog.CardText')}}</h2></div>
                              <div class="text-center">
                                  <v-btn color="red" small outlined width="100px" @click="NoCardItemDialog = !NoCardItemDialog">{{$i18n.t('EmptyCardItemDialog.ok_btn')}}</v-btn>
                              </div>
                          </div>
                      </v-card-text>
                  </v-card>
              </v-dialog>

              <!-- Confirm Dialog -->
              <v-dialog width="400px" v-model="LogoutConfirmDialog" >
                  <v-card class="rounded-0">
                      <v-card-title style="background-color: red; color:white">
                          <v-spacer></v-spacer>
                          <v-icon color="white" @click="LogoutConfirmDialog = !LogoutConfirmDialog">close</v-icon>
                      </v-card-title>
                      <v-card-text>
                        <div class="text-center">
                          <v-icon color="red" style="font-size: 80px" class="mt-5">power_settings_new</v-icon>
                        </div>
                          <div class="text-center pt-4 pb-10 red--text">
                              <h2>{{$i18n.t('ToolbarMenu.Logout_Confirm_Text')}}</h2>
                          </div>
                          <div class="text-center">
                              
                              <v-btn 
                                color="primary" 
                                class="rounded-0  mr-3" 
                                @click="UserLogout()" 
                                :loading="LogoutLoadingProgress"
                                width="100px"
                                >
                                  <v-icon color="white" left>power_settings_new</v-icon> 
                                  {{$i18n.t('ToolbarMenu.Logout_Confirm_btn')}}
                              </v-btn>

                              <v-btn 
                                style="color: white; background: red" 
                                class="rounded-0" 
                                @click="LogoutConfirmDialog = !LogoutConfirmDialog"
                                width="100px"
                                >
                                  <v-icon color="white" left>close</v-icon> 
                                  {{$i18n.t('ToolbarMenu.Logout_Cancel_btn')}}
                              </v-btn>

                          </div>
                      </v-card-text>
                  </v-card>
              </v-dialog>
          </v-app-bar>
        </div>

        <v-card-actions v-else class="pt-0 pb-0 Shop-App-bar">
          <v-btn text @click="BackFunc()" large style="color: white" v-if="ShowBack_btn">
            <v-icon>arrow_back_ios</v-icon>{{$i18n.t('MainPage.BackInMyShop')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-badge overlap color="red" v-if="CardItem > 0">
                <template v-slot:badge>
                  {{CardItem}}
                </template>
                <v-icon class=" white--text mr-2 cursor-pointer" @click="NavigateToPage('/order/cart')">local_grocery_store</v-icon>
          </v-badge>

          <span class="ml-5">
            <v-btn icon class="ml-1" color="white" @click="setLang('en')" v-if="lang == 'la'">
              <img src="@/assets/images/en.png" style="width: 25px" />
            </v-btn>
            <v-btn icon class="ml-1" color="white" @click="setLang('la')" v-else>
              <img src="@/assets/images/la.png" style="width: 25px" />
            </v-btn>
          </span>
        </v-card-actions>
        
        <v-navigation-drawer v-model="LEFTNAVIGATIONDRAWER" fixed temporary id="NavigationDrawer">
          <v-app-bar class="rounded-0" color="#1396AB" dark>
            <span class="cursor-pointer mr-2 pt-2"  @click="NavigateToPage('/')">
                  <img src="@/assets/images/7tsulogoNew3.png"  width="50px" />
            </span>
            <v-toolbar-title @click="NavigateToPage('/')">
              <span  class="cursor-pointer TimeNewRoman-Phetsarath-OT">7TSU</span>
            </v-toolbar-title>
          </v-app-bar>
          <div class="red-devider"></div>
          <div >
            <v-list>
              <v-list-item @click="NavigateToPage('/')">
                  <v-icon left class="left-side-menu-icon">home</v-icon> {{$i18n.t('HomePage.Home')}}
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item @click="NavigateToPage('/shop')">
                  <v-icon left class="BTN-MENU">storefront</v-icon> {{$i18n.t('MainPage.Shopes')}}
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item @click="NavigateToPage('/product/all')">
                  <v-icon left class="left-side-menu-icon">extension</v-icon> {{$i18n.t('HomePage.Product')}}
              </v-list-item>
            </v-list>

            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-icon class="left-side-menu-icon">adjust</v-icon>
                  {{$i18n.t('HomePage.Category')}}
                </v-list-item-title>
              </template>
              <div v-for="(category, j) in Category" :key="j" class="sub-menu">
                <v-list-group  no-action v-if="SubMenu.filter((obj)=>obj.category_id === category['cate_id']).length > 0">
                    <template v-slot:activator>
                      <v-list-item-title> {{($i18n.locale == 'la')?category['category_la']:category['category_en']}}</v-list-item-title>
                    </template>
                    <v-list-item v-for="(submenuItem, k) in SubMenu.filter((obj)=>obj.category_id === category['cate_id'])" 
                        :key="k"
                        @click="NavigateToProductType('/product/type',submenuItem.category_id, submenuItem.type_id)"
                       >
                      <v-list-item-title> {{($i18n.locale == 'la')?submenuItem['type_la']:submenuItem['type_en']}}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>
              </div>
            </v-list-group>

            <v-list>
              <v-list-item>
                   <v-list-item-title @click="NavigateToHistoryPage()">
                       <v-icon left class="left-side-menu-icon">restore</v-icon> {{$i18n.t('MainPage.History_btn')}}
                    </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item>
                   <v-list-item-title @click="NavigateToPage('/about')">
                       <v-icon left class="left-side-menu-icon">supervised_user_circle</v-icon> {{$i18n.t('MainPage.AboutUs')}}
                    </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item>
                   <v-list-item-title @click="NavigateToPage('/contact')">
                       <v-icon left class="left-side-menu-icon">phone_in_talk</v-icon> {{$i18n.t('MainPage.ContactUs')}}
                    </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item>
                   <v-menu open-on-hover bottom offset-y :close-on-content-click="CLOSEMENUCONTENT">
                    <template v-slot:activator="{ on, attrs }">
                        <span color="black" class="rounded-0" v-bind="attrs" v-on="on" text dark  v-if="UserInfo">
                          <v-icon class="left-side-menu-icon">person</v-icon>{{UserInfo.first_name}}
                        </span>
                        <span dark v-bind="attrs" v-on="on" v-else  style="width: 240px">
                          <v-icon left class="left-side-menu-icon">person</v-icon>{{$i18n.t('MainPage.User')}}
                        </span>
                    </template>
                    <v-list style="width: 224px">
                      <v-list-item v-if="!UserHasLogIn">
                        <v-list-item-title @click="NavigatToRegisterUserPage()"><v-icon left class="BTN-MENU" >person_add</v-icon> {{$i18n.t('HomePage.Register')}}</v-list-item-title>
                      </v-list-item>
                      
                      <v-list-item v-if="!UserHasLogIn">
                        <v-list-item-title @click="NavigatToLoginPage()"><v-icon left class="BTN-MENU" >how_to_reg</v-icon> {{$i18n.t('HomePage.Login')}}</v-list-item-title>
                      </v-list-item>
                  
                      <v-list-item v-if="UserInfo" >
                        <v-list-item-title @click="NavigateToHistoryPage()"><v-icon left class="BTN-MENU" >restore</v-icon> {{$i18n.t('HomePage.History')}}</v-list-item-title>
                      </v-list-item>

                      <v-list-item v-if="UserHasLogIn">
                        <v-list-item-title @click="LogoutConfirmDialog = !LogoutConfirmDialog"><v-icon left class="BTN-MENU" >power_settings_new</v-icon> {{$i18n.t('HomePage.Logout')}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
              </v-list-item>
            </v-list>
          </div>
        </v-navigation-drawer>
      </v-card>
      
      <div v-if="$vuetify.breakpoint.mdAndUp">
      <div v-if="ShowMainAppbar">
          <div class="red-devider"></div>
          <div class="top-category" v-if="TopCategory">
            <div class="Show-TopCategoryMenu">
                <div class="category-content" style="cursor: pointer" @click="ControlMenu = !ControlMenu">
                  <span> {{$i18n.t('MainPage.Category_btn')}} 
                      <v-icon class="up_down_icon" style="color: white;" v-if="ControlMenu == false">keyboard_arrow_down</v-icon>
                      <v-icon class="up_down_icon" style="color: white;" v-else>keyboard_arrow_up </v-icon>
                  </span> 
                </div>
            </div>
            <div class="red-devider" id="bottom_devider"></div>            
              <div id="CategoryMenu" @mouseleave="ControlMenu = !ControlMenu" v-if="ControlMenu">
                <ul id="CategoryMenuUl">
                  <div v-for="(category, i) in Category" :key="i">
                    <li v-if="SubMenu.filter((obj)=>obj.category_id === category['cate_id']).length > 0" @mouseover="ShowType(category.cate_id, i)">
                        {{($i18n.locale=='la')?category.category_la:category.category_en}}
                      <ul id="CategoryMenuSubUl" v-if="(submenuIndex == i) && SubMenuList.length" :style="$isMobile()?'top:89px':'top:120px'">
                          <li v-for="(type, j) in SubMenuList" :key="j" @click="NavigateToProductType('/product/type',category.cate_id, type.type_id)"> 
                            {{($i18n.locale == 'la')?type.type_la:type.type_en}}
                          </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
      </div>


      <!-- <div  v-if="ShowMainAppbar">
          <div class="red-devider"></div>
          <div class="top-category" v-if="TopCategory">
            <div class="Show-TopCategoryMenu">
                <div class="category-content" style="cursor: pointer" @click="ShowCategory()">
                  <span> {{$i18n.t('MainPage.Category_btn')}} 
                      <v-icon class="up_down_icon" style="color: white;"> {{(up_down == '')?'keyboard_arrow_down':'keyboard_arrow_up'}} </v-icon>
                  </span> 
                </div>
            </div>
            <div class="red-devider" id="bottom_devider"></div>
            <div id="CategoryMenu" @mouseleave="HidCategoryMenu()">
              <ul id="CategoryMenuUl">
                <div v-for="(category, i) in Category" :key="i">
                  <li v-if="SubMenu.filter((obj)=>obj.category_id === category['cate_id']).length > 0" @mouseover="ShowType(category.cate_id, i)">
                      {{($i18n.locale=='la')?category.category_la:category.category_en}}
                    <ul id="CategoryMenuSubUl" v-if="(submenuIndex == i) && SubMenuList.length" :style="$isMobile()?'top:89px':'top:120px'">
                        <li v-for="(type, j) in SubMenuList" :key="j" @click="NavigateToProductType('/product/type',category.cate_id, type.type_id)"> 
                          {{($i18n.locale == 'la')?type.type_la:type.type_en}}
                        </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </div>
          </div>
      </div> -->
    </div>
   <v-main>
     <router-view />
   </v-main>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import CryptoJS from 'crypto-js'
  import server from '@/config/server.js'
export default {
  name: "Main",
  data: () => ({
    lang: window.localStorage.getItem("lang") == "la" ? "la" : "en",
    token: localStorage.getItem("Tk")?localStorage.getItem("Tk"):'',
    CLOSEMENUCONTENT: false,
    LEFTNAVIGATIONDRAWER: false,
    NoCardItemDialog: false,
    LogoutConfirmDialog: false,
    LogoutLoadingProgress: false,
    ControlMenu: false,
    search: null,
    searchItem:[],
    SearchText:'',
    up_down:'',
    show: false,
    SubMenuList:[],
    submenuIndex: -1,
    Top_ProductGroup:[],
    Top_ProductType:[],
  }),

  computed: {
     ...mapGetters({
        TopCategory: 'generalInfo/topcategory',
        Token: 'generalInfo/Token',
        CustomerProfile: 'generalInfo/CustomerProfile',
        Category:"menus/category",
        SubMenu:"menus/submenu",
        SelectedProduct:"selectedproduct",
        ShoppingCart:"shoppingCart/ShoppingCart",
        cart_status:"shoppingCart/cart_status",
        HistoryById:"history/HistoryById",
        HistoryByDevice:"history/HistoryByDevice",
        ShowMainAppbar: "switchPage/ShowMainAppbar",
        ShowBack_btn: "switchPage/ShowBack_btn",
        AllShop:"shop/AllShop",
        PayItem:"history/MyPaymentItem"
    }),
    mounted () {
      // console.log(this.$vuetify.breakpoint)
    },

    CardItem(){
        return this.ShoppingCart.length;
    },
    UserHasLogIn(){
      if(this.Token.length){
        return true;
      }
      return false;
    },
    UserInfo(){
      if(this.CustomerProfile.length){
          var bytes = CryptoJS.AES.decrypt(this.CustomerProfile, server['cryptoJSkey']);
          var CusInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          // console.log(CusInfo);
          if(CusInfo["cus_id"]){
            return CusInfo;
          }else{
             return '';
          }
      }
    },

    HisControl(){
      if(this.HistoryByDevice.length > 0 || this.HistoryById.length > 0){
        return true
      }else{
        return false
      }
    }
  },

  created(){
    this.GetMenus();
    this.Get_Mix_AdvertProduct(0);
    this.GetCountry();
    if(this.AllShop.length == 0){
      this.GetAllShop(0);
    }
    if(this.Token) {
        this.GetHistoryByID(this.Token)
      } else {
          // const deviceID = {deviceId: localStorage.getItem("uuid")} ;
          // console.log(deviceID);
          // this.GetHistoryByDevice(deviceID);
          // this.getCustomerInfoByDevice(deviceID);
      }
  },

  methods: {
    ...mapActions("menus", ["GetMenus"]),
    ...mapActions("mixproduct", ["Get_Mix_AdvertProduct"]),
    ...mapActions("product", ["GetAllProductType", "SetSelectedProduct"]),
    ...mapActions("generalInfo",["GetCountry","SetUserPageSide", "SetToken", "SetUserProfile", "getCustomerInfoByDevice"]),
    ...mapActions("search",["DoSearch"]),
    ...mapActions("history",["GetHistoryByID", "GetHistoryByDevice", "SetEmptyToHisByID"]),
    ...mapActions("shop", ["GetAllShop"]),
    ...mapActions("generalInfo", ["SetNewLang"]),
    setLang(lang) {
      this.$i18n.locale = lang.toLowerCase();
      this.SetNewLang(lang)
      this.lang = lang;
    },

    NavigateToPage(router){
      this.$router.push({path: router });
      this.CLOSEMENUCONTENT = true;
      setTimeout(() => {
        this.CLOSEMENUCONTENT = false;
      }, 100);
    },
    NavigateToProductType(router, group_id, type_id){
      const data = {group:group_id, type:type_id, limit: 0};
      this.SetSelectedProduct(data);
      this.GetAllProductType(data);
      this.$router.push({path: router });
      $("#CategoryMenuSubUl").css('display', 'none');
      $('#CategoryMenu').css("display", "none");

       this.CLOSEMENUCONTENT = true;
        setTimeout(() => {
          this.CLOSEMENUCONTENT = false;
        }, 100);
    },

    ShowGroup(id, index){
      this.submenuIndex = index;
      this.ProductGroup=[];
      this.ProductType =[];
      this.ProductGroup= this.Categories.filter((category)=>category.id == id)[0]['group'];
    },

    ShowType(id, index){
      this.submenuIndex = index;
      this.SubMenuList =[];
      this.SubMenuList= this.SubMenu.filter((type) => type.category_id == id);
    },

    ShowCategoryMenuUl(id, index){
      console.log(id);
      this.submenuIndex = index;
      this.Top_ProductGroup=[];
      this.Top_ProductType =[];
      this.Top_ProductGroup= this.Categories.filter((category)=>category.id == id)[0]['group'];
    },

    SmallDevice(){
      if(window.innerWidth <= 559){
        return true
      }else{ return false }
    },
    ShowCategory(){
      $("#CategoryMenu").css('display', 'block');
      $("#bottom_devider").css('display', 'none')
      this.up_down ='up'
    },

    HidCategoryMenu(){
          $('#CategoryMenu').css("display", "none");
           $("#bottom_devider").css('display', 'block')
           this.up_down =''
        },

    NavigatToRegisterUserPage(){
      this.SetUserPageSide("register");
      this.$router.push({path: '/user'});
    },
    NavigatToLoginPage(){
      this.SetUserPageSide("Login");
      this.$router.push({path: '/user'});
    },

    NavigateToHistoryPage(){
      this.$router.push({path: '/order/history'});
    },
    UserLogout(){
      this.LogoutLoadingProgress = true
      setTimeout(() => {
          this.LogoutLoadingProgress = false;
          this.SetToken("");
          this.SetUserProfile("");
          localStorage.removeItem("Tk");
          localStorage.removeItem("profile");
          this.SetEmptyToHisByID([])
          this.LogoutConfirmDialog =false;
      }, 800);
    
    },
    SearchAllProduct(){
      // console.log(this.SearchText);
      const data = {searchData: this.SearchText, limit: 0};
      this.DoSearch(data);
      this.$router.push({path:'/search/result'});
    },
    BackFunc(){
      if(window.history.length > 0){
        this.$router.back()
      }else{
        this.$router.push({path:'/'});
      }
    }
  }, 
};
</script>

<style scoped>
  .app-bar-menu{position: relative;}
  .toolbar-main-menu > .v-btn:not(.v-btn--round).v-size--default {
      height: auto;
      min-width: 64px;
      padding: 0 0px;
      margin-right: 10px;
  }
  .red-devider{ height: 5px; background-color: red }

  .top-category{  height: 15px; background-color: white }

  .BTN-MENU{ font-size: 20px }

  .cursor-pointer{ cursor: pointer}

  .toolbar-main-menu{
    background-color: rgb(233, 231, 231);
    text-align: right;
  }

  .v-list-item:hover{
    box-shadow: 1px 1px 3px grey;
    cursor: pointer;
  }

  ul>li {
    list-style: none;
    width: 100%;
    padding: 5px;
    border-bottom: gray 1px solid;
  }

  #NavigationDrawer .v-list--nav{
    padding: 0 0px
  }
  #NavigationDrawer .navigation-dawer-toolbar{
    background-color: #64b5f6;
    height: 52px;
  }
  .sub-menu{
    margin-left: 7%;
    font-size: 12px
  }
  .sub-menu .v-list-item:hover {
    cursor: pointer;
    background-color:white;
  }

  .submenuItem{
    margin-left: 13%;
    font-size: 12px
  }

  .submenuItem .v-list-item:hover {
    cursor: pointer;
    background-color:white;
  }
  /* Category menu */
  /*  #CategoryMenu{
      display: none;
      width: 200px;
      height: 800px;
      padding: 5px;
      z-index:2;
      background-color: rgb(238, 237, 236);
      box-shadow: -5px 5px 5px rgb(180, 179, 179);
    } */
  #CategoryMenu{
    width: 200px;
    height: 800px;
    padding: 5px;
    z-index:2;
    background-color: rgb(238, 237, 236);
    box-shadow: 5px 1px 5px rgb(180, 179, 179);
  }

  ul#CategoryMenu>li:hover {
    color: red;
    /* cursor: pointer; */
  }

  .Show-TopCategoryMenu{
  background-color: red;
  }

  #CategoryMenuSubUl{
      display: none;
      position: absolute;
      z-index: 2;
      margin-left: 150px;
      width: 200px;
      text-align: left;
      height: 800px;
      padding: 5px;
      color: black;
      background-color: white;
      box-shadow: 2px 5px 5px rgb(180, 179, 179);
  }
  ul > div >li {
  list-style: none;
  width: 100%;
  padding: 5px;
  border-bottom: gray 1px solid;
}

  ul#CategoryMenuUl>div>li:hover{
    color: red;
    cursor: pointer;
  }

  ul#CategoryMenuUl>div>li:hover > #CategoryMenuSubUl {
    display: block;
  }

  ul#CategoryMenuSubUl>li:hover {
    color: red;
    cursor: pointer;
  }

  .category-content{
    width: 200px;
    background-color: #1396AB;
    padding:2px;
    text-align: center;
    color: white;
    position: relative;
  }
  
  .up_down_icon{
    color: white;
    margin-left:10px;
    position: absolute;
    width: 20px;
    top:0;
    left:150px;
  }

  @media screen and (max-width: 959px) {
     #CategoryMenuSubUl{
        top:0 !important;
        margin-top: 90px
     }
  }

  .Shop-App-bar{
    background-color: rgba(15, 147, 199, 0.5); 
    width:100%;
    height: 60px;
    position: absolute;
    z-index: 7;
  }

  .side-7tsu-text{
    position: absolute;
  }

  .v-list {
    padding: 0px 0 !important;
}

</style>
