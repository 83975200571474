import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

 const ShoppingCard = {
  namespaced: true,
  state: {
  },
  mutations: {
   
  },

  actions: {
    Register(context, data){
        try { 
           return axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/user/register", data, {
                    headers:{
                    'Content-Type': 'application/json',
                    'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                    'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                    'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                    }
                })
        } catch (error) {
            return null
        }
    },
    UserDoLogin(context, data){
        return axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/user/login", data, {
                headers:{
                'Content-Type': 'application/json',
                'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
                'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
                'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
                }
            })
    },
  },

  getters:{
    ShoppingCard: state => state.ShoppingCard,
    ShopingCartRespon: state => state.ShopingCartRespon
  }
}

export default ShoppingCard;
