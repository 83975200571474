var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',{staticClass:"main-content"},[(_vm.SearchResult.length > 0)?_c('div',{staticClass:"pa-3",staticStyle:{"height":"2000px"}},[_vm._l((_vm.SearchResult),function(product,key){return _c('div',{key:key,staticClass:"section"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"2","sm":"2","xs":"2"},on:{"click":function($event){return _vm.ProductDetail('/product/detail/', product.product_id)}}},[_c('div',{staticClass:"pa-2"},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"aspect-ratio":16/9,"src":_vm.server + product['imageList'][0]}})],1)]),_c('v-col',{attrs:{"md":"10","sm":"10","xs":"10"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ProductDetail('/product/detail/', product.product_id)}}},[_c('b',[_vm._v(_vm._s((_vm.$i18n.locale=='la')?product["product_la"]:product["product_en"]))])]),_c('div',{staticClass:"pt-2",staticStyle:{"word-wrap":"break-word"},domProps:{"innerHTML":_vm._s((_vm.$i18n.locale == 'la')?product.detail_la:product.detail_en)}}),_c('div',[(parseInt(product['priceList'][0]['advert_price']) > parseInt(product['priceList'][0]['price']))?_c('span',{staticClass:"red--text mr-5"},[_c('del',[_vm._v(" $"+_vm._s(product['priceList'][0]['advert_price'])+" ")])]):_vm._e(),_c('span',{staticClass:"green--text"},[_vm._v(" $"+_vm._s(product['priceList'][0]['price'])+" ")]),_c('span',{staticStyle:{"float":"right","margin-right":"10px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.AddToCard(
                                {
                                    category_id:product['category_id'], 
                                    type_id:product['ptype_id'], 
                                    product_la:product['product_la'],
                                    product_en:product['product_en'],

                                    productId:product['product_id'],
                                    unitNo:product['priceList'][0]['unit_no'],
                                    priceNo:product['priceList'][0]['price_id'],
                                    price:product['priceList'][0]['price'],
                                    quantity:product['priceList'][0]['min_qty'], 

                                    imageList:product['imageList'],
                                    unitList:product['unitList'],
                                    priceList:product['priceList'],
                                })}}},[_c('v-icon',{staticClass:"green--text",staticStyle:{"cursor":"pointer"}},[_vm._v("add_shopping_cart")])],1)],1)])])],1)],1)}),_c('div',[_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.AddToCardDialog),callback:function ($$v) {_vm.AddToCardDialog=$$v},expression:"AddToCardDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#1396ab","color":"white"}},[_vm._v(" "+_vm._s(_vm.$i18n.t('AddCardDialog.CardTitle'))+" "),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white","left":""},on:{"click":function($event){_vm.AddToCardDialog = !_vm.AddToCardDialog}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"text-center mb-4"},[_c('v-icon',{staticStyle:{"font-size":"50px"},attrs:{"color":"green"}},[_vm._v("check_circle")])],1),_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$i18n.t('AddCardDialog.AddCardText')))])]),_c('div',{staticClass:"text-center mt-10 pb-0"},[_c('hr'),_c('v-btn',{staticClass:"dialog-btn ma-2",attrs:{"color":"info","small":"","outlined":"","width":"100px"},on:{"click":function($event){_vm.AddToCardDialog = !_vm.AddToCardDialog}}},[_vm._v(_vm._s(_vm.$i18n.t('AddCardDialog.ok_btn')))]),_c('hr')],1)])])],1)],1)],1)],2):_c('div',{staticStyle:{"height":"635px"}},[(_vm.EmptySearchText)?_c('div',{staticClass:"text-center"},[_c('h1',{staticStyle:{"padding-top":"300px"}},[_vm._v(_vm._s(_vm.EmptySearchText))])]):_c('div',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"rgb(241, 241, 241)"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"padding-top":"200px"},attrs:{"max-width":"300","type":"card"}})],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }