import Vue from 'vue'
import Vuex from 'vuex'
import GeneralInfo from './module/GeneralInfo.js'
import Menus from './module/Menus.js'
import MixProduct from './module/MixProduct.js'
import Product from './module/Product.js'
import ProductDetail from './module/ProductDetail.js'
import ShoppingCart from './module/ShoppingCart.js'
import Order from './module/Order.js'
import User from './module/User.js'
import Search from './module/Search.js'
import History from './module/History.js'
import SwitchPage from './module/SwitchPage.js'
import Shop from './module/Shop.js'
import DirectCheckOut from './module/DirectCheckOut.js'
import Payment from './module/Payment.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
      generalInfo: GeneralInfo,
      menus: Menus,
      mixproduct: MixProduct,
      product: Product,
      productdetail: ProductDetail,
      shoppingCart: ShoppingCart,
      order: Order,
      user: User,
      search: Search,
      history: History,
      switchPage: SwitchPage,
      shop: Shop,
      directcheckOut: DirectCheckOut,
      payment: Payment
    }
})
