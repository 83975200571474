import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import CryptoJS from 'crypto-js'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)

 const Search = {
  namespaced: true,
  state: {
    SearchResult:[],
    totalSearchProduct:0
  },
  mutations: {
    SetSearchProduct(state, data){
      state.SearchResult = data
    },
    SettotalSearchProduct(state, data){
      state.totalSearchProduct = data
    }
    
  },
  actions: {
    DoSearch(context, data){
      axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/product/search/"+data.limit+"/limit", {searchData: data.searchData }, {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        const Respon = res['data'];
        if(Respon['success'] && Respon['code'] == 200){
          var ResponAllProduct = Respon['data']['search'];
          var priceList=[];
          var unitList=[];
          var imageList=[];
          if(ResponAllProduct.length > 0){
            for(let i=0; i < ResponAllProduct.length; i++){
              priceList = eval("["+ ResponAllProduct[i]["price_detail"] +"]");
              unitList = eval("["+ ResponAllProduct[i]["unit_detail"] +"]");
              imageList = JSON.parse( ResponAllProduct[i]["product_img"]);
              ResponAllProduct[i]["priceList"] = priceList;
              ResponAllProduct[i]["unitList"] = unitList;
              ResponAllProduct[i]["imageList"] = imageList;
            }
          }
          context.commit('SetSearchProduct',ResponAllProduct);
          context.commit('SettotalSearchProduct',Respon['data']["totalProduct"]);
        }
      })
    }
  },

  getters:{
    SearchResult: state => state.SearchResult,
    totalSearchProduct: state => state.totalProduct
  }
}

export default Search;
