<template>
    <div>
        <div class="footer">
          <div class="mb-5 text-center" style="position: relative">
             <img src="@/assets/images/7tsulogoNew3.png" class="pa-3" width="300"/>
            <h2  class="text-center"><b>{{$t('Footer.PaymentCard')}}</b></h2>
          </div>
          <hr />
          <div class="text-center">
             <img src="@/assets/images/logobcel.png" class="pa-3" width="100"/>
              <h3 class="TimeNewRoman-Phetsarath-OT">BCEL</h3>
          </div>
           <v-row>
              <v-col md="3" lg="3" sm="3" xs="6">
                <div class="mb-5 text-center" style="position: relative">
                  <img src="@/assets/images/card/mastercard.png" class="pa-3" width="100"/>
                  <h3 class="TimeNewRoman-Phetsarath-OT">mastercard</h3>
                </div>
              </v-col>

              <v-col md="3" lg="3" sm="3" xs="6">
                <div class="mb-5 text-center" style="position: relative">
                  <img src="@/assets/images/card/visa.png" class="pa-3" width="100"/>
                  <h3 class="TimeNewRoman-Phetsarath-OT">VISA</h3>
                </div>
              </v-col>

              <v-col md="3" lg="3" sm="3" xs="6">
                <div class="mb-5 text-center" style="position: relative">
                  <img src="@/assets/images/card/jcb.png" class="pa-3" width="100"/>
                  <h3 class="TimeNewRoman-Phetsarath-OT">JCB</h3>
                </div>
              </v-col>

              <v-col md="3" lg="3" sm="3" xs="6">
                <div class="mb-5 text-center" style="position: relative">
                  <img src="@/assets/images/card/amex.png" class="pa-3" width="100"/>
                  <h3 class="TimeNewRoman-Phetsarath-OT">AMAX</h3>
                </div>
              </v-col>
          </v-row>
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      partners:[
        {id:1, logo:require('@/assets/images/card/mastercard.png'), name:'Master Card'},
        {id:2, logo:require('@/assets/images/card/visa.png'), name:'Visa'},
        {id:3, logo:require('@/assets/images/card/jcb.png'), name:'JCB'},
        {id:4, logo:require('@/assets/images/card/amex.png'), name:'AMAX'},
      ]
    }
  },
}
</script>
<style>
    .footer{
      background-color: #e8eafd;
      text-align:justify;
      padding-bottom: 100px;
    }
    .partner-logo{
      width: 100px;
    }
    .bcel-logo{
      display:block;
      margin:auto;
      margin: auto;
      width: 150px;
    }
    .card-logo{
      /* position: absolute;
      margin-left: 2%; */
      /* text-align: center; */
      bottom: 0;
      margin-bottom: 20px;
    }
</style>