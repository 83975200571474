import config from './webdb.js'
import {DeviceUUID} from 'device-uuid';
var websql = config['websql']();
export default {
    insertLoginDevice: function(device) {
        if(device) {
            var indexedb = config['indexedb']();
            if(websql){
                websql.transaction(function (tx) {
                    tx.executeSql('CREATE TABLE IF NOT EXISTS divce (id integer  primary key, uuid text)');
                    tx.executeSql('SELECT * FROM divce', [], function (tx, results) { 
                        var len = results.rows.length;
                        if(len == 0) {
                            localStorage.setItem('uuid', device)
                            tx.executeSql('INSERT INTO divce (id,uuid) VALUES (?, ?)', [1, device]);
                        }
                    }); 
                });
            }   else if(indexedb) {
                var opened = indexedb.open("eedb", 2);
                opened.onsuccess = function(event) {
                    
                    localStorage.setItem('uuid', device)
                    var db = event.target.result;
                    var deviceObjectStore = db.transaction("divce", "readwrite").objectStore("divce");
                    deviceObjectStore.add({id: 1, uuid: device});
    
                };
            }
        }
    },

    registerDevice: function() {
        var indexedb = config['indexedb']();
        if(websql){
            websql.transaction(function (tx) {
                tx.executeSql('CREATE TABLE IF NOT EXISTS divce (id integer  primary key, uuid text)'); 
                tx.executeSql('SELECT * FROM divce', [], function (tx, results) {
                    // console.log(results);
                    var len = results.rows.length;
                    if(len == 0) {
                        var token = window['device']?window['device']:getDeviceUUid();
                        localStorage.setItem('uuid', token)
                        tx.executeSql('INSERT INTO divce (id,uuid) VALUES (?, ?)', [1, token]); 
                    }else{
                        
                        // Edit By Mouathor
                        var token = window['device']?window['device']:getDeviceUUid();
                        localStorage.setItem('uuid', token)
                    }
                }); 
            });
        }   else if(indexedb) {
            console.log(indexedb);
            var opened = indexedb.open("eedb", 2);
            opened.onsuccess = function(event) {

                var token = window['device']?window['device']:getDeviceUUid();
                localStorage.setItem('uuid', token)
                var db = event.target.result;
                var deviceObjectStore = db.transaction("divce", "readwrite").objectStore("divce");
                deviceObjectStore.add({id: 1, uuid: token});

            };
        } else {
            localStorage.setItem('uuid', window['device']?window['device']:getDeviceUUid())
        }
    },
    
    getDevice: function() {
        return new Promise(resolve => {
            var indexedb = config['indexedb']();
            if(websql) {
                websql.transaction(function (tx) {   
                    tx.executeSql('CREATE TABLE IF NOT EXISTS divce (id integer  primary key, uuid text)'); 
                    tx.executeSql('SELECT * FROM divce', [], function (tx, results) { 
                        var len = results.rows.length;
                        if(len > 0) {
                            localStorage.setItem('uuid', results.rows.item(0)["uuid"])
                            return resolve(results.rows.item(0)["uuid"]);
                        } else {
                            var token = window['device']?window['device']:getDeviceUUid();
                            localStorage.setItem('uuid', token)
                            tx.executeSql('INSERT INTO divce (id,uuid) VALUES (?, ?)', [1, token]);
                            return resolve(token);
                        }
                    });
                    //  tx.executeSql('DELETE FROM divce',[]);
                    //  resolve('true')
                });
            }  else if(indexedb) {
                var opened = indexedb.open("eedb", 2);
                opened.onsuccess = function(event) {
                    var db = event.target.result;
                    db.transaction("divce")
                    .objectStore("divce")
                    .get(1)
                    .onsuccess = function(event) {
                        if(event.target.result.uuid) {
                            localStorage.setItem('uuid', event.target.result.uuid)
                            return resolve( event.target.result.uuid);
                        } else {
                            var token = window['device']?window['device']:getDeviceUUid();
                            localStorage.setItem('uuid', token)
                            var deviceObjectStore = db.transaction("divce", "readwrite").objectStore("divce");
                            deviceObjectStore.add({id: 1, uuid: token});
                            return resolve( token );
                        }
                    }
                    // eslint-disable-next-line no-unused-vars
                    .onerror = function(event) {
                        localStorage.setItem('uuid', window['device']?window['device']:getDeviceUUid())
                        return resolve(localStorage.getItem('uuid'))
                    };
                };
                // eslint-disable-next-line no-unused-vars
                opened.onerror = function(event) {
                    localStorage.setItem('uuid', window['device']?window['device']:getDeviceUUid())
                    return resolve(localStorage.getItem('uuid'))
                };
            } else {
                localStorage.setItem('uuid', window['device']?window['device']:getDeviceUUid())
                return resolve(localStorage.getItem('uuid'))
            }
        });
    },
}
function getDeviceUUid() {
    var uuid = new DeviceUUID().get();
    return uuid;
}