<template>
    <div>
        <div :class="$vuetify.breakpoint.width > 800?'content':''">
            <div class="pb-1">
                <div :class="$vuetify.breakpoint.width > 800?'aboutUs':''">
                    <div class="pa-2">
                        <ul>
                            <li>
                               <b>{{$t("About.AboutUs")}}</b>
                            </li>
                            <li class="none-list">
                                <div :class="(lang == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'" style="text-align: justify">
                               “ 7tsu” {{$t("About.E_commerce_background1")}}
                                </div>
                            </li>
                            <br v-if="lang == 'en'">
                            <li class="none-list">
                                <div :class="(lang == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                                    {{$t("About.E_commerce_background2")}}
                                </div>
                            </li>
                        </ul>
                        <br>
                        <ul>
                            <li>
                                <b>{{$t("About.Contact")}}</b>
                            </li>
                            <li class="none-list">
                               <v-row dense no-gutters>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-img src="@/assets/images/7tsulocation.jpeg" style="box-shadow: 1px 1px 5px grey" />
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <div class="pa-6">
                                            <div class="stsu-logo">
                                                <v-img src="@/assets/images/7tsulogoNew3.png" width="100"/>
                                                <b class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('Contact.7TSU_Company')}}</b>
                                            </div>
                                            <br>
                                            <div class="">
                                                <div>
                                                    {{$i18n.t('Contact.Address_detail')}} <br />
                                                    {{$i18n.t('Contact.Tel')}} <br />
                                                    {{$i18n.t('Contact.Fax')}} <br />
                                                    {{$i18n.t('Contact.Email')}} <br />
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Footer />
        </div>
    </div>
</template>
<script>
import Footer from'../../views/Footer'
import {mapGetters} from 'vuex'
export default {
     components:{
        Footer
    },
  data() {
    return {}
  },
  computed:{
      ...mapGetters ({
          lang: "generalInfo/lang"
      })
  },
  created(){
      window.scrollTo(0,0);
  }
}
</script>

<style scoped>
    .content{
        background-color: rgb(229, 236, 240);
        height: 800px
    }
    .aboutUs{
        background-color: white;
        margin-left: 5%;
        margin-right: 5%;
        box-shadow: 0px -1px 4px 0px grey;
        height: 800px
    }
    .dot{
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: black;
        position: absolute;
    }
    .none-list{
        list-style: none;
    }
    .tab-first-line{
         text-indent: 25px;
    }
</style>