import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
Vue.use(Vuex)

 const GeneralInfo = {
  namespaced: true,
  state: {
    TopCategory: false,
    MyUserPageSide:'login',
    Country:[],
    LoginStatus: localStorage.getItem("n_page_log")?localStorage.getItem("n_page_log"):'',
    CustomerProfile: localStorage.getItem("profile")?localStorage.getItem("profile"):'',
    Token: localStorage.getItem("Tk")?localStorage.getItem("Tk"):'', 
    lang: localStorage.getItem("lang")?localStorage.getItem("lang"):'', 
  },
  mutations: {
    SetTopCategory(state, data){
      state.TopCategory = data
    },
    m_setLang(state, data){
      state.lang = data
    },
    SetUserPageSide(state, data){
      state.MyUserPageSide = data
    },
    SetCountry(state, data){
      state.Country = data
    },
    SetUserProfile(state, data){
      state.CustomerProfile = data
    },
    SetToken(state, data){
      state.Token = data
    },
    SetLoginStatus(state, data){
      state.LoginStatus = data
    }
    
  },

  actions: {
    SetTopCategory(context, data){
      context.commit('SetTopCategory', data);
    },
    SetNewLang(context, lang){
      window.localStorage.setItem("lang", lang.toLowerCase());
      context.commit('m_setLang', lang);
    },
    SetUserPageSide(context, side){
      context.commit('SetUserPageSide', side)
    },
    GetCountry(context){
      axios.get(server['_7tsuAPI'] + "/api/7tsu/customer/another/country",{
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res) => {
        const Respon = res["data"];
        if(Respon["success"] == true && Respon["code"]){
          context.commit("SetCountry", Respon["data"]);
        }
      });
    },
    SetUserProfile(context, data){
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), server['cryptoJSkey']).toString();
        window.localStorage.setItem('profile',encrypted);
        context.commit("SetUserProfile", encrypted);
    },
    SetToken(context, data){
        window.localStorage.setItem('Tk',data);
        context.commit("SetToken", data);
    },

    getCustomerInfoByDevice(context, data){
      /*
        axios.post(server['_7tsuAPI'] + "/api/7tsu/customer/user/info",data,{
          headers:{
            'Content-Type': 'application/json',
            'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
            'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
            'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
          }
        }).then((res) => {
          const respone = res["data"];
          if(respone["success"] == true && respone["code"]){
            const userprofile = {
              cus_id:respone["data"]["cus_id"],
              cus_name:respone["data"]["cus_name"],
              first_name:respone["data"]["first_name"],
              last_name:respone["data"]["last_name"],
              city:respone["data"]["city"],
              country_code:respone["data"]["country_code"],
              country_en:respone["data"]["country_en"],
              country_la:respone["data"]["country_la"],
              device_id:respone["data"]["device_id"],
              email:respone["data"]["email"],
              tel:respone["data"]["tel"],
              address:respone["data"]["address"]
            }
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify(userprofile), server['cryptoJSkey']).toString();
            window.localStorage.setItem('profile',encrypted);
            context.commit("SetUserProfile", encrypted);
          }
        });   */
    },
    DoLoginStatus(context, data){ // if check userLogin on Delivery page then set the LoginStatus="onDelivery". After user login success then direct to Delivery page
      var encrypted = CryptoJS.AES.encrypt(data, server['cryptoJSkey']).toString();
      window.localStorage.setItem('n_page_log',encrypted); // n_page_log = Next page after login
      context.commit('SetLoginStatus', encrypted);
    }
  },
  
  getters:{
    topcategory: state => state.TopCategory,
    UserPageSide: state => state.MyUserPageSide,
    Country: state => state.Country,
    Token: state => state.Token,
    CustomerProfile: state => state.CustomerProfile,
    lang: state => state.lang,
    thePageAffterLogin: state => state.LoginStatus
  }
}

export default GeneralInfo;
