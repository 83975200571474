<template>
    <div class="Allproduct-content">
        <div class="red-devider"></div>
        <v-container class="pb-10">
            <div class="pt-10">
                <div class="content-side pl-3 pt-3 pb-3"> 
                    {{$i18n.t("AllProductPage.AllProduct")}} <v-icon>chevron_right</v-icon>
                </div>
            </div>
            
            <div class="content-side">
                <div v-if="Category.length > 0 && AllProduct.length > 0">
                    <div v-for="(category, i) in Category" :key="i">
                        <div class="section" v-if="AllProduct.filter((obj) => obj.category_id === category.cate_id ).length > 0">
                            <div class="ml-3">
                                <div class="pt-2 pb-2">
                                    <h3>{{($i18n.locale == 'la')?'ໝວດ'+category['category_la']:category['category_en']+' group'}}</h3>
                                </div>
                                <v-row>
                                    <v-col cols="12" md="3" lg="2" sm="4"  v-for="(product, key) in AllProduct.filter((obj) => obj.category_id === category.cate_id )" :key="key">
                                        <v-card class="rounded-0 product-card">
                                            <v-hover v-slot:default="{ hover }">
                                                <div :elevation="hover ? 6 : 2">
                                                <div class="pl-2 pr-2">
                                                    <v-img :aspect-ratio="16/9"  :src="server + product['imageList'][0]" ></v-img>
                                                </div>
                                                <v-card-text>
                                                    <div class="mb-2"><b>{{($i18n.locale=='la')?product["product_la"]:product["product_en"]}}</b></div>
                                                    <div class="mb-1 red--text" v-if="parseInt(product['priceList'][0]['advert_price']) > parseInt(product['priceList'][0]['price'])">
                                                    <del>
                                                        ${{product['priceList'][0]['advert_price']}}
                                                    </del>
                                                    </div>
                                                    <div class="green--text">${{product['priceList'][0]['price']}}</div>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn icon @click="AddToCart(
                                                            {
                                                                category_id:product['category_id'], 
                                                                type_id:product['ptype_id'], 
                                                                product_la:product['product_la'],
                                                                product_en:product['product_en'],

                                                                productId:product['product_id'],
                                                                unitNo:product['priceList'][0]['unit_no'],
                                                                priceNo:product['priceList'][0]['price_id'],
                                                                price:product['priceList'][0]['price'],
                                                                quantity:product['priceList'][0]['min_qty'], 

                                                                imageList:product['imageList'],
                                                                unitList:product['unitList'],
                                                                priceList:product['priceList'],
                                                            })
                                                        ">
                                                        <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                                    </v-btn>
                                                        
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="NavigateToProductDetail('/product/detail/' ,product.product_id)" color="primary">
                                                        {{$i18n.t('HomePage.Detail')}}
                                                    </v-btn>
                                                </v-card-actions>
                                                </div>
                                            </v-hover>
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mt-5">
                                <hr>
                            </div>
                        </div>
                    </div>
                    <div>
                        <v-row>
                            <v-col cols="12" v-if="gettingnewprod">
                                <div class="text-center pt-15 pb-5">
                                    <v-progress-circular
                                        :size="70"
                                        :width="7"
                                        color="rgb(19, 150, 171)"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12">
                                <div class="pa-5 load-new-product" v-if="!IsLastProductItem">
                                <span style="cursor: pointer;" @click="LoadNewProduct()"> <v-icon>expand_more</v-icon> <b class="load-new-product-span">{{$i18n.t("AllProductPage.Showmore")}}</b> </span>
                                    <v-progress-linear
                                        color="rgb(19, 150, 171)"
                                        buffer-value="0"
                                        stream
                                        reverse
                                        style="width: 200px;"
                                        v-if="gettingnewprod"
                                    ></v-progress-linear>   
                                    <!-- <hr style="display: none;" class="pl-5 hr-line">                           -->
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                
                <!-- <div v-if="Category.length > 0 && AllProduct.length > 0">
                   <v-row>
                        <v-col cols="12" md="3" lg="2" sm="4"  v-for="(product, key) in AllProduct" :key="key">
                            <v-card class="rounded-0 product-card">
                                    <div class="pl-2 pr-2">
                                        <v-img :src="server + product['imageList'][0]" ></v-img>
                                    </div>
                                    <v-card-text>
                                        <div class="mb-2"><b>{{($i18n.locale=='la')?product["product_la"]:product["product_en"]}}</b></div>
                                        <div class="mb-1 red--text" v-if="parseInt(product['priceList'][0]['advert_price']) > parseInt(product['priceList'][0]['price'])">
                                        <del>
                                            ${{product['priceList'][0]['advert_price']}}
                                        </del>
                                        </div>
                                        <div class="green--text">${{product['priceList'][0]['price']}}</div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn icon @click="AddToCart(
                                                {
                                                    category_id:product['category_id'], 
                                                    type_id:product['ptype_id'], 
                                                    product_la:product['product_la'],
                                                    product_en:product['product_en'],

                                                    productId:product['product_id'],
                                                    unitNo:product['priceList'][0]['unit_no'],
                                                    priceNo:product['priceList'][0]['price_id'],
                                                    price:product['priceList'][0]['price'],
                                                    quantity:product['priceList'][0]['min_qty'], 

                                                    imageList:product['imageList'],
                                                    unitList:product['unitList'],
                                                    priceList:product['priceList'],
                                                })
                                            ">
                                            <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                        </v-btn>
                                            
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="NavigateToProductDetail('/product/detail/' ,product.product_id)" color="primary">
                                            {{$i18n.t('HomePage.Detail')}}
                                        </v-btn>
                                    </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" v-if="gettingnewprod">
                                <div class="text-center pt-15 pb-5">
                                    <v-progress-circular
                                        :size="70"
                                        :width="7"
                                        color="rgb(19, 150, 171)"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="pa-5 load-new-product" v-if="!IsLastProductItem">
                               <span style="cursor: pointer;" @click="LoadNewProduct()"> <v-icon>expand_more</v-icon> <b class="load-new-product-span">{{$i18n.t("AllProductPage.Showmore")}}</b> </span>
                                <v-progress-linear
                                    color="rgb(19, 150, 171)"
                                    buffer-value="0"
                                    stream
                                    reverse
                                    style="width: 200px;"
                                    v-if="gettingnewprod"
                                ></v-progress-linear>
                            </div>
                        </v-col>
                    </v-row>
                </div> -->

                <div v-else>
                    <v-row v-if="CheckIf_AllProduct_NotGetData">
                        <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                            <div class="pa-1">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <div class="text-center">
                            <h1 class="No-Data">No Data</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <v-dialog width="300" v-model="addcardDialog">
                    <v-card>
                    <v-card-title style="background-color: #1396ab; color:white">
                        {{$i18n.t('AddCardDialog.CardTitle')}}
                        <v-spacer></v-spacer>
                        <v-icon color="white"  @click="addcardDialog = !addcardDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                <div class="text-center mt-10 pb-0">
                                    <hr>
                                    <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="addcardDialog = !addcardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                    <hr>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </v-container>
        <div>
            <Footer />
        </div>
    </div>
</template>
<script>
import { mapActions ,mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
import Footer from'../../views/Footer.vue'
export default {
  components:{
    Footer
  },
    data() {
        return {
            productLimmit:0,
            gettingnewprod: false,
            IsLastProductItem: false,
            server: server['_7tsuAPI'],
            addcardDialog: false,
            ShoppingCartResponText: this.$i18n.t("ShopingCardText"),
            decrypt_key: server['cryptoJSkey'],
        }
    },

    computed: {
     ...mapGetters({
            Category:"menus/category",
            Submenu:"menus/submenu",
            AllProduct:"product/AllProduct",
            CheckIf_AllProduct_NotGetData:"product/CheckIf_AllProduct_NotGetData",
        })
    },

    created(){
        window.scrollTo(0,0);
        this.SetTopCategory(true);
        if(!this.AllProduct.length){
            this.GetAllProduct(this.productLimmit);

           /*
            this.Category.forEach(item => {
              setTimeout(() => {
                 this.GetAllProductByCategory({id: item.cate_id, limmit: this.productLimmit});
              }, 1000);
            });
           
           */

        }
    },

    methods: {
        ...mapActions('product', ["GetAllProduct", "GetNewProduct"]),
        ...mapActions("generalInfo",["SetTopCategory"]),
        ...mapActions("shoppingCart",["AddToShoppingCart"]),

        // ProductDetail(router){
        //     this.$router.push({path:router});
        // },

        NavigateToProductDetail(url, id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
        },
        AddToCart(data){
            this.AddToShoppingCart(data);
            this.addcardDialog = true;
        },
        LoadNewProduct(){
            this.gettingnewprod = true;
            this.GetNewProduct(this.AllProduct.length).then(res=>{
                    const Respon = res['data'];
                    if(Respon['success'] && Respon['code'] == 200){
                        this.gettingnewprod = false;
                        var ResponAllProduct = Respon['data']['all'];
                        var priceList=[];
                        var unitList=[];
                        var imageList=[];
                        if(ResponAllProduct.length > 0){
                            for(let i=0; i < ResponAllProduct.length; i++){
                            priceList = eval("["+ ResponAllProduct[i]["price_detail"] +"]");
                            unitList = eval("["+ ResponAllProduct[i]["unit_detail"] +"]");
                            imageList = JSON.parse( ResponAllProduct[i]["product_img"]);
                            ResponAllProduct[i]["priceList"] = priceList;
                            ResponAllProduct[i]["unitList"] = unitList;
                            ResponAllProduct[i]["imageList"] = imageList;
                            }
                            ResponAllProduct.forEach(element => {
                                this.AllProduct.push(element);
                            });

                            if(this.AllProduct.length >= Respon['data']["totalProduct"]){
                                this.IsLastProductItem = true;
                            }
                        }else{
                            alert("Interrupted");
                        }
                    }
            })
        }
    },
}
</script>

<style scoped>
.red-devider{ height: 5px; background-color: red }

    .Allproduct-content{
        background-color: #f5f5f5;
    }


    .content-side{
        width: 100%;
        background-color: white;
        border-left: grey 1px solid;
    }
    
    .section{
        background-color: white;
        margin-right: 5px;
        margin-top: 20px;
    }

    .product-card:hover{
        box-shadow: 0px 1px 5px 0px grey !important;
    }
    .No-Data{
        padding: 20%;
        opacity: 0.2;
    }

    .load-new-product{
        /* font-weight: bold; */
        font-size: 20px;
    }
    .load-new-product-span:hover{
        color: darkorange;
        cursor: pointer;
        text-decoration: underline;
    }

  
</style>