export default {
    websql: function(){
        try{
            if(window.openDatabase){
                var shortName = 'eedb';
                var version = '1.0';
                var displayName = 'Database';
                var maxSize = 2 * 1024 * 1024; // in bytes
                var db = window.openDatabase(shortName, version, displayName, maxSize);
                return db;
            }
        }catch(e){
            return null;
        }
    },
    indexedb: indexedb
}
indexedb()
function indexedb() {
    try {
        var request = window.indexedDB
        if(!window.openDatabase){
            var opened = request.open("eedb", 2);
            opened.onupgradeneeded = function(event) {
                var db = event.target.result;

                var deviceStore = db.createObjectStore("divce", { keyPath: "id" });
                deviceStore.createIndex("uuid", "uuid", { unique: false });
                deviceStore.createIndex("id", "id", { unique: true });
                // eslint-disable-next-line no-unused-vars
                deviceStore.transaction.oncomplete = function(event) {
                };

            };
            opened.onsuccess = function(event) {
                var db = event.target.result;
                
                var deviceStore = db.createObjectStore("divce", { keyPath: "id" });
                deviceStore.createIndex("uuid", "uuid", { unique: false });
                deviceStore.createIndex("id", "id", { unique: true });
                // eslint-disable-next-line no-unused-vars
                deviceStore.transaction.oncomplete = function(event) {
                };

            };
        }
        return request;
    } catch(e) {
        return null;
    }
}