<template>
    <div>
       <v-container>
           <div class="main-content">
            <div class="pa-3" v-if="SearchResult.length > 0" style="height:2000px">
                <div v-for="(product, key) in SearchResult" :key="key" class="section">
                    <v-row dense>
                        <v-col md="2" sm="2" xs="2" @click="ProductDetail('/product/detail/', product.product_id)">
                            <div class="pa-2">
                                <v-img :aspect-ratio="16/9"  :src="server + product['imageList'][0]" style="cursor:pointer"></v-img>
                            </div>
                        </v-col>
                        <v-col md="10" sm="10" xs="10">
                            <div @click="ProductDetail('/product/detail/', product.product_id)" style="cursor:pointer"><b>{{($i18n.locale=='la')?product["product_la"]:product["product_en"]}}</b></div>
                            <div class="pt-2" style="word-wrap: break-word;" v-html="($i18n.locale == 'la')?product.detail_la:product.detail_en"></div>
                            <div>
                                <span class="red--text mr-5" v-if="parseInt(product['priceList'][0]['advert_price']) > parseInt(product['priceList'][0]['price'])">
                                    <del>
                                        ${{product['priceList'][0]['advert_price']}}
                                    </del>
                                </span>
                                <span class="green--text">
                                    ${{product['priceList'][0]['price']}}
                                </span>
                                
                                <span style="float: right; margin-right: 10px">
                                    <v-btn icon @click="AddToCard(
                                    {
                                        category_id:product['category_id'], 
                                        type_id:product['ptype_id'], 
                                        product_la:product['product_la'],
                                        product_en:product['product_en'],

                                        productId:product['product_id'],
                                        unitNo:product['priceList'][0]['unit_no'],
                                        priceNo:product['priceList'][0]['price_id'],
                                        price:product['priceList'][0]['price'],
                                        quantity:product['priceList'][0]['min_qty'], 

                                        imageList:product['imageList'],
                                        unitList:product['unitList'],
                                        priceList:product['priceList'],
                                    })">
                                    <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                </v-btn>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                    <!-- Add product Dialog -->
                    <div>
                        <v-dialog width="300" v-model="AddToCardDialog">
                            <v-card>
                            <v-card-title style="background-color: #1396ab; color:white">
                                {{$i18n.t('AddCardDialog.CardTitle')}}
                                <v-spacer></v-spacer>
                                <v-icon color="white"  @click="AddToCardDialog = !AddToCardDialog" left>mdi-close</v-icon>
                                </v-card-title>
                                <v-card-text>
                                    <div class="mt-4">
                                        <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                        <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                        <div class="text-center mt-10 pb-0">
                                            <hr>
                                            <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCardDialog = !AddToCardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                            <hr>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
            </div>
            <div v-else  style="height: 635px">
                <div v-if="EmptySearchText" class="text-center" >
                    <h1 style="padding-top: 300px">{{EmptySearchText}}</h1>
                </div>
                <div v-else>
                    <v-sheet
                        color="rgb(241, 241, 241)"
                        class="pa-3">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="300"
                            type="card"
                            style="padding-top: 200px"
                        ></v-skeleton-loader>
                    </v-sheet>
                </div>
            </div>
           </div>
       </v-container>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
export default {
     inject: {
            theme: {
                default: { isDark: false },
            },
        },
    data() {
        return {
            server: server['_7tsuAPI'],
            decrypt_key: server['cryptoJSkey'],
            AddToCardDialog: false,
            EmptySearchText:""
        }
    },
    computed: {
        ...mapGetters({
            SearchResult:"search/SearchResult",
            totalSearchProduct:"search/totalSearchProduct",
        }),
    },

    created(){
        window.scrollTo(0,0);
        setTimeout(() => {
            if(this.SearchResult.length == 0){
                this.EmptySearchText = this.$t("SearchPage.SearchEmptyText");
            }
        }, 10000);
    },

    methods: {
        ...mapActions("shoppingCart",["AddToShoppingCart"]),
        ProductDetail(url, id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
        },

        AddToCard(data){
            this.AddToCardDialog = true;
            this.AddToShoppingCart(data);
            }
    },

}
</script>
<style scoped>
    .main-content{
        background-color: rgb(241, 241, 241);
    }
    .section{
        background-color: white;
        margin-right: 5px;
        margin-top: 20px;
    }
    .section:hover{
        box-shadow: 0px 0px 5px 0px gray;
        transition: ease-in-out 0.3s;
    }

</style>