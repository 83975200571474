<template>
    <div class="" >
        <div class="menuside d-none d-sm-block d-md-block d-lg-block" v-if="!$isMobile()">
           <div class="mt-3" v-if="MyCategory.length > 0">
               <div id="side_menu" v-if="$vuetify.breakpoint.mdAndUp">
                   <div class="pa-3 text-center">
                       <b>
                            {{($i18n.locale=='la')?MyCategory[0]["category_la"]:MyCategory[0]["category_en"]}}
                       </b>
                   </div>
                   <hr>
                    <ul id="menu">
                        <li v-for="(type, i) in AllProductTypeInCategory" :key="i" @click="SelectNewProductType(type.category_id, type.type_id)">
                            <div v-if="type.type_id === Group_Type_Decode.type" style="background-color: #ffd54f; color: white">
                              {{($i18n.locale=='la')?type.type_la:type.type_en}}
                            </div>
                            <div v-else>
                                {{($i18n.locale=='la')?type.type_la:type.type_en}}
                            </div>
                        </li>
                    </ul>
                </div> 
           </div>
           <div v-else>
               <v-sheet
                    color="rgb(241, 241, 241)">
                    <v-skeleton-loader
                        class="mx-auto"
                        max-width="300"
                        type="article"
                        style="padding-top: 20px"
                    ></v-skeleton-loader>
                </v-sheet>
           </div>
        </div>

        <div v-if="AllProductTypeInCategory.length > 0" >
            <!-- <span class="Show-LeftSideProductType-menu" id="ShowLeftSideProductTypemenu" style="display: none" @click="ShowCategorySide()">
                <span> {{$i18n.t('ProductType.ProductType_menu')}}</span>
            </span> -->
            <span class="Show-LeftSideProductType-menu" id="ShowLeftSideProductTypemenu" @click="ShowCategorySide()" v-if="$vuetify.breakpoint.smAndDown">
                {{$i18n.t('ProductType.ProductType_menu')}}
            </span>
            <div class="" id="side_menu_2" @mouseleave="HidSideMenu2()">
                <ul id="menu_2">
                    <li v-for="(type, i) in AllProductTypeInCategory" :key="i" @click="SelectNewProductType(type.category_id, type.type_id)">
                        <div v-if="type.type_id === Group_Type_Decode.type" style="background-color: #ffd54f; color: white">
                            {{($i18n.locale=='la')?type.type_la:type.type_en}}
                        </div>
                        <div v-else>
                            {{($i18n.locale=='la')?type.type_la:type.type_en}}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <div :class="$isMobile()?'':'content-side'" id="Content_Side_ID" style="background-color:#f5f5f5" >
        <!-- <div style="background-color:#f5f5f5"> -->
             <div>
                 <br>
                 <div class="section ml-3 pa-3">
                    <div v-if="MyCategory.length > 0">
                        <span>{{($i18n.locale=='la')?MyCategory[0]["category_la"]:MyCategory[0]["category_en"]}}</span><v-icon>chevron_right</v-icon>
                        <span>
                            {{($i18n.locale == 'la')?AllProductTypeInCategory.filter((obj)=>obj.type_id === Group_Type_Decode.type)[0]['type_la']:AllProductTypeInCategory.filter((obj)=>obj.type_id === Group_Type_Decode.type)[0]['type_en']}}
                        </span>
                    </div>
                    <div v-else>
                        <v-sheet
                            color="rgb(241, 241, 241)"
                            >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="article"
                            ></v-skeleton-loader>
                        </v-sheet>
                    </div>
                </div>

                <div class="section ml-3 pa-2">
                    <div class="pa-1">
                        <h3>{{$i18n.t('ProductType.AllProductOfType')}}</h3>
                    </div>
                    <v-row v-if="AllProductType.length > 0">
                        <v-col cols="12" md="3" lg="2" sm="4"  v-for="(product, key) in AllProductType" :key="key">
                            <v-card class="rounded-0 product-Card">
                                <div class="pl-2 pr-2 pt-2">
                                    <v-img :aspect-ratio="16/9" :src="server + product['imageList'][0]" ></v-img>
                                </div>
                                <v-card-text>
                                    <div class="mb-2">
                                        <b>{{($i18n.locale == 'la')?product.product_la:product.product_en}}</b>
                                    </div>
                                    <div class="mb-1 red--text" v-if="parseInt(product.priceList[0]['advert_price']) > parseInt(product.priceList[0]['price'])">
                                        <del>${{product.priceList[0]['advert_price']}}</del>
                                    </div>
                                    <div class="mb-2 green--text">
                                        ${{product.priceList[0]['price']}}
                                    </div>
                                </v-card-text>
                                 <v-card-actions>
                                    <v-btn icon
                                    @click="AddToCard(
                                        {
                                        category_id:product['category_id'], 
                                        type_id:product['ptype_id'], 
                                        product_la:product['product_la'],
                                        product_en:product['product_en'],
                                        
                                        productId:product['product_id'],
                                        unitNo:product['priceList'][0]['unit_no'],
                                        priceNo:product['priceList'][0]['price_id'],
                                        price:product['priceList'][0]['price'],
                                        quantity:product['priceList'][0]['min_qty'], 

                                        imageList:product['imageList'],
                                        unitList:product['unitList'],
                                        priceList:product['priceList'],
                                      }
                                    )"
                                    >
                                        <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="NavigateToProductDetail('/product/detail/', product.product_id)" color="primary">
                                        {{$i18n.t('HomePage.Detail')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" v-if="gettingnewprod">
                                <div class="text-center pt-15 pb-5">
                                    <v-progress-circular
                                        :size="70"
                                        :width="7"
                                        color="rgb(19, 150, 171)"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="pa-5 load-new-product" v-if="!IsLastProductItem">
                               <span style="cursor: pointer;" @click="LoadNewProductType()"> <v-icon>expand_more</v-icon> <b class="load-new-product-span">{{$i18n.t("AllProductPage.Showmore")}}</b> </span>
                                <v-progress-linear
                                    color="rgb(19, 150, 171)"
                                    buffer-value="0"
                                    stream
                                    reverse
                                    style="width: 200px;"
                                    v-if="gettingnewprod"
                                ></v-progress-linear>
                            </div>
                        </v-col>
                    </v-row> 
                    <!-- <div v-if="AllProductType.length > 0">
                        <v-data-iterator
                            :items="AllProductType"
                            :items-per-page.sync="ipp"
                            :page.sync="page"
                            hide-default-footer
                        >
                            <template v-slot:default="props">
                            <v-row>
                                <v-col
                                v-for="(product,idx) in props.items"
                                :key="idx"
                                :cols="(12/itemsPerRow)"
                                >
                                <v-card class="fill-height">
                                    <div class="pl-2 pr-2 pt-2">
                                        <v-img :aspect-ratio="16/9" :src="server + product['imageList'][0]" ></v-img>
                                    </div>
                                    <v-card-text>
                                        <div class="mb-2">
                                            <b>{{($i18n.locale == 'la')?product.product_la:product.product_en}}</b>
                                        </div>
                                        <div class="mb-1 red--text" v-if="parseInt(product.priceList[0]['advert_price']) > parseInt(product.priceList[0]['price'])">
                                            <del>${{product.priceList[0]['advert_price']}}</del>
                                        </div>
                                        <div class="mb-2 green--text">
                                            ${{product.priceList[0]['price']}}
                                        </div>
                                    </v-card-text>  
                                </v-card>
                                </v-col>
                            </v-row>
                            </template>
                        </v-data-iterator>
                        <div>
                            <v-pagination v-model="page" :length="numberOfPages"  :total-visible="7"></v-pagination>
                        </div>
                    </div> -->
                    <div v-else>
                        <v-row v-if="CheckIf_AllProductType_NotGetData">
                            <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </v-col>
                        </v-row>
                        <div v-else>
                            <div class="text-center">
                                <h1 class="No-Data">{{$i18n.t('History.No_Data')}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>

         <!-- Add product Dialog -->
          <div>
           <v-dialog width="300" v-model="AddToCardDialog">
                    <v-card>
                        <v-card-title style="background-color: #1396ab; color: white">
                            {{$i18n.t('AddCardDialog.CardTitle')}}
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="AddToCardDialog = !AddToCardDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                <div class="text-center mt-10 pb-0">
                                    <hr>
                                    <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCardDialog = !AddToCardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                    <hr>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
          </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
export default {
    data() {
        return {
            submenuIndex: -1,
            productLimmit:0,
            gettingnewprod: false,
            Group_Type_Decode:{},
            server: server['_7tsuAPI'],
            AddToCardDialog: false,
            ShoppingCartResponText: this.$i18n.t("ShopingCardText"),
            decrypt_key: server['cryptoJSkey'],
            rowsPerPage: 8,
            page: 1,
        }
    },

    
    computed:{
        ...mapGetters({
            selectedproduct:"product/selectedproduct",
            Categories:"menus/category",
            AllProductType:"product/AllProductType",
            Submenus:"menus/submenu",
            ShoppingCart:"shoppingCart/ShoppingCart",
            ShopingCartRespon:"shoppingCart/ShopingCartRespon",
            CheckIf_AllProductType_NotGetData:"product/CheckIf_AllProductType_NotGetData",
            IsLastProductItem:"product/IsLastProductItem",
        }),

        AllProductTypeInCategory:function(){
            var bytes  = CryptoJS.AES.decrypt(this.selectedproduct, server['cryptoJSkey']);
            this.Group_Type_Decode = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if(this.Group_Type_Decode.group && this.Group_Type_Decode.type){
                const AllSubTypeInCategory = this.Submenus.filter((obj)=>obj.category_id == this.Group_Type_Decode.group);
                return AllSubTypeInCategory;
            }
            return []
        },

        MyCategory(){
            if(this.AllProductTypeInCategory.length){
                return this.Categories.filter((obj)=> obj.cate_id == this.AllProductTypeInCategory[0]["category_id"])
            }
            return []
        },


        // this for pargination
        numberOfPages () {
            return Math.ceil(this.AllProductType.length / this.ipp)
        },

        itemsPerRow: function() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": 
                    return 1 
                case "sm": 
                    return 2 
                case "md": 
                    return 3 
                case "lg": 
                    return 4 
                case "xl":
                    return 6
                default: return 0
            }
        },

        ipp () {
            switch (this.itemsPerRow) {
                case 1: 
                    return 1 
                case 2: 
                    return 2 
                case 3: 
                    return 3 
                case 4: 
                    return 4 
                case 6:
                    return 6
                default: return 0
            }
            
        }
        // ////////////////////////////////////
        
    },

    mounted: function (){
        if(document.getElementById('Content_Side_ID')){
            if(window.innerWidth <= 959){
                document.getElementById('Content_Side_ID').classList.remove('content-side');
            }

            window.addEventListener("resize", function(){
                if(window.innerWidth <= 959 && document.getElementsByClassName('content-side') ){
                        if(document.getElementById('Content_Side_ID')){
                        document.getElementById('Content_Side_ID').classList.remove('content-side');
                        }
                    }

                    if(window.innerWidth >= 960 ){
                        if(document.getElementById('Content_Side_ID')){
                            document.getElementById('Content_Side_ID').classList.add('content-side');
                        }
                    }
                });
            };
    },
    
    created(){
        window.scrollTo(0,0);
        if(this.$isMobile()){
            // document.getElementById('ShowLeftSideProductTypemenu').css("display","block");
            $('.Show-LeftSideProductType-menu').css("display","block");
        }
        var bytes = CryptoJS.AES.decrypt(this.selectedproduct, server['cryptoJSkey']);
        this.Group_Type_Decode = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.SetTopCategory(true);
        this.GetAllProductType({type: this.Group_Type_Decode.type, limit: this.productLimmit});
    },
    beforeDestroy(){
         this.SetTopCategory(false);
    },

    methods: {
        ...mapActions("product",["GetAllProductType", "SetSelectedProduct", "GetNewProductType", "DoSetIsLastProductItem"]),
        ...mapActions("generalInfo",["SetTopCategory"]),
        ...mapActions("shoppingCart",["AddToShoppingCart"]),
        NavigateToPage(router){
            this.$router.push({path: router})
        },
        NavigateToProductDetail(url, id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`})
        },
        SelectNewProductType(group_id, type_id){
            // console.log(Respon);
            const data = {group: group_id, type: type_id, limit: 0};
            this.SetSelectedProduct({group: group_id, type: type_id,}); // ສົ່ງກຸ່ມ ແລະ ປະເພດໄປເກັບໃນ localstorage
            this.GetAllProductType(data);
            $('#side_menu_2').css("display", "none");
        },
        AddToCard(data){
            this.AddToShoppingCart(data);
            this.AddToCardDialog = true;
        },

        ShowCategorySide(){
            $('#side_menu_2').css("display", "block")
        },

        HidSideMenu2(){
            $('#side_menu_2').css("display", "none")
        },


         LoadNewProductType(){
            this.gettingnewprod = true;
            var data = {type: this.Group_Type_Decode.type, limit: this.AllProductType.length}
            this.GetNewProductType(data).then((res)=>{
                    var Respon = res['data'];
                    console.log(Respon);
                    if(Respon['success'] && Respon['code'] == 200){
                         this.gettingnewprod = false;
                      var ResponAllProductType = Respon['data']['products'];
                      var priceList=[];
                      var unitList=[];
                      var imageList=[];
                      if(ResponAllProductType.length > 0){
                        for(let i=0; i< ResponAllProductType.length;i++){
                          priceList = eval('['+ResponAllProductType[i]["price_detail"]+']');
                          unitList = eval('['+ResponAllProductType[i]["unit_detail"]+']');
                          imageList = JSON.parse(ResponAllProductType[i]['product_img']);
                          ResponAllProductType[i]['priceList'] = priceList;
                          ResponAllProductType[i]['unitList'] = unitList;
                          ResponAllProductType[i]['imageList'] = imageList;
                        }
                    //   context.commit('SetAllProductType',ResponAllProductType)
                        ResponAllProductType.forEach(element => {
                            this.AllProductType.push(element);
                        });

                        if(this.AllProductType.length >= Respon['data']["totalProduct"]){
                            this.DoSetIsLastProductItem(true);
                        }

                      }else{
                        this.DoSetIsLastProductItem(true);
                      }
                    }
                })
                
            //     .then(res=>{
            //         const Respon = res['data'];
            //         if(Respon['success'] && Respon['code'] == 200){
            //             this.gettingnewprod = false;
            //             var ResponAllProduct = Respon['data']['all'];
            //             var priceList=[];
            //             var unitList=[];
            //             var imageList=[];
            //             if(ResponAllProduct.length > 0){
            //                 for(let i=0; i < ResponAllProduct.length; i++){
            //                 priceList = eval("["+ ResponAllProduct[i]["price_detail"] +"]");
            //                 unitList = eval("["+ ResponAllProduct[i]["unit_detail"] +"]");
            //                 imageList = JSON.parse( ResponAllProduct[i]["product_img"]);
            //                 ResponAllProduct[i]["priceList"] = priceList;
            //                 ResponAllProduct[i]["unitList"] = unitList;
            //                 ResponAllProduct[i]["imageList"] = imageList;
            //                 }
            //                 ResponAllProduct.forEach(element => {
            //                     this.AllProduct.push(element);
            //                 });

            //                 if(this.AllProduct.length >= Respon['data']["totalProduct"]){
            //                     this.IsLastProductItem = true;
            //                 }
            //             }else{
            //                 alert("Interrupted");
            //             }
            //         }
            // })
        }
    }
}
</script>

<style scoped>
    .producttype-content{
        background-color: #f5f5f5;
        padding-bottom: 10px;
    }

    .menuside{
        position: fixed;
        top: 0px;
        width: 200px;
        margin-top: 100px;
        padding: 5px;
        z-index:2;
    }
    .content-side{
        position: relative;
        float: right;
        left: 200px !important;
        padding-right: 205px;
        padding-bottom: 50px;
        width: 100%;
        background-color: #f5f5f5;
        border-left: grey 1px solid;
    }
    .section{
        background-color: white;
        margin-right: 5px;
        margin-top: 20px;
    }

    

        
    #side_menu{
    list-style: none;
    }
    ul#menu>li:hover{
    color: red;
    cursor: pointer;
    }

    ul>li {
    list-style: none;
    width: 100%;
    padding: 5px;
    border-bottom: gray 1px solid;
    }
    ul#menu>li:hover > #submenu {
    display: block;
    }
    #submenu{
    display: none;
    z-index: 2;
    position: absolute;
    margin-left: 150px;
    top: 10px;
    width: 200px;
    height: 800px;
    text-align: left;
    padding: 10px;
    color: black;
    background-color: white;
    box-shadow: -1px 1px 3px rgb(180, 179, 179);

    }
    ul#submenu>li:hover {
    color: red;
    cursor: pointer;
    }

    /* side menu 2  on sm & xs screen*/
    #side_menu_2{
    display: none;
    position: fixed;
    z-index: 10;
    height: 400px;
    width: 150px;
    top: 90px;
    margin-top: 5px;
    font-size: 11px;
    background-color: rgb(238, 237, 236);
    box-shadow: 0px 1px 1px rgb(180, 179, 179);
    }

    ul#menu_2>li:hover{
    color: red;
    cursor: pointer;
    }

    .product-item:hover{
        box-shadow: grey 1px 1px 5px 1px;
        transition: ease-in-out 0.3s;
    }

    .detail-navigation:hover{
        text-decoration-line: underline;
        text-decoration-style: solid;
        cursor: pointer;
    }

    .Show-LeftSideProductType-menu{
        position: fixed;
        z-index: 9;
        top: 200px;
        height: 50px;
        width: 65px;
        padding-top: 10px;
        padding-left: 5px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        background-color: #64b5f6;
        color: white;
        cursor: pointer;
        box-shadow: 1px 1px 5px grey;
        }
    .Show-LeftSideProductType-menu {
        position: absolute;
        margin-top: 10px;
    }

 .product-card:hover{
       box-shadow: 0px 1px 5px 0px grey !important;
    }
.No-Data{
    padding: 20%;
    opacity: 0.2;
}



</style>