import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

 const ShoppingCart = {
  namespaced: true,
  state: {
    ShoppingCart:localStorage.getItem("cartproduct")?JSON.parse(localStorage.getItem("cartproduct")):[],
    // ShoppingCart: [],
    cart_status: localStorage.getItem("c_status")?localStorage.getItem("c_status"):false,
    ShopingCartRespon: false,
  },
  mutations: {
    
    SetToCart(state, data){
        if(state.ShoppingCart.length > 0){
          if(state.ShoppingCart.filter((obj)=>obj.productId === data["productId"]).length > 0){
            for (let i = 0; i < state.ShoppingCart.length; i++) {
              if(state.ShoppingCart[i]["productId"] === data["productId"]){
                state.ShoppingCart[i]["quantity"] = parseInt(state.ShoppingCart[i]["quantity"]) + parseInt(data["quantity"]);
                state.ShoppingCart[i]["priceNo"] =  data["priceNo"];
                state.ShoppingCart[i]["price"] =  data["price"];
                state.ShoppingCart[i]["unitNo"] =  data["unitNo"];
              }     
            }
          }else{
            state.ShoppingCart.push(data);
          }
        }else{
            state.ShoppingCart.push(data);
        }
        window.localStorage.setItem("cartproduct", JSON.stringify(state.ShoppingCart));
        state.cart_status = true;
        localStorage.setItem("c_status", true);
    },
    UpdateCartItem(state, data){
      state.ShoppingCart = data;
      window.localStorage.setItem("cartproduct", JSON.stringify(state.ShoppingCart));
    },
    SetCartEmpty(state, data){
      state.ShoppingCart = data;
      window.localStorage.setItem("cartproduct", JSON.stringify([]));
    },

    set_cart_status(state, data){
      state.cart_status = data;
      localStorage.setItem("c_status", data);
    }
  },

  actions: {
    AddToShoppingCart(context, data){
      context.commit('SetToCart', data);
      return true;
    },

    UpdateCardItem(context, data){
      context.commit('UpdateCartItem', data);
    },

    ClearCard(context, data){
      context.commit("SetCartEmpty", data);
    },

    DoCartStatus(context, data){
      context.commit("set_cart_status", data);
    }
  },

  getters:{
    ShoppingCart: state => state.ShoppingCart,
    cart_status: state => state.cart_status,
    ShopingCartRespon: state => state.ShopingCartRespon
  }
}

export default ShoppingCart;
