<template>
  <div>
    <div class="">
      <v-row style="background-color: white">
        <v-col cols="12" md="3" lg="3" class="d-md-block d-lg-block d-sm-none d-sx-none text-right my_col">
          <div class="d-none d-md-block d-lg-block" id="side_menu">
            <div v-if="Category.length > 0">
              <ul id="menu">
                <div v-for="(category, i) in Category" :key="i">
                  <li v-if="SubMenu.filter((obj)=>obj.category_id === category['cate_id']).length > 0"  @mouseover="ShowType(category.cate_id, i)">
                    {{($i18n.locale=='la')?category.category_la:category.category_en}}
                    <ul id="submenu" v-if="(submenuIndex == i) && SubMenuList.length">
                        <li v-for="(type, j) in SubMenuList" :key="j" @click="NavigateToProductType('/Product/type',category.cate_id, type.type_id)"> 
                          {{($i18n.locale == 'la')?type.type_la:type.type_en}}
                        </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </div>
            <div v-else>
              <v-sheet
              color="rgb(241, 241, 241)">
              <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  type="article"
                  style="padding-top: 20px"
              ></v-skeleton-loader>
            </v-sheet>
           </div>
          </div>
        </v-col>
        <v-col cols="12" md="9" lg="9" sm="12" sx="12" class="my_col">
          <v-carousel cycle interval="10000" :show-arrows="false"  hide-delimiter-background>
            <v-carousel-item v-for="(item,i) in carouselImgs" :key="i">
              <div class="">
                <div v-if="item.view_shop == false" class="white--text carousel1">
                  <div class="page1-text-content">
                    <div class="TimeNewRoman-Phetsarath-OT" style="font-size:22px">{{item.welcom}}</div>
                    <div class="TimeNewRoman-Phetsarath-OT pt-5" style="font-size:18px">{{item.page1article1}}</div>
                    <div class="TimeNewRoman-Phetsarath-OT pt-5" style="font-size:18px">{{item.page1article2}}</div>
                  </div>
                  <div class="mt-15">
                    <div class="link-tab">
                      <span class="link-circle rounded-circle d-inline-block" style="padding:7px; background-color: #81d4fa;"  @click="NavigateToEcommerce('https://twitter.com/Yangcho73053753?t=ETFU5KVZDZXxe7ZOUXMqQg&s=08')">
                        <v-img src="@/assets/images/e-commerce-icon/twitter.png" />
                      </span>
                      <span class="link-background text-center" style="background-color: #81d4fa;"  @click="NavigateToEcommerce('https://twitter.com/Yangcho73053753?t=ETFU5KVZDZXxe7ZOUXMqQg&s=08')">twitter</span>
                    </div>
                    <br>
                    <div class="link-tab pt-5" >
                      <span class="link-circle rounded-circle d-inline-block" style="padding:7px; background-color: red;" @click="NavigateToEcommerce('https://www.youtube.com/channel/UCxSQNKZ4PrEyRE2GZYkGduQ')">
                        <v-img src="@/assets/images/e-commerce-icon/youtube.png" />
                      </span>
                      <span class="link-background text-center" style="background-color: red;" @click="NavigateToEcommerce('https://www.youtube.com/channel/UCxSQNKZ4PrEyRE2GZYkGduQ')">youtube</span>
                    </div>
                    <div class="link-tab pt-11">
                      <span class="link-circle rounded-circle d-inline-block" style="padding:7px; background-color: #01579b;"  @click="NavigateToEcommerce('https://www.facebook.com/yangchoutongxa/?ref=pages_you_manage')">
                        <v-img src="@/assets/images/e-commerce-icon/facebook.png" />
                      </span>
                      <span class="link-background text-center"  style="background-color: #01579b;"  @click="NavigateToEcommerce('https://www.facebook.com/yangchoutongxa/?ref=pages_you_manage')">facebook</span>
                    </div>
                  </div>
                </div>
                <div v-else class="page2-text-content">
                  <div>
                    <div style="font-size: 26px;" class="TimeNewRoman-Phetsarath-OT text-center">
                      {{item.page2article1}}
                    </div>
                    <br>
                    <v-card-actions class="justify-center">
                      <v-btn  class="primary" v-if="item.view_shop" to="/shop">
                      {{$i18n.t('HomePage.CarouselCentent.view_shop_btn')}}
                    </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </div>
              <v-img :src="item.path" style="width: 100% ;height: 500px"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <div class="home-content pb-10">
        <div class="" style="margin-left: 4%; margin-right:4%">          
          <div class="section2">
            <h2 class="mb-5"><b>{{$i18n.t('HomePage.AllProduct')}}</b></h2>
            <div v-if="AdvertProduct.length > 0">
              <v-row >
                <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(advert_product, key) in AdvertProduct.slice(0, 12)" :key="key" class="my_col" style="background: red">
                  <v-card class="mx-auto rounded-0 product-Card">
                    <div class="pl-2 pr-2">
                      <v-img :aspect-ratio="16/9"  :src="server_ip + JSON.parse(advert_product['product_img'])[0]" ></v-img>
                    </div>
                      <v-card-text class="pt-6">
                      <div class="mb-2"><b>{{($i18n.locale=='la')?advert_product["product_la"]:advert_product["product_en"]}}</b></div>
                      <div class="mb-1 red--text" v-if="parseInt(advert_product['priceList'][0]['advert_price']) > parseInt(advert_product['priceList'][0]['price'])">
                        <del>
                          ${{advert_product['priceList'][0]['advert_price']}}
                        </del>
                      </div>
                      <div class="mb-1 green--text">${{advert_product['priceList'][0]['price']}}</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="AddToCard(
                                  {
                                  category_id:advert_product['category_id'], 
                                  type_id:advert_product['ptype_id'], 
                                  product_la:advert_product['product_la'],
                                  product_en:advert_product['product_en'],
                                  
                                  productId:advert_product['product_id'],
                                  unitNo:advert_product['priceList'][0]['unit_no'],
                                  priceNo:advert_product['priceList'][0]['price_id'],
                                  price:advert_product['priceList'][0]['price'],
                                  quantity:advert_product['priceList'][0]['min_qty'], 

                                  imageList:advert_product['imageList'],
                                  unitList:advert_product['unitList'],
                                  priceList:advert_product['priceList'],
                                })"
                              color="green"
                              icon
                              >
                                <v-icon left>add_shopping_cart</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                              @click="NavigateToProductDetail('/product/detail/', advert_product.product_id)"
                            text
                            color="primary"
                          >
                            {{$i18n.t('HomePage.Detail')}}
                          </v-btn>
                      </v-card-actions>
                  </v-card>

                </v-col>
              </v-row>
                <div class="pa-3 text-right">
                  <span @click="NavigateToPage('/product/all')" class="toproduct_all">
                    {{$i18n.t('HomePage.AllProduct_navigation')}}
                  </span>
                </div>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                    <v-sheet
                        color="rgb(241, 241, 241)">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="300"
                            type="card"
                        ></v-skeleton-loader>
                    </v-sheet>
                </v-col>
            </v-row>
            </div>
          </div>

          <div class="section2 mt-5">
            <h2  class="mb-5"><b>{{$i18n.t('HomePage.Bestsaler')}}</b></h2>
              <div v-if="BestSellerProduct.length > 0">
                <v-row>
                  <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(bestseller, key) in BestSellerProduct.slice(0, 12)" :key="key" class="my_col">
                    <v-card class="mx-auto rounded-0 product-Card">
                            <div class="pl-2 pr-2">
                              <v-img :aspect-ratio="16/9"  :src="server_ip + JSON.parse(bestseller['product_img'])[0]" ></v-img>
                            </div>
                            <v-card-text class="pt-6">
                              <div class="mb-2"><b>{{($i18n.locale=='la')?bestseller.product_la:bestseller.product_en}}</b></div>
                              <div class="mb-1 red--text" v-if="parseInt(bestseller['priceList'][0]['advert_price']) > parseInt(bestseller['priceList'][0]['price'])">
                                <del>
                                  ${{bestseller['priceList'][0]['advert_price']}}
                                </del>
                              </div>
                              <div class="mb-2 green--text">${{bestseller['priceList'][0]['price']}}</div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn @click="AddToCard(
                                        {
                                        category_id:bestseller['category_id'], 
                                        type_id:bestseller['ptype_id'], 
                                        product_la:bestseller['product_la'],
                                        product_en:bestseller['product_en'],
                                        
                                        productId:bestseller['product_id'],
                                        unitNo:bestseller['priceList'][0]['unit_no'],
                                        priceNo:bestseller['priceList'][0]['price_id'],
                                        price:bestseller['priceList'][0]['price'],
                                        quantity:bestseller['priceList'][0]['min_qty'], 

                                        imageList:bestseller['imageList'],
                                        unitList:bestseller['unitList'],
                                        priceList:bestseller['priceList'],
                                      }
                                    )"
                                    color="green"
                                    icon
                                    >
                                      <v-icon class="">add_shopping_cart</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn 
                                  @click="NavigateToProductDetail('/product/detail/' , bestseller.product_id)" 
                                  text 
                                  color="primary"
                                >
                                  {{$i18n.t('HomePage.Detail')}}
                                </v-btn>

                            </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <div class="pa-3 text-right">
                  <span @click="NavigateToPage('/product/best/seller')" class="toproduct_all">
                    {{$i18n.t('HomePage.AllBestSellerProduct_navigation')}}
                  </span>
                  </div>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                      <v-sheet
                          color="rgb(241, 241, 241)">
                          <v-skeleton-loader
                              class="mx-auto"
                              max-width="300"
                              type="card"
                          ></v-skeleton-loader>
                      </v-sheet>
                  </v-col>
                </v-row>
              </div>
          </div>

          <div class="section2 mt-5">
            <h2  class="mb-5"><b>{{$i18n.t('HomePage.NewProduct')}}</b></h2>
            <div v-if="LatestSellerProduct.length > 0">
              <v-row>
                <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(LatestSeller, key) in LatestSellerProduct.slice(0, 12)" :key="key" class="my_col">
                  <v-card class="mx-auto rounded-0 product-Card">
                          <div class="pl-2 pr-2">
                            <v-img :aspect-ratio="16/9"  :src="server_ip + JSON.parse(LatestSeller['product_img'])[0]" ></v-img>
                          </div>
                          <v-card-text class="pt-6">
                              <div class="mb-2"><b>{{($i18n.locale=='la')?LatestSeller.product_la:LatestSeller.product_en}}</b></div>
                              <div class="mb-1 red--text" v-if="parseInt(LatestSeller['priceList'][0]['advert_price']) > parseInt(LatestSeller['priceList'][0]['price'])">
                                <del>
                                  ${{LatestSeller['priceList'][0]['advert_price']}}
                                </del>
                              </div>
                              <div class="mb-2 green--text">${{LatestSeller['priceList'][0]['price']}}</div>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="AddToCard(
                                      {
                                        category_id:LatestSeller['category_id'], 
                                        type_id:LatestSeller['ptype_id'], 
                                        product_la:LatestSeller['product_la'],
                                        product_en:LatestSeller['product_en'],
                                        
                                        productId:LatestSeller['product_id'],
                                        unitNo:LatestSeller['priceList'][0]['unit_no'],
                                        priceNo:LatestSeller['priceList'][0]['price_id'],
                                        price:LatestSeller['priceList'][0]['price'],
                                        quantity:LatestSeller['priceList'][0]['min_qty'], 

                                        imageList:LatestSeller['imageList'],
                                        unitList:LatestSeller['unitList'],
                                        priceList:LatestSeller['priceList'],
                                      }
                                  )"
                                  color="green"
                                  icon
                                  >
                                    <v-icon class="">add_shopping_cart</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn @click="NavigateToProductDetail('/product/detail/', LatestSeller.product_id)" text color="primary">
                                 {{$i18n.t('HomePage.Detail')}}
                              </v-btn>

                          </v-card-actions>
                  </v-card>

                </v-col>
              </v-row>
                <div class="pa-3 text-right">
                  <span @click="NavigateToPage('product/latest')" class="toproduct_all">
                    {{$i18n.t('HomePage.AllLatestProduct_navigation')}}
                  </span>
                </div>
            </div>
            <div v-else>
                <v-row>
                  <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                      <v-sheet
                          color="rgb(241, 241, 241)">
                          <v-skeleton-loader
                              class="mx-auto"
                              max-width="300"
                              type="card"
                          ></v-skeleton-loader>
                      </v-sheet>
                  </v-col>
                </v-row>
            </div>
          </div>


          <div class="section2 mt-5">
            <h2  class="mb-5"><b>{{$i18n.t('HomePage.Recommended')}}</b></h2>
            <div v-if="RecommendProduct.length > 0">
              <v-row>
                <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(recommend, key) in RecommendProduct.slice(0, 12)" :key="key" class="my_col">
                        <v-card class="mx-auto rounded-0 product-Card">
                          <div class="pl-2 pr-2">
                            <v-img :aspect-ratio="16/9"  :src="server_ip + JSON.parse(recommend['product_img'])[0]" ></v-img>
                          </div>
                         <v-card-text class="pt-6">
                              <div class="mb-2"><b>{{($i18n.locale=='la')?recommend.product_la:recommend.product_en}}</b></div>
                              <div class="mb-1 red--text" v-if="parseInt(recommend['priceList'][0]['advert_price']) > parseInt(recommend['priceList'][0]['price'])">
                                <del>
                                  ${{recommend['priceList'][0]['advert_price']}}
                                </del>
                              </div>
                              <div class="mb-2 green--text">${{recommend['priceList'][0]['price']}}</div>
                            </v-card-text>
                            <v-card-actions>
                            <v-btn @click="AddToCard(
                                 {
                                      category_id:recommend['category_id'], 
                                      type_id:recommend['ptype_id'], 
                                      product_la:recommend['product_la'],
                                      product_en:recommend['product_en'],

                                      productId:recommend['product_id'],
                                      unitNo:recommend['priceList'][0]['unit_no'],
                                      priceNo:recommend['priceList'][0]['price_id'],
                                      price:recommend['priceList'][0]['price'],
                                      quantity:recommend['priceList'][0]['min_qty'], 

                                      imageList:recommend['imageList'],
                                      unitList:recommend['unitList'],
                                      priceList:recommend['priceList'],
                                  }
                              )"
                              color="green"
                              icon
                              >
                                <v-icon class="">add_shopping_cart</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                            @click="NavigateToProductDetail('/product/detail/', recommend.product_id)"
                            text
                            color="primary"
                          >
                            {{$i18n.t('HomePage.Detail')}}
                          </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
                <div class="pa-3 text-right">
                  <span @click="NavigateToPage('product/recommend')" class="toproduct_all">
                    {{$i18n.t('HomePage.AllRecommendproduct_navigation')}}
                  </span>
                </div>
            </div>
            <div v-else>
                <v-row>
                  <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                      <v-sheet
                          color="rgb(241, 241, 241)">
                          <v-skeleton-loader
                              class="mx-auto"
                              max-width="300"
                              type="card"
                          ></v-skeleton-loader>
                      </v-sheet>
                  </v-col>
                </v-row>
            </div>
          </div>


          <div class="section2 mt-5">
            <h2  class="mb-5"><b>{{$i18n.t('HomePage.Shop')}}</b></h2>
            <div v-if="AllShop.length > 0">
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" v-for="(shop, index) in AllShop.slice(0, 8)" :key="index">
                    <v-card class="my-shop" max-width="400" @click="NavigateToMyShop('/shop/' ,shop.shop_no)">
                      <v-img class="white--text align-end" height="200px" :src="server_ip + shop['ImageList'][0]"></v-img>
                      <v-card-title class="pt-5 pb-1" style="position: relative;">
                        <span>{{($i18n.locale == 'la')?shop['shop_la']:shop['shop_en']}}</span>
                      </v-card-title>
                      <v-card-text class="pb-5 text--primary">
                          <div>
                            {{($i18n.locale == 'la')?shop['province_la']:shop['province_en']}}, {{($i18n.locale == 'la')?shop['district_la']:shop['district_en']}}, {{shop["village"]}}
                          </div>
                        </v-card-text>
                    </v-card>
                  </v-col>
              </v-row>
                <div class="pa-3 text-right">
                  <span @click="NavigateToPage('shop')" class="toproduct_all">
                    {{$i18n.t('HomePage.AllShop_navigation')}}
                  </span>
                </div>
            </div>
            <div v-else>
                <v-row>
                  <v-col cols="12" md="3" lg="2" sm="6"  v-for="(item, key) in 6" :key="key">
                      <v-sheet
                          color="rgb(241, 241, 241)">
                          <v-skeleton-loader
                              class="mx-auto"
                              max-width="300"
                              type="card"
                          ></v-skeleton-loader>
                      </v-sheet>
                  </v-col>
                </v-row>
            </div>
          </div>

          <!-- Add product Dialog -->
          <div>
            <v-dialog width="300" v-model="AddToCardDialog">
                <v-card>
                   <v-card-title style="background-color: #1396ab; color:white">
                      {{$i18n.t('AddCardDialog.CardTitle')}}
                      <v-spacer></v-spacer>
                      <v-icon color="white"  @click="AddToCardDialog = !AddToCardDialog" left>mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                         <div class="mt-4">
                            <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                            <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                            <div class="text-center mt-10 pb-0">
                                <hr>
                                <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCardDialog = !AddToCardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                <hr>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from'./Footer.vue'
import { mapActions ,mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
export default {
  components:{
    Footer
  },
  data() {
    return {
      name: "",
      show: false,
      SubMenuList:[],
      submenuIndex: -1,

      AddToCardDialog: false,
      ShoppingCartResponText: this.$i18n.t("ShopingCardText"),

      server_ip: server['_7tsuAPI'],
      decrypt_key: server['cryptoJSkey'],
    };
  },

  created(){
    this.SetTopCategory(false);
  },

  computed: {
     ...mapGetters({
        Category:"menus/category",
        SubMenu:"menus/submenu",
        TotalProduct_count:"mixproduct/TotalProduct_count",
        AdvertProduct:"mixproduct/AdvertProduct",
        BestSellerProduct:"mixproduct/bestSellerProduct",
        LatestSellerProduct:"mixproduct/latestSellerProduct",
        RecommendProduct:"mixproduct/RecommendProduct",
        ShoppingCart:"shoppingCart/ShoppingCart",
        ShopingCartRespon:"shoppingCart/ShopingCartRespon",
        AllShop:"shop/AllShop",
    }),
    carouselImgs(){
      const _Detail = [
        {
          path: require("../assets/images/branner1.jpeg"),
          welcom: this.$i18n.t('HomePage.CarouselCentent.Welcome'),
          page1article1: this.$i18n.t('HomePage.CarouselCentent.page1article1'),
          page1article2: this.$i18n.t('HomePage.CarouselCentent.page1article2'),
          view_shop: false
        },
        {
          path: require("../assets/images/branner2.jpeg"),
          page2article1:this.$i18n.t('HomePage.CarouselCentent.page2article1'),
          view_shop: true
        }
      ];
      return _Detail;
    }
  },

 

  methods: {
    ...mapActions("product",["SetSelectedProduct"]),
    ...mapActions("generalInfo",["SetTopCategory"]),
    ...mapActions("shoppingCart",["AddToShoppingCart"]),
    ShowType(id, index){
      this.submenuIndex = index;
      this.SubMenuList =[];
      this.SubMenuList= this.SubMenu.filter((type) => type.category_id == id);
    },
    NavigateToPage(router){
      this.$router.push({path: router});
    },
    NavigateToProductType(router, group_id, type_id){
      const data = {group: group_id, type: type_id};
      this.SetSelectedProduct(data);
      this.$router.push({path: router});
    },
    ProductDetail(router){
      this.$router.push({path:router});
    },

    AddToCard(data){
       this.AddToCardDialog = true;
      this.AddToShoppingCart(data);
    },

    NavigateToMyShop(url, id) {
        const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
        this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
    },

    NavigateToProductDetail(url, id){
      const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
        this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
    },

    NavigateToEcommerce(uri){
      window.open(uri);
    }
  },
};
</script>

<style scoped>

.my-shop:hover {
  box-shadow: 1px 1px 4px 1px #515252 !important;
  transition: 0.4s ease-in-out;
}

.link-background{
  position: absolute;
  margin-top: 6px;
  height: 30px;
  width: 180px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.link-tab{
  position: relative;
  width: 100px;
  cursor: pointer;
}


.link-circle{
  position: absolute;
  box-shadow: 0px 0px 2px 0px black;
  width: 40px;
  height: 40px;
  z-index: 9;
}

.home-content{
  background-color:#f5f5f5;
  height:auto;
  margin-top: 12px;
  padding-top: 10px;
}

.carousel1{
  width: 600px;
  min-height: auto;
  padding: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 5;
  word-wrap: break-word;
}
.page1-text-content{
  padding: 5px;
  position: relative;
  word-wrap: break-word;
}
.page2-text-content{
  width: 600px;
  min-height: auto;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  word-wrap: break-word;
}

.description-img{
      display : block;
      margin : auto;
  }
@media screen and (min-width: 1280px) {
  
    .title-description{
      margin-top: 10%;
      margin-left: 60px;
      margin-right: 30px;
    }
    .description-img{
      width: 400px !important; 
    }
    .carousel1{
      width: 520px;
      top: 50%;
      left: 28%;
      word-wrap: break-word;
    }
  }
@media screen and (max-width: 1279px) {
  
    .title-description{
      margin-top: 10%;
      margin-left: 60px;
      margin-right: 30px;
    }
    .description-img{
      width: 400px !important; 
    }
    .carousel1{
      width: 420px;
      top: 50%;
      left: 28%;
      word-wrap: break-word;
    }
  }
@media screen and (max-width: 959px) {
  
    .title-description{
      margin-top: 10%;
      margin-left: 60px;
      margin-right: 30px;
    }
    .description-img{
      width: 400px !important; 
    }
    .carousel1{
      width: 450px;
      top: 50%;
      left: 30%;
      word-wrap: break-word;
    }
  }

@media screen and (max-width: 800px) {
    .title-description{
      margin-top: 5%;
      margin-left: 60px;
      margin-right: 30px;
    }
    .description-img{
      width: 200px !important; 
    }

    .carousel1{
      width: 380px;
      top: 40%;
      left: 30%;
    }
  }

@media screen and (max-width: 699px) {
    .title-description{
      margin-top: 5%;
      margin-left: 20px;
      margin-right: 10px;
    }
    .description-img{
      width: 40% !important; 
    }

    .carousel1{
      width: 500px;
      top: 40%;
      left: 50%;
    }
}
@media screen and (max-width: 600px) {
   .carousel1{
      width: 380px;
      top: 50%;
      left: 45%;
    }
}
@media screen and (max-width: 500px) {
   .carousel1{
      width: 400px;
      top: 50%;
      left: 50%;
    }
}
@media screen and (max-width: 450px) {
   .carousel1{
      width: 350px;
      top: 50%;
      left: 45%;
    }
}
@media screen and (max-width: 400px) {
   .carousel1{
      width: 330px;
      top: 50%;
      left: 50%;
    }
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.toproduct_all{
  color: darkblue;
}
.toproduct_all:hover{
  color: red;
  cursor: pointer;
}

.section{
  background-color: white;
  padding: 15px;
  box-shadow: 0px 0px 1px grey;
}
.section2{
  background-color: white;
  box-shadow: 0px 0px 1px grey;
  margin-top: 10px;
  padding: 20px;
}
.product-Card{
  border-left: gray 1px solid !important;
  border-bottom: gray 1px solid !important;
  height: 100%;
  /* box-shadow: 1px 1ox 2px grey; */
  /* border-style: ridge; */
}
.product-Card:hover{
  box-shadow: 0px 1px 5px 0px grey !important;
}
.my_col {
    width: 100%;
    padding: 0px;
}

#side_menu{
  list-style: none;
  width: 280px;
  height: 500px;
  float: right;
  text-align: left;
  padding: 10px;
  background-color: rgb(238, 237, 236);
  /* box-shadow: 0px 1px 1px rgb(180, 179, 179); */
}
ul#menu> div >li:hover{
  color: red;
  cursor: pointer;
}

ul > div >li {
  list-style: none;
  width: 100%;
  padding: 5px;
  border-bottom: gray 1px solid;
}
ul>li {
  list-style: none;
  width: 100%;
  padding: 5px;
  border-bottom: gray 1px solid;
}
ul#menu > div >li:hover > #submenu {
  display: block;
}

#submenu{
 display: none;
 z-index: 2;
 position: absolute;
 margin-left: 180px;
 top: 0;
 width: 200px;
 height: 500px;
 text-align: left;
 padding: 0px;
 color: black;
 background-color: white;
 box-shadow: -1px 1px 3px rgb(180, 179, 179);

}
ul#submenu>li:hover {
  color: red;
  cursor: pointer;
}


.detail-btn:hover{
  border: #64b5f6 1px solid;
  padding: 5px;
  box-shadow: -1px -1px 2px #64b5f6;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}



</style>
