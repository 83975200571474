import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)
const History = {
  namespaced: true,
  state: {
      HistoryById:[],
      HistoryByDevice:[],
      myPayItem:0
  },
  mutations: {
    SetById(state, data){
        state.HistoryById = data
    },
    SetByDevice(state, data){
        state.HistoryByDevice = data
    },
    SetEmptyToHisById(state, data){
        state.HistoryById = data
    },
    setPayItem(state, data){
      state.myPayItem = data;
    }
  },
  actions: {
    GetHistoryByID(context, token){
        axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/order/history",{
            headers:{
              'Content-Type': 'application/json',
              'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
              'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
              'token7tsu': token
            }
          }).then((res)=>{
            const Respone = res["data"];
            // console.log(Respone);
            if(Respone["success"] == true && Respone["code"] == 200){
                var AllHistory = Respone["data"];
                 var BuyList = [];
                 var Pay_item = 0;
                  if(AllHistory.length > 0){
                    for(let i = 0; i < AllHistory.length; i++) {
                        if(AllHistory[i]["row_status"] == "pay"){
                          Pay_item = Pay_item+1;
                        }
                        if("buy_detail" in AllHistory[i] == true){
                          BuyList = [];
                          BuyList = eval('['+AllHistory[i]["buy_detail"]+']');
                        } else{
                          BuyList = [];
                        }
                        AllHistory[i]["BuyList"] = BuyList;
                    }
                    context.commit("setPayItem", Pay_item);
                    context.commit("SetById", AllHistory);
                  }
              }
        });
      },

      GetHistoryByDevice(context, deviceID){
/*
        axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/order/history",deviceID, {
            headers:{
              'Content-Type': 'application/json',
              'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
              'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
              'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
            }
          }).then((res)=>{
            const Respone = res["data"];
            if(Respone["success"] == true && Respone["code"] == 200){
                var AllHistory = Respone["data"];
                 var BuyList=[];
                if(AllHistory.length > 0){
                  for(let i=0; i < AllHistory.length; i++){
                      if(AllHistory[i]["buy_detail"] ){
                      BuyList = eval("["+ AllHistory[i]["buy_detail"] +"]");
                      }else{
                        BuyList = [];
                      }
                      AllHistory[i]["BuyList"] = BuyList;
                  }
                 context.commit("SetByDevice", AllHistory);
                }
            }
        }); */

      },
      SetEmptyToHisByID(context, data){
        context.commit("SetEmptyToHisById", data)
      }
    },

  getters:{
    HistoryById: state => state.HistoryById,
    HistoryByDevice: state => state.HistoryByDevice,
    MyPaymentItem: state => state.myPayItem
  }
}

export default History;