<template>
    <div class="detail-content">
        <v-container>
            <div class=" section pa-3 mt-3">
                <div v-if="productDetail['category_la']">
                    <span>{{($i18n.locale=='la')?productDetail['category_la']:productDetail['category_en']}}</span>
                    <v-icon>chevron_right</v-icon><span>{{($i18n.locale=='la')?productDetail['type_la']:productDetail['type_en']}}</span>
                    <v-icon>chevron_right</v-icon> <span>{{($i18n.locale=='la')?productDetail['product_la']:productDetail['product_en']}}</span>
                </div>
                <div v-else>
                    <v-sheet
                        color="rgb(241, 241, 241)">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="300"
                            type="article"
                            style="padding-top: 20px"
                        ></v-skeleton-loader>
                    </v-sheet>
                </div>
            </div>
            
            <div class="section">
                <div class="pa-3">
                    <v-row>
                        <v-col cols="12" md="4" lg="4" sm="4" xs="12" v-if="productDetail['imageList']">
                            <div class="image-list"  >
                                <div v-for="(images, j) in productDetail['imageList']" :key="j">
                                    <div class="mb-1 pa-1 image-list-img" @mouseover="ShoSelectImg(j)">
                                    <v-img :src="server_ip +  images" /></div>
                                </div>
                            </div>
                            <div class="image-content">
                                <v-img :src="server_ip + DetailShowImg" style="height: 400px;" @click="LargeimageCard = !LargeimageCard" />
                            </div>

                            <div class="text-center pt-4" v-if="productDetail['video_url']">
                            <h3> {{$i18n.t('ProductDetail.Product_video')}}</h3>
                                <div>
                                    <iframe width="100%" height="205" :src="productDetail['video_url']" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" sm="4" xs="12" v-else>
                            <div class="pa-1 text-center">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>

                        <v-col cols="12" md="8" lg="8" sm="8" xs="12" v-if="productDetail['priceList']">
                            <h3 class="TimeNewRoman-Phetsarath-OT">
                                {{($i18n.locale=='la')?productDetail.product_la:productDetail.product_en}}
                            </h3>
                            <hr />
                            <div class="pt-2 mb-5">
                                <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ProductDetail.ProductPrice')}}</h3>
                                <div class="Product-Price mt-2" v-for="(pprice, i) in productDetail['priceList']" :key="i">
                                    <div>
                                        <span class="TimeNewRoman-Phetsarath-OT" v-if="parseInt(pprice.max_qty) >= 100000"> 
                                            <b>{{$i18n.t('ProductDetail.Order')}}:</b> 
                                            <span class="orange--text">
                                                {{pprice.min_qty}}
                                            </span>
                                            <span>
                                                {{($i18n.locale=='la')?pprice.unit_la:pprice.unit_en}} 
                                            </span> 
                                            <span class="orange--text">
                                                {{$i18n.t('ProductDetail.MaxAndUpProduct')}}
                                            </span>
                                        </span>
                                        <span class="TimeNewRoman-Phetsarath-OT" v-else>
                                            <b>{{$i18n.t('ProductDetail.Order')}}:</b> 
                                            <span class="orange--text"> {{pprice.min_qty}} - {{pprice.max_qty}} </span> {{($i18n.locale=='la')?pprice.unit_la:pprice.unit_en}} 
                                        </span>
                                    </div>
                                    <div v-if="parseInt(pprice.advert_price) > parseInt(pprice.price)">
                                        <span class="TimeNewRoman-Phetsarath-OT"> {{$i18n.t('ProductDetail.Adverst_Price')}}: </span>
                                        <del><span class="red--text ">${{pprice.advert_price}}</span></del> /  {{($i18n.locale=='la')?pprice.unit_la:pprice.unit_en}}
                                    </div>
                                    <div>
                                        <span class="TimeNewRoman-Phetsarath-OT" v-if="parseInt(pprice.advert_price) > parseInt(pprice.price)">{{$i18n.t('ProductDetail.Sale_Price')}}: </span> 
                                        <span class="TimeNewRoman-Phetsarath-OT" v-else>{{$i18n.t('ProductDetail.Only_Sale_Price')}}: </span> 
                                        <span class="green--text">${{pprice.price}}</span> /  {{($i18n.locale=='la')?pprice.unit_la:pprice.unit_en}}
                                        <div class="rounded-2 pl-3">
                                        <span class="blue--text"> {{pprice.detail}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="mt-5">
                                <v-form ref="form" v-model="isFormValid">
                                    <v-row dense>
                                        <v-col cols="12" md="2" lg="2" sm="12" xs="12" class="text-lg-center text-md-center text-sm-left text-xs-left">
                                            <div style="margin-top: 10px">
                                                <b class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ProductDetail.Quantity')}}</b>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="10" lg="10" sm="12" xs="12">
                                            <v-text-field 
                                                dense 
                                                v-model="FormData.quantity" 
                                                required
                                                :rules="QtyRules_la"
                                                id="centered-input"
                                                :color="quantity_input_color" 
                                                @mouseup="ChangeColor()" 
                                                onkeypress="if(isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;"
                                                >
                                                <template v-slot:prepend-inner>
                                                    <v-btn color="error" class="rounded-0" width="50px" height="40px" @click="QuantityDown()" >
                                                        <v-icon>remove</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:append>
                                                    <v-autocomplete
                                                        v-model="FormData.unitNo"
                                                        required
                                                        :rules="Unit_rules"
                                                        :items="productDetail['unitList']" 
                                                        :item-text="($i18n.locale=='la')?'unit_la':'unit_en'"
                                                        item-value="unit_no" 
                                                        outlined
                                                        dense  
                                                        class="rounded-0 product-unit-box"
                                                        >
                                                    </v-autocomplete>                                                    
                                                    <v-btn color="primary" class="rounded-0 pt-0" width="50px" height="40px" @click="QuantityUp()">
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <div class="mt-2 pa-0">
                                        <v-row dense>
                                            <v-col md="6" sm="6" xs="6">
                                                <v-btn 
                                                    color="success" 
                                                    class="rounded-0" 
                                                    width="100%" 
                                                    small 
                                                    :disabled="!isFormValid"
                                                    :loading="AddCartProgress"
                                                    @click="AddToCart(
                                                        {
                                                            category_id:productDetail['category_id'], 
                                                            type_id:productDetail['ptype_id'], 
                                                            product_la:productDetail['product_la'],
                                                            product_en:productDetail['product_en'],

                                                            productId:productDetail['product_id'],
                                                            unitNo:FormData.unitNo,
                                                            priceNo:FormData.priceNo,
                                                            price:FormData.price,
                                                            quantity:FormData.quantity, 

                                                            imageList:productDetail['imageList'],
                                                            unitList:productDetail['unitList'],
                                                            priceList:productDetail['priceList'],
                                                        })" 
                                                    >
                                                        <v-icon left>add_shopping_cart</v-icon>
                                                        <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ProductDetail.AddToCart')}}</h3>
                                                </v-btn>
                                            </v-col>
                                            <v-col  md="6" sm="6" xs="6">
                                                <v-btn 
                                                    color="warning" 
                                                    class="rounded-0" 
                                                    width="100%" 
                                                    small 
                                                    :loading="progress1Loading"
                                                    @click="CheckOut('/order/cart',
                                                        {
                                                            category_id:productDetail['category_id'], 
                                                            type_id:productDetail['ptype_id'], 
                                                            product_la:productDetail['product_la'],
                                                            product_en:productDetail['product_en'],

                                                            productId:productDetail['product_id'],
                                                            unitNo:FormData.unitNo,
                                                            priceNo:FormData.priceNo,
                                                            price:FormData.price,
                                                            quantity:FormData.quantity, 

                                                            imageList:productDetail['imageList'],
                                                            unitList:productDetail['unitList'],
                                                            priceList:productDetail['priceList'],
                                                        })"
                                                >
                                                    <h3>
                                                        <v-icon left>local_grocery_store</v-icon>
                                                        {{$i18n.t('ProductDetail.NavigateTocart_btn')}}
                                                    </h3>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pt-5">
                                        <span class="contact-supplier orange--text" @click="navigateToSupplier(productDetail.shop_no)"><v-icon class="orange--text" left>email</v-icon>{{$i18n.t('ProductDetail.Contact_Supplier')}}</span>
                                    </div>
                                </v-form>
                            </div>
                        </v-col>

                        <v-col cols="12" md="8" lg="8" sm="8" xs="12" v-else>
                            <div class="text-center">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div class="section">
                <div class="pa-3" v-if="productDetail.detail_la">
                    <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ProductDetail.advert_navigateText')}}</h3>
                    <div style="word-wrap: break-word;">
                    <p class="ml-7 TimeNewRoman-Phetsarath-OT" v-html="($i18n.locale == 'la')?productDetail.detail_la:productDetail.detail_en"></p>
                    </div>
                </div>
                <div v-else>
                    <div class="pa-2">
                        <v-sheet
                            color="rgb(241, 241, 241)">
                            <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="article"
                                style="padding-top: 20px"
                            ></v-skeleton-loader>
                        </v-sheet>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="pa-3"  v-if="MyAdvertProduct.length > 0">
                    <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('ProductDetail.TheSameStore')}}</h3>
                    <div class="product-thesame-store">
                        <v-row class="pa-3">
                            <v-col cols="12" md="2" lg="2" sm="4" xs="6" v-for="(item, key) in MyAdvertProduct" :key="key" class="my_col">
                                <v-card class="rounded-0 product-card">
                                    <v-hover v-slot:default="{ hover }">
                                        <div :elevation="hover ? 6 : 2">
                                        <div class="pl-2 pr-2">
                                            <v-img :aspect-ratio="16/9"  :src="server_ip + item['imageList'][0]" ></v-img>
                                        </div>
                                        <v-card-text>
                                            <div class="mb-2"><b>{{($i18n.locale=='la')?item["product_la"]:item["product_en"]}}</b></div>
                                            <div class="mb-1 red--text" v-if="parseInt(item['priceList'][0]['advert_price']) > parseInt(item['priceList'][0]['price'])">
                                                <del>
                                                    ${{item['priceList'][0]['advert_price']}}
                                                </del>
                                            </div>
                                            <div class="green--text">${{item['priceList'][0]['price']}}</div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn icon color="primary" @click="AddToCart(
                                                {
                                                category_id: item['category_id'], 
                                                type_id: item['ptype_id'], 
                                                product_la: item['product_la'],
                                                product_en: item['product_en'],

                                                productId: item['product_id'],
                                                unitNo: item['priceList'][0]['unit_no'],
                                                priceNo: FormData.priceNo,
                                                price: FormData.price,
                                                quantity: item['priceList'][0]['min_qty'], 

                                                imageList: item['imageList'],
                                                unitList: item['unitList'],
                                                priceList: item['priceList'],
                                                })">
                                                    <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                            </v-btn>

                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                @click="ProductDetail('/product/detail/' , item.product_id)" 
                                                text
                                                color="primary"
                                                >
                                                {{$i18n.t('HomePage.Detail')}}
                                            </v-btn>
                                        </v-card-actions>
                                        </div>
                                    </v-hover>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div v-else>
                    <div class="pa-1 text-center">
                        <v-sheet
                            color="rgb(241, 241, 241)">
                            <v-skeleton-loader
                                class="mx-auto"
                                max-width="300"
                                type="card"
                            ></v-skeleton-loader>
                        </v-sheet>
                    </div>
                </div>
            </div>
           
           <!-- Large image Dialog -->
            <v-dialog :width="$isMobile()?'90%':'60%'" v-model="LargeimageCard" >
                <v-card class="rounded-0">
                    <v-card-text>
                        <div class="close-image-icon">
                            <v-icon color="red" @click="LargeimageCard = !LargeimageCard">close</v-icon>
                        </div>
                        <div>
                            <v-img :src="server_ip + DetailShowImg" width="100%"></v-img>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

           <!-- Add product Dialog -->
            
            <v-dialog width="300" v-model="AddToCartDialog">
                <v-card>
                    <v-card-title style="background-color: #1396ab">
                        {{$i18n.t('AddCardDialog.CardTitle')}}
                        <v-spacer></v-spacer>
                        <v-icon color="white"  @click="AddToCartDialog = !AddToCartDialog" left>mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <div class="mt-4">
                            <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                            <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                            <div class="text-center mt-10 pb-0">
                                <hr>
                                <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCartDialog = !AddToCartDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                <hr>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            
            <!-- NoCardItemDialog Dialog -->
            <v-dialog width="300" v-model="NoCardItemDialog">
                <v-card>
                <v-card-title style="background-color: orange;">
                    <v-spacer></v-spacer>
                    <v-icon color="white"  @click="NoCardItemDialog = !NoCardItemDialog" left>mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <div class="mt-4">
                            <div class="text-center mb-4"><v-icon color="warning" style="font-size: 50px">warning</v-icon></div>
                            <div class="text-center mb-4 red--text "><h2>{{$i18n.t('EmptyCardItemDialog.CardText')}}</h2></div>
                            <div class="text-center pa-5">
                                <v-btn color="red" small outlined width="100px" @click="NoCardItemDialog = !NoCardItemDialog">{{$i18n.t('EmptyCardItemDialog.ok_btn')}}</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
              </v-dialog>

            <!-- Noti to login Dialog -->
            <v-dialog width="300" v-model="UserLogin_Noti">
                <v-card>
                <v-card-title style="background-color: orange;">
                    <v-spacer></v-spacer>
                    <v-icon color="white"  @click="UserLogin_Noti = !UserLogin_Noti" left>mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <div class="mt-4">
                            <div class="text-center mb-4"><v-icon color="warning" style="font-size: 50px">warning</v-icon></div>
                            <div class="text-center mb-4 red--text">{{$i18n.t('LoginNotificationDialog.noti_text')}}</div>
                            <div class="text-center pa-5">
                                <!-- <v-btn
                                    :loading="loading5"
                                    :disabled="loading5"
                                    small outlined width="100px"
                                    color="red"
                                    @click="NavigateToPage('/user')"
                                    >
                                    {{$i18n.t('EmptyCardItemDialog.ok_btn')}}
                                </v-btn> -->
                                <v-btn
                                    :loading="progress2Loading"
                                    :disabled="loading5"
                                    small outlined width="100px"
                                    color="red"
                                    @click="NavigateToPage('/user')"
                                    >
                                    {{$i18n.t('EmptyCardItemDialog.ok_btn')}}
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
export default {
    data() {
        return {
            PRODetailId:'',
            isFormValid: false,
            DetailShowImg: "",
            quantity:1,
            ProductDetailID:'',
            quantity_input_color:'primary',
            ADDCARDDIALOG:false,
            NoCardItemDialog:false,
            UserLogin_Noti:false,
            progress1Loading:false,
            progress2Loading:false,

            QtyRules_la:[v => !!v || 'ຈຳນວນຕ້ອງໃຫຍ່ກວ່າ ຫຼື ເທົ່າກັບ 1'],
            Unit_rules:[  v => !!v || 'ຫົວໜ່ວຍຫ້າມຫວ່າງ'],
            productDetail:{},
            DetailAdvertList:[],
            server_ip: server['_7tsuAPI'],
            decrypt_key: server['cryptoJSkey'],

            AddToCartDialog: false,
            ShoppingCartResponText: this.$i18n.t("ShopingCardText"),
            loader: null,
            loading5: false,
            AddCartProgress: false,

            LargeimageCard:false,
            FormData:{
                productId:'',
                unitNo:'',
                priceNo:'',
                price:0,
                quantity:1
            },
        }
    },

    computed:{
        ...mapGetters({
            TotalProduct_count:"mixproduct/TotalProduct_count",
            AdvertProduct:"mixproduct/AdvertProduct",
            BestSellerProduct:"mixproduct/bestSellerProduct",
            LatestSellerProduct:"mixproduct/latestSellerProduct",
            AllProduct:"product/AllProduct",
            ShoppingCart:"shoppingCart/ShoppingCart",
            Token: 'generalInfo/Token',
            CustomerProfile: 'generalInfo/CustomerProfile',
        }),
        MyAdvertProduct(){
            if(this.DetailAdvertList.length > 0){
                this.DetailAdvertList = [];
            }
                const _LatestSellerProduct =  this.LatestSellerProduct.filter((obj)=>obj.category_id === this.productDetail.category_id)
                _LatestSellerProduct.forEach(element => {
                    this.DetailAdvertList.push(element);
                });
                if(this.DetailAdvertList.length < 12){
                    var latestProduct = this.BestSellerProduct.filter((obj)=>obj.category_id === this.productDetail.category_id);
                    if(latestProduct.length > 0){
                        latestProduct.forEach(element => {
                            if(this.DetailAdvertList.filter((obj)=>obj.product_id === element.product_id).length == 0){
                                this.DetailAdvertList.push(element)
                            }
                        });
                        if(this.DetailAdvertList.length < 12){
                            var advert_allproduct = this.AdvertProduct.filter((obj)=>obj.category_id === this.productDetail.category_id);
                            if(advert_allproduct.length > 0){
                                advert_allproduct.forEach(element => {
                                    if(this.DetailAdvertList.filter((obj)=>obj.product_id === element.product_id).length == 0){
                                    this.DetailAdvertList.push(element)
                                    }
                                });
                                
                                if(this.DetailAdvertList.length < 12){
                                    if(this.AllProduct.length > 0){
                                        var allproduct = this.AllProduct.filter((obj)=>obj.category_id == this.productDetail.category_id);
                                        if(allproduct.length > 0){
                                            allproduct.forEach(element => {
                                                if(this.DetailAdvertList.filter((obj)=>obj.product_id === element.product_id).length == 0){
                                                    this.DetailAdvertList.push(element)
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                    
            return  this.DetailAdvertList
        },
    },

    created () {
        window.scrollTo(0,0);
        this.SetTopCategory(true);
        var encryptedId = this.$route.params.productId;
        var bytes = CryptoJS.AES.decrypt(encryptedId, this.decrypt_key);
          var decryptId = bytes.toString(CryptoJS.enc.Utf8);
          if(!decryptId) {
              this.$router.back();
              return;
          }
        this.ProductDetailID = decryptId;
        this.FormData.product_id = decryptId;
        this.GetProductDetail(this.ProductDetailID).then((res)=>{
              const Respon = res['data'];
                var Detail = Respon['data'];
              if(Respon['success'] && (Respon['code'] == 200) && Detail['product_id']) {
                var priceList = [];
                var unitList = [];
                var imageList = [];
                    priceList = eval('['+Detail['price_detail']+']');
                    unitList  = eval('['+Detail['unit_detail']+']');
                    imageList  = JSON.parse(Detail['product_img']);
                    Detail['priceList'] = priceList;
                    Detail['unitList'] = unitList;
                    Detail['imageList'] = imageList;
                    if(Detail['unitList'].length){
                        this.FormData.unitNo = Detail['unitList'][0]['unit_no'];
                    }
                    priceList.forEach(priceItem => {
                        if(priceItem["unit_no"] == this.FormData.unitNo){
                            if(priceItem["min_qty"] >= this.FormData.quantity && this.FormData.quantity <= priceItem["max_qty"]){
                                this.FormData.priceNo = priceItem["price_id"];
                                this.FormData.price = priceItem["price"];
                            }
                        } 
                    });
                    this.DetailShowImg = imageList[0];
                    this.productDetail = Detail;
              } else {
                  this.$router.back();
              }
        })
    
    },

    methods: {
        ...mapActions('productdetail',['GetProductDetail']),
        ...mapActions('product',['GetAllProduct']),
        ...mapActions("shoppingCart",["AddToShoppingCart"]),
        ...mapActions("generalInfo",["SetTopCategory","DoLoginStatus"]),
        ...mapActions("directcheckOut",["AddToDirectCart"]),
        ShoSelectImg(j) {
            this.DetailShowImg = this.productDetail['imageList'][j];
        },
        QuantityDown(){
            this.quantity_input_color = 'error';
             if(this.FormData.quantity == ""){
                 this.FormData.quantity = 1
             }
             if(this.FormData.quantity > 1){
                this.FormData.quantity = parseInt(this.FormData.quantity) - 1;
            }
        },
        QuantityUp(){
            this.quantity_input_color = 'primary';
            if(this.FormData.quantity == ""){
                this.FormData.quantity = 1
            }else{
            this.FormData.quantity = parseInt(this.FormData.quantity) + 1;
            }
        },
        ChangeColor(){
            this.quantity_input_color = 'primary';
        },
        NavigateToPage(router){
            this.progress2Loading = true;
            setTimeout(() => {
               // this.DoLoginStatus("CustomerAddedToCart");
               this.progress2Loading = false;
                this.$router.push({path: router})
            }, 600);
        },
        ProductDetail(url, id){
             const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
             this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
            
            this.ProductDetailID = id;
            this.GetProductDetail(this.ProductDetailID).then((res)=>{
            const Respon = res['data'];
            if(Respon['success'] && Respon['code'] == 200){
                var Detail = Respon['data'];
                var priceList = [];
                var unitList = [];
                var imageList = [];
                    priceList = eval('['+Detail['price_detail']+']');
                    unitList  = eval('['+Detail['unit_detail']+']');
                    imageList  = JSON.parse(Detail['product_img']);
                    Detail['priceList'] = priceList;
                    Detail['unitList'] = unitList;
                    Detail['imageList'] = imageList;
                    if(Detail['unitList'].length){
                        this.FormData.unitNo = Detail['unitList'][0]['unit_no'];
                    }
                    // console.log(imageList[0]);
                    this.DetailShowImg = imageList[0];
                    this.productDetail = Detail;
                }
            });

            setTimeout(() => {
                window.scrollTo(0,0);
            }, 1000);
        },

        AddToCart(data){
            this.AddCartProgress = true;
            setTimeout(() => {
                this.DoAddCart(data);
                this.AddCartProgress = false;
                this.AddToCartDialog = true;
            }, 1000);
        },

        DoAddCart(data){
            this.productDetail["priceList"].forEach(priceItem => {
                if(priceItem["unit_no"] == this.FormData.unitNo){
                    if(priceItem["min_qty"] <= this.FormData.quantity && this.FormData.quantity <= priceItem["max_qty"]){
                        this.FormData.priceNo = priceItem["price_id"];
                        this.FormData.price = priceItem["price"];
                    }
                }
            });
            data["priceNo"]= this.FormData.priceNo;
            data["price"] = this.FormData.price;
            this.AddToShoppingCart(data);
        },

        CheckOut(router,data){
            this.progress1Loading = true;
            setTimeout(() => {
                this.progress1Loading = false;
                if(this.ShoppingCart.length > 0){
                    this.DoAddCart(data);
                    this.$router.push({path:router})
                }else{
                    this.AddToDirectCart(data);
                    if(this.CustomerProfile.length){
                            var bytes = CryptoJS.AES.decrypt(this.CustomerProfile,this.decrypt_key);
                            var CusInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                            if(CusInfo["cus_id"]){
                                this.$router.push({path:"/order/process"});
                            }else{
                                this.UserLogin_Noti = true
                            }
                    }else{
                        this.UserLogin_Noti = true
                    }
                }
            }, 800);
        },
        navigateToSupplier(id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:'/shop/' + `${encodeURIComponent(encryptedId)}`});
        }

    },
}
</script>

<style scoped>

    /* Note: The quantity input box css manage on App.vue */

    .detail-content{
        background-color: #f5f5f5;
        padding-bottom: 10px;
        
    }
    .product-detail{
        background-color: white;
        padding: 5px;
    }
    .section{
        background-color: white;
        margin-top: 15px;
    }
    .image-list{
        position: absolute;
        float: left;
        width: 50px;
    }
    .image-list-img{
        border: gray 1px solid;
    }
    .image-list-img:hover{
        box-shadow: 0px 0px 5px rgb(253, 123, 2);
    }

    .image-content{
        margin-left: 50px;
        padding: 0px;
        height: 400px;
        /* background-color: red; */
        border: gray 1px solid;
    }
    .Product-Price{
        border: grey 1px solid;
        border-radius: 5px;
        padding: 5px;
    }

    .my_col {
        width: 100%;
        padding: 2px;
    }
    .similar-product:hover{
        border: red 1px solid;
    }
    
    .v-dialog > .v-card > .v-card__title {
        font-size: 1rem;
        font-weight: 200;
        letter-spacing: 0em;
        padding: 5px !important;
        color: white;
    }
    .add-card-text{
        text-align: center;
    }

    .v-dialog > .v-card > .v-card__text {
    padding: 0px !important;
    }
    .to-detail:hover{
        color: blue;
        cursor: pointer;
    }

    .product-unit-box{
        width: 100px;
        bottom: 0;
    }

@media screen and (min-width: 959px) {
    .add-card-text{
        font-size: 1.8em;
    }
    .product-unit-box{
        margin-top:0.5px
    }
  }

@media screen and (max-width: 958px) {
     .add-card-text{
        font-size: 1.5em;
    }
    .product-unit-box{
        margin-top:0.5px
    }
  }

@media screen and (max-width: 599px) {
     .add-card-text{
        font-size: 1.3em;
    }
    .product-unit-box{
        margin-top:0px
    }
}
.product-card:hover{
       box-shadow: 0px 1px 5px 0px grey !important;
    }

.detail-btn{
        color: #64b5f6;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
        }
    .detail-btn:hover{
        text-decoration: underline;
        }
    .close-image-icon{
        position: absolute;
        right: 0;
        margin-right: 10px;
        z-index: 9;
    }
    .No-Data{
        padding: 20%;
        opacity: 0.2;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    .contact-supplier:hover{
        border-bottom: 3px solid orange;
        cursor: pointer;
    }


</style>