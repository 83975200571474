<template>
    <div class="Allproduct-content" >
        <v-container class="pb-10">
            <div class="section content-side pa-3">
                <div><span>{{$i18n.t("LatestPage.AllLatestText")}}</span><v-icon>chevron_right</v-icon></div>
            </div>
            <div class="section content-side ">
                <v-row v-if="AllLatestProduct.length > 0">
                    <v-col cols="12" md="3" lg="2" sm="4"  v-for="(latestProduct, key) in AllLatestProduct" :key="key">
                        <v-card class="rounded-0 product-card">
                            <v-hover v-slot:default="{ hover }">
                                <div :elevation="hover ? 6 : 2">
                                <div class="pl-2 pr-2">
                                    <v-img :aspect-ratio="16/9"  :src="server + latestProduct['imageList'][0]" ></v-img>
                                </div>
                                <v-card-text>
                                    <div class="mb-2"><b>{{($i18n.locale=='la')?latestProduct["product_la"]:latestProduct["product_en"]}}</b></div>
                                    <div class="mb-1 red--text" v-if="parseInt(latestProduct['priceList'][0]['advert_price']) > parseInt(latestProduct['priceList'][0]['price'])">
                                        <del>
                                            ${{latestProduct['priceList'][0]['advert_price']}}
                                        </del>
                                    </div>
                                    <div class="mb-1 red--text" v-else>...</div>
                                    <div class="green--text">${{latestProduct['priceList'][0]['price']}}</div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn icon color="primary" @click="AddToCard(
                                        {
                                        category_id:latestProduct['category_id'], 
                                        type_id:latestProduct['ptype_id'], 
                                        product_la:latestProduct['product_la'],
                                        product_en:latestProduct['product_en'],

                                        productId:latestProduct['product_id'],
                                        unitNo:latestProduct['priceList'][0]['unit_no'],
                                        priceNo:latestProduct['priceList'][0]['price_id'],
                                        price:latestProduct['priceList'][0]['price'],
                                        quantity:latestProduct['priceList'][0]['min_qty'], 

                                        imageList:latestProduct['imageList'],
                                        unitList:latestProduct['unitList'],
                                        priceList:latestProduct['priceList'],
                                        })">
                                            <v-icon class="green--text" style="cursor:pointer">add_shopping_cart</v-icon>
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                    <v-btn text @click="NavigateToProductDetail('/product/detail/', latestProduct.product_id)" color="primary">
                                        {{$i18n.t('HomePage.Detail')}}
                                    </v-btn>
                                </v-card-actions>
                                </div>
                            </v-hover>
                        </v-card>
                    </v-col>
                </v-row>
                <div v-else>
                    <v-row v-if="CheckIf_AllLatestProduct_NotGetData">
                        <v-col cols="12" md="3" lg="2" sm="4"  v-for="(item, key) in 6" :key="key">
                            <div class="pa-1">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <div class="text-center">
                            <h1 class="No-Data">No Data</h1>
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- Add product Dialog -->
            <div>
                <v-dialog width="300" v-model="AddToCardDialog">
                    <v-card>
                        <v-card-title style="background-color: #1396ab">
                            {{$i18n.t('AddCardDialog.CardTitle')}}
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="AddToCardDialog = !AddToCardDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="mt-4">
                                <div class="text-center mb-4"><v-icon color="green" style="font-size: 50px">check_circle</v-icon></div>
                                <div class="text-center"><h2>{{$i18n.t('AddCardDialog.AddCardText')}}</h2></div>
                                <div class="text-center mt-10 pb-0">
                                    <hr>
                                    <v-btn color="info" small outlined class="dialog-btn ma-2" width="100px" @click="AddToCardDialog = !AddToCardDialog">{{$i18n.t('AddCardDialog.ok_btn')}}</v-btn>
                                    <hr>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>            
        </v-container>
        <div>
            <Footer />
        </div>
    </div>
</template>
<script>
import { mapActions ,mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
import Footer from'../../views/Footer.vue'
export default {
  components:{
    Footer
  },
    data() {
        return {
            productLimmit:0,
            server: server['_7tsuAPI'],
            AddToCardDialog: false,
            ShoppingCartResponText: this.$i18n.t("ShopingCardText"),
            decrypt_key: server['cryptoJSkey'],
        }
    },

    computed: {
     ...mapGetters({
            AllLatestProduct:"product/AllLatestProduct",
            CheckIf_AllLatestProduct_NotGetData:"product/CheckIf_AllLatestProduct_NotGetData",
        })
    },

    created(){
        window.scrollTo(0,0);
        this.SetTopCategory(true);
        this.GetAllLatestProduct(this.productLimmit);
    },

    methods: {
        ...mapActions("generalInfo",["SetTopCategory"]),
        ...mapActions('product', ["GetAllLatestProduct"]),
        ...mapActions("shoppingCart",["AddToShoppingCart"]),
        ShowType(id, index){
            this.submenuIndex = index;
            this.SubMenuList =[];
            this.SubMenuList= this.Submenu.filter((type) => type.category_id == id);
        },

        NavigateToPage(router){
            this.$router.push({path: router})
        },

        NavigateToProductDetail(url, id){
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
            this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
        },

        AddToCard(data){
            this.AddToShoppingCart(data);
            this.AddToCardDialog = true;
        }
    },
}
</script>

<style scoped>
    .Allproduct-content{
        background-color: #f5f5f5;
    }

    .menuside{
        position: fixed;
        top: 0px;
        width: 200px;
        height: 800px;
        margin-top: 90px;
        padding: 5px;
        z-index:2;
        background-color: #f5f5f5;

    }
     .content-side{
        width: 100%;
        background-color: white;
    } 
    .section{
        background-color: white;
        margin-right: 5px;
        margin-top: 20px;
    }

    .product-card:hover{
        box-shadow: 0px 1px 5px 0px grey !important;
    }
    .No-Data{
        padding: 20%;
        opacity: 0.2;
    }
  
</style>