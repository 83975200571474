<template>
  <div class="all-shop">
    <v-container fluid>
        <div class="pa-5">
          <div style="margin-left: 3px; margin-right: 3px">
            <h2><b>{{$i18n.t('AllShop.title')}}</b></h2>
            <div v-if="AllShop.length > 0">
                <v-row>
                  <v-col cols="12" md="12">
                      <div class="AllShop-search">
                          <v-text-field 
                          v-bind:placeholder="$t('AllShop.Search')" 
                          v-model="searchtext" 
                          class="search-box" 
                          outlined 
                          dense
                          v-on:keyup.enter="SearchAllShop()"
                          >
                              <template v-slot:append>
                                  <v-icon class="cursor-pointer" @click="SearchAllShop()"> search </v-icon>
                              </template>
                          </v-text-field>
                      </div>
                  </v-col>
              </v-row>
              
              <br />
              <div style="margin-left: 2px; margin-right: 2px">
                <v-row>
                  <v-col cols="12" lg="3" md="3" sm="6" v-for="(shop, index) in Shops" :key="index">
                    <v-card class="mx-auto my-shop" max-width="400" @click="NavigateToMyShop('/shop/' ,shop.shop_no)">
                      <v-img class="white--text align-end" height="200px" :src="server_ip + shop['ImageList'][0]"></v-img>
                      <v-card-title class="pt-5 pb-1" style="position: relative;">
                        <span>{{($i18n.locale == 'la')?shop['shop_la']:shop['shop_en']}}</span>
                      </v-card-title>
                      <v-card-text class="pb-5 text--primary">
                          <div>
                            {{($i18n.locale == 'la')?shop['province_la']:shop['province_en']}}, {{($i18n.locale == 'la')?shop['district_la']:shop['district_en']}}, {{shop["village"]}}
                          </div>
                        </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-else class="section">
                    <v-row>
                        <v-col cols="12" md="3" lg="3" sm="6"  v-for="(item, key) in 8" :key="key">
                            <div class="pa-1">
                                <v-sheet
                                    color="rgb(241, 241, 241)">
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-width="300"
                                        type="card"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                </div>

          </div>
        </div>
    </v-container>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import server from '@/config/server.js'
import CryptoJS from 'crypto-js'
export default {
  data: () => ({
    showMap: false,
    searchtext: "",
    server_ip: server['_7tsuAPI'],
    decrypt_key: server['cryptoJSkey'],
    generalInfo: {
      deviceId: "",
      lat: "",
      lng: ""
    },

  }),

  computed: {
    ...mapGetters({
            AllShop:"shop/AllShop",
            ShopDetail:"shop/ShopDetail",
            totalShop:"shop/totalShop",
            AllSearchShop:"shop/AllSearchShop",
        }),
    Shops: function() {
        if(this.searchtext && this.AllSearchShop.length){
            return this.AllSearchShop
            // return this.AllShop.filter((obj) => 
            //   obj.shop_la.match(this.searchtext) ||
            //   obj.shop_en.toLowerCase().match(this.searchtext.toLowerCase())
            // );
        }else{
            return this.AllShop;
        }
        
    }
  },

  created(){
    window.scrollTo(0,0);
    if(this.AllShop.length == 0){
      this.GetAllShop(0);
    }
  },

  methods: {
    ...mapActions("shop", ["GetAllShop", "SearchShop"]),
    NavigateToMyShop(url, id) {
        const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.decrypt_key).toString();
        this.$router.push({path:url + `${encodeURIComponent(encryptedId)}`});
    },
    SearchAllShop(){
      if(this.searchtext){
          var data = {
              searchData: this.searchtext,
              limmit: 0
          };
          this.SearchShop(data);
      }
    }
  }
};
</script>

<style scoped>

.all-shop{
  margin-bottom: 60px;
}

.search-box{
  width: 500px;
  float: right;
  position: relative;
}

.my-shop:hover {
  box-shadow: 1px 1px 4px 1px #515252;
  transition: 0.5s ease-in-out;
}
</style>