import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)

 const Shop = {
  namespaced: true,
  state: {
    AllShop:[],
    AllShopProduct:[],
    ShopDetail:{},
    totalShop:0,
    TotalShopProduct:0,
    AllProductSearch:[],
    AllSearchShop:[],
    TotalSearchShop:0,
    CheckIfNotGetData: false
  },
  mutations: {
    SetAllShop(state, data){
      state.AllShop = data
    },
    SetTotalShop(state, data){
      state.totalShop = data
    },
    SetAllShopProduct(state, data){
      state.AllShopProduct = data
    },
    SetTotalShopProduct(state, data){
      state.TotalShopProduct = data
    },
    SetShopDetail(state, data){
      state.ShopDetail = data
    },
    SetSearchShop(state, data){
      state.AllSearchShop = data
    },
    SetProductSearch(state, data){
      state.AllProductSearch = data
    },
    SetTotalSearchShop(state, data){
      state.TotalSearchShop = data
    },
    SetCheckIfNotGetData(state, data){
      state.CheckIfNotGetData = data
    }
    
  },
  actions: {
    GetAllShop(context, limit){
      context.commit('SetAllShop',[]);
      axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/shop/all/"+limit+"/limit", {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        const Respone = res['data'];
        if(Respone['success'] && Respone['code'] == 200){
          var ResoneShop = Respone["data"]["shops"];
          var _TotalShop = Respone["data"]["totalShop"];
          var ImageList = [];
          if(ResoneShop.length > 0){
            for (let i = 0; i < ResoneShop.length; i++) {
                ImageList = JSON.parse(ResoneShop[i]["shop_img"]);
                ResoneShop[i]["ImageList"] = ImageList;
            }
            context.commit('SetAllShop',ResoneShop);
            context.commit('SetTotalShop',_TotalShop);
          }else{
            context.commit('SetCheckIfNotGetData',true);
          }
        }
      })
    },

    GetAllShopProduct(context, data){
      axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/by/shop/"+ data.shopId +"/"+ data.limmit +"/limit", {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        console.log(res);
        const Respone = res['data'];
        if(Respone['success'] && Respone['code'] == 200){
          var ResoneShop = Respone["data"]["products"];
          var _TotalShop = Respone["data"]["totalProduct"];
          var priceList=[];
          var unitList=[];
          var imageList=[];
          if(ResoneShop.length > 0){
            for(let i=0; i < ResoneShop.length; i++){
              priceList = eval("["+ ResoneShop[i]["price_detail"] +"]");
              unitList = eval("["+ ResoneShop[i]["unit_detail"] +"]");
              imageList = JSON.parse( ResoneShop[i]["product_img"]);
              ResoneShop[i]["priceList"] = priceList;
              ResoneShop[i]["unitList"] = unitList;
              ResoneShop[i]["imageList"] = imageList;
            }
          }
            context.commit('SetAllShopProduct',ResoneShop);
            context.commit('SetTotalShopProduct',_TotalShop);
        }
      })
    },
    GetNewShopProduct(context, data){
      return  axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/by/shop/"+ data.shopId +"/"+ data.limmit +"/limit", {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      })
    },

    MyShopDetail(context, shop_id){
      axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/shop/detail/" + shop_id, {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        const Respone = res['data'];
        if(Respone['success'] && Respone['code'] == 200 && Respone["data"]["shop_no"]){
            var ImageList = [];
            var Detail = Respone["data"];
                ImageList = JSON.parse(Detail["shop_img"]);
                Detail["ImageList"] = ImageList;
                context.commit('SetShopDetail',Respone["data"]);
        }
      })
    },
    DoSearchShopProduct(context, data){
      axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/product/search/by/shop/"+data.shopId+"/"+data.limmit+"/limit", {searchData: data.searchData}, {
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        const Respone = res['data'];
        if(Respone['success'] && Respone['code'] == 200){
          var AllSearchProduct = Respone["data"]["search"];
          var priceList=[];
          var unitList=[];
          var imageList=[];
          if(AllSearchProduct.length > 0){
            for(let i=0; i < AllSearchProduct.length; i++){
              priceList = eval("["+ AllSearchProduct[i]["price_detail"] +"]");
              unitList = eval("["+ AllSearchProduct[i]["unit_detail"] +"]");
              imageList = JSON.parse( AllSearchProduct[i]["product_img"]);
              AllSearchProduct[i]["priceList"] = priceList;
              AllSearchProduct[i]["unitList"] = unitList;
              AllSearchProduct[i]["imageList"] = imageList;
            }
          }
          context.commit('SetProductSearch',AllSearchProduct);
        }
      })
    },

    SearchShop(context, data){
      axios.post( server['_7tsuAPI'] + "/api/7tsu/customer/shop/search/"+data.limmit+"/limit",{searchData: data.searchData},{
        headers:{
          'Content-Type': 'application/json',
          'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
          'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
          'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
        }
      }).then((res)=>{
        const Respone = res['data'];
        if(Respone['success'] && Respone['code'] == 200){
          var ResoneShop = Respone["data"]["shops"];
          var _TotalShop = Respone["data"]["totalShop"];
          var ImageList = [];
          if(ResoneShop.length > 0){
            for (let i = 0; i < ResoneShop.length; i++) {
                ImageList = JSON.parse(ResoneShop[i]["shop_img"]);
                ResoneShop[i]["ImageList"] = ImageList;
            }
            context.commit('SetSearchShop',ResoneShop);
            context.commit('SetTotalSearchShop',_TotalShop);
          }
        }
      })
    }
  },

  getters:{
    AllShop: state => state.AllShop,
    ShopDetail: state => state.ShopDetail,
    totalShop: state => state.totalShop,
    AllShopProduct: state => state.AllShopProduct,
    TotalShopProduct: state => state.TotalShopProduct,
    AllProductSearch: state => state.AllProductSearch,
    AllSearchShop: state => state. AllSearchShop,
    TotalSearchShop: state => state. TotalSearchShop,
    CheckIfNotGetData: state => state. CheckIfNotGetData,
  }
}

export default Shop;
