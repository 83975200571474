<template>
    <div class="user-content">
        <v-container>
            <div class="register-side" id="registerside" v-if="UserSide=='register'">
                <div class="Top-Right-content">
                      <v-img src="@/assets/images/login-text.png" class="login-text"></v-img>
                    <v-btn @click="ChangeToLogin()" id="login_page_btn" class="rounded-0">
                        <v-icon left>lock_outline</v-icon>
                        {{($i18n.t("User.RegisterPage.Login"))}}
                    </v-btn>
                </div>
                <div class="pt-2 pl-2 register-form-content">
                    <div class="RegisterForm">
                        <div class="pa-3">
                            <v-icon style="font-size: 100px; color: #64b5f6">person_add</v-icon>
                        </div>
                        <v-form ref="form_Register" v-model="isFormValid_Register">
                            <v-row dense>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.firstName" :label="$i18n.t('User.RegisterPage.firstName')" outlined dense :rules="($i18n.locale == 'la')?fullnameRule_LA:fullnameRule_EN"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.lastName" :label="$i18n.t('User.RegisterPage.lastName')" outlined dense :rules="($i18n.locale == 'la')?fullnameRule_LA:fullnameRule_EN"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-autocomplete
                                            v-model="RegisterForm.countryCode"
                                            :items="Country?Country:[]" 
                                            required
                                            :rules="($i18n.locale == 'la')?countryCodeRule_LA:countryCodeRule_EN"
                                            :item-text="($i18n.locale=='la')?'country_la':'country_en'"
                                            item-value="country_code" 
                                            outlined
                                            dense  
                                            :label="$i18n.t('User.RegisterPage.Country')"
                                            class="rounded-0 product-unit-box"
                                            >
                                    </v-autocomplete> 
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.city" :label="$i18n.t('User.RegisterPage.City')" outlined dense :rules="($i18n.locale == 'la')?city_LA:city_EN"></v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.address" :label="$i18n.t('User.RegisterPage.Address')" outlined dense :rules="($i18n.locale == 'la')?address_LA:address_EN"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.tel" :label="$i18n.t('User.RegisterPage.Tel')" outlined dense :rules="($i18n.locale == 'la')?telRule_LA:telRule_EN"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-text-field v-model="RegisterForm.email" :label="$i18n.t('User.RegisterPage.Email')" outlined dense :rules="($i18n.locale == 'la')?emailRule_LA:emailRule_EN"></v-text-field>
                                </v-col>
                                
                                 <v-col cols="12" md="12">
                                    <v-text-field 
                                    v-model="RegisterForm.password" 
                                    :label="$i18n.t('User.RegisterPage.Password')" 
                                    outlined 
                                    dense 
                                    :rules="($i18n.locale == 'la')?passwordRule_LA:passwordRule_EN"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-10-1"
                                    counter
                                    @click:append="showPassword = !showPassword"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" v-if="PasswordComePareText != ''">
                                    <div class="red--text">* {{PasswordComePareText}}</div>
                                </v-col>
                                <v-col cols="12" md="12" v-if="PasswordComepareText_match != ''">
                                    <div class="green--text">* {{PasswordComepareText_match}}</div>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                    v-model="RegisterForm.confirmPass" 
                                    :label="$i18n.t('User.RegisterPage.ConfirmPassword')" 
                                    outlined 
                                    dense 
                                    :rules="($i18n.locale == 'la')?ConfirmPasswordRule_LA:ConfirmPasswordRule_EN"
                                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    name="input-10-2"
                                    counter
                                    @click:append="showConfirmPassword = !showConfirmPassword"
                                    @keyup="CheckUserPassword()"
                                    ></v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12">
                                    <div class="border-content">
                                        <v-radio-group v-model="RegisterForm.emailNotify">
                                            <div class="pb-1 pl-1">
                                              <span class="red--text font-weight-bold" style="font-size: 20px">*</span>  {{$i18n.t("User.RegisterPage.EmailNoti")}}
                                            </div>
                                            <v-row no-gutters>
                                                <v-col class="col-6 pl-5">
                                                    <v-radio :label="$i18n.t('User.RegisterPage.enable_noti')" value="y"></v-radio>
                                                </v-col>
                                                <v-col class="col-6">
                                                    <v-radio :label="$i18n.t('User.RegisterPage.disable_noti')" value="n"></v-radio>
                                                </v-col>
                                            </v-row>
                                        </v-radio-group>
                                    </div>
                                </v-col>

                                <v-col cols="12">
                                    <div style="width: 80%; float:left;">
                                        <v-checkbox
                                            @click="Accept7tsuPolicy()"
                                            :label="$i18n.t('User.RegisterPage.policy_agree_text')"
                                            color="red"
                                            value="accept"
                                            hide-details
                                        ></v-checkbox>
                                    </div>
                                    <div class="text-center pt-1" style="width: 20%;  float:left;">
                                        <span style="padding-top: 50px">
                                            (<u class="pl-1 blue--text" style="cursor:pointer;" @click="NavigateToPage('/policy')">{{$i18n.t("User.RegisterPage.policy")}}</u>)
                                        </span>
                                    </div>
                                </v-col>
                                
                                <v-col cols="12" md="12">
                                    <v-row>
                                        <v-col md="6">
                                            <v-btn class="rounded-0" color="warning" width="100%" @click="$router.back()">{{($i18n.t("User.RegisterPage.Cancel"))}}</v-btn>
                                        </v-col>
                                        <v-col md="6">
                                            <v-btn 
                                                class="rounded-0" 
                                                style="background-color: #64b5f6" 
                                                width="100%"  
                                                @click="RegisterDialog = !RegisterDialog" 
                                                :disabled="!ValidateRegister"
                                                :loading="SignUpprogressLoading"
                                                > 
                                                    <v-icon left>person_add</v-icon> 
                                                    {{($i18n.t("User.RegisterPage.SignUp"))}}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                </div>


                <v-dialog :width="$isMobile()?'90%':'30%'" v-model="registerSuccessDialog" >
                    <v-card class="rounded-0" >
                        <v-card-text>
                            <div class="close-dilog-icon pt-2">
                                <v-icon color="red" @click="registerSuccessDialog = !registerSuccessDialog">close</v-icon>
                            </div>

                            <div class="text-center pl-3 pr-3 pt-5 ">
                                <div class="pb-5" >
                                    <v-icon style="color: green; font-size:80px">check_circle</v-icon>
                                </div>
                                <h3 class="green--text">{{registerSuccessText}}</h3>
                                <br>
                                <div>
                                    <div class="text-center">
                                        <v-btn style="background-color: green; color: white; font-size:18px" class="rounded-0 mr-2" @click="LoginPage()" text width="100%"> <v-icon left style="font-size: 18px">lock_outline</v-icon> {{$i18n.t("User.navigate_login")}}</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog :width="$isMobile()?'90%':'30%'" v-model="registerFailedDialog" >
                    <v-card class="rounded-0" style="background-color: red">
                        <v-card-text>
                            <div class="close-dilog-icon">
                                <v-icon color="white" @click="registerFailedDialog = !registerFailedDialog">close</v-icon>
                            </div>
                            <div class="text-center pa-10">
                                <h2 class="white--text">{{registerFailedText}}</h2>
                            </div>
                            <div class="text-center">
                                <div class="pa-2">
                                    <hr>
                                </div>
                                <v-btn color="white" class="rounded-0" @click="registerFailedDialog = !registerFailedDialog">{{$i18n.t("User.Ok_btn")}}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog :width="$isMobile()?'90%':'30%'" v-model="RegisterDialog" >
                    <v-card class="rounded-0" >
                        <v-card-text>
                            <div class="close-dilog-icon pt-2">
                                <v-icon color="red" @click="RegisterDialog = !RegisterDialog">close</v-icon>
                            </div>

                            <div class="text-center pl-3 pr-3 pt-5 pb-10">
                                <div class="pb-5" >
                                    <v-icon style="color: #ffcc00; font-size:100px">help_outline</v-icon>
                                </div>
                                <h2 class="">{{$i18n.t("User.RegisterPage.RegisterDialog_text")}}</h2>
                            </div>
                            <div class="text-center">
                                <v-btn color="white" class="rounded-0 mr-2 warning" @click="RegisterDialog = !RegisterDialog"><v-icon left>close</v-icon> {{$i18n.t("User.RegisterPage.cancel")}}</v-btn>
                                <v-btn color="white" class="rounded-0 ml-2 primary" @click="RegisterUser()" > <v-icon left>check</v-icon> {{$i18n.t("User.RegisterPage.confirm")}}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>

            <div class="login-side" id="LoginSide" v-else>
                <div class="Top-Left-Content">
                    <div class="register-text">
                        <v-img src="@/assets/images/register.png"></v-img>
                    </div>
                    <div class="text-center" id="logout_page_btn" >
                        <v-btn @click="ChangeToRegister()" class="rounded-0">   
                            <v-icon style="font-size: 20px;" left>person_add</v-icon>
                             {{$i18n.t("User.Login.Register")}}
                        </v-btn>
                    </div>
                </div>
                <div class="pt-2 pl-2 login-form-content">
                    <div class="LoginForm">
                        <div class="pa-3 text-center">
                            <v-icon style="font-size: 100px; color: red">lock</v-icon>
                        </div>
                        <div v-if="LoginRespon_la && LoginRespon_en" class="red--text pt-3 pb-3">
                            * {{($i18n.locale == 'la')?LoginRespon_la:LoginRespon_en}}
                        </div>
                        <v-form ref="form_Login" v-model="isFormValid_Login">
                            <v-row dense>
                                <v-col cols="12" md="12">
                                    <v-text-field 
                                        v-model="LoginForm.uname" 
                                        :label="$i18n.t('User.Login.UserName')" 
                                        outlined 
                                        dense
                                        append-icon="alternate_email"
                                        ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field 
                                    v-model="LoginForm.pword" 
                                    :label="$i18n.t('User.Login.Password')" 
                                    outlined 
                                    dense
                                    :append-icon="showLoginPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showLoginPassword ? 'text' : 'password'"
                                    name="input-10-2"
                                    @click:append="showLoginPassword = !showLoginPassword"
                                    ></v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12">
                                    <v-row>
                                        <v-col md="6">
                                            <v-btn class="rounded-0" color="warning" width="100%" @click="$router.back()">
                                            <v-icon left>close</v-icon>
                                                {{$i18n.t("User.Login.Cancel")}}
                                            </v-btn>
                                        </v-col>
                                        <v-col md="6">
                                                <v-btn 
                                                    class="rounded-0" 
                                                    style="background-color: #64b5f6; color: white" 
                                                    width="100%" 
                                                    :disabled="!isFormValid_Login" 
                                                    :loading="LoginprogressLoading"
                                                    @click="DoLogin()"
                                                    >
                                                    <v-icon left>arrow_forward</v-icon>
                                                    {{$i18n.t("User.Login.SignIn")}}
                                                </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                </div>  

                <v-dialog :width="$isMobile()?'90%':'30%'" v-model="LoginSuccessDialog" >
                    <v-card class="rounded-0" >
                        <v-card-text>
                            <div class="close-dilog-icon pt-2">
                                <v-icon color="red" @click="LoginSuccessDialog = !LoginSuccessDialog">close</v-icon>
                            </div>

                            <div class="text-center pl-3 pr-3 pt-5 ">
                                <div class="pb-5" >
                                    <v-icon style="color: green; font-size:80px">check_circle</v-icon>
                                </div>
                                <h3 class="green--text">{{$i18n.t("User.Login.Login_Success")}}</h3>
                                <br>
                                <div>
                                    <div class="text-center">
                                        <v-btn style="background-color: green; color: white; font-size:18px" class="rounded-0 mr-2"  @click="LoginSuccessDialog = !LoginSuccessDialog" text width="100%">{{$i18n.t("User.Ok_btn")}}</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>      
    </v-container>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import server from '@/config/server.js'
import CryptoJS from 'crypto-js'
import tsu7socket from '@/config/socketio.js'
export default {
    data() {
        return {
            PasswordComePareText:"",
            PasswordComepareText_match:"",
            showPassword: false,
            showConfirmPassword: false,
            registerSuccessDialog: false,
            registerFailedDialog: false,
            showLoginPassword: false,
            RegisterDialog:false,
            LoginSuccessDialog:false,
            LoginprogressLoading:false,
            SignUpprogressLoading:false,
            decrypt_key: server['cryptoJSkey'],

            LoginRespon_la:"",
            LoginRespon_en:"",

            registerSuccessText:"",
            registerFailedText:"",
            policy_accept:false,
            RegisterForm : {
                fullname:"",
                firstName:"",
                lastName:"",
                username:"",
                password:"",
                confirmPass:"",
                tel:"",
                email:"",
                countryCode:"",
                address:"",
                city:"",
                emailNotify:"y"

            },
            LoginForm : {
                uname:"",
                pword:""
            },
            
            isFormValid_Register: false,
            isFormValid_Login: false,
            usernameRule_LA:     [v => !!v || 'ຊື່ຜູ້ໃຊ້ຫ້າມວ່າງ'],
            passwordRule_LA:     [
                v => !!v || 'ລະຫັດຜ່ານຫ້າມວ່າງ',
                v => v.length >= 8 || 'ລະຫັດຜ່ານຕ້ອງຫຼາຍກວ່າ 8 ຕົວອັກສອນ',
            ],
            ConfirmPasswordRule_LA:[
                v => !!v || 'ຢືນຢັນລະຫັດຜ່ານຫ້າມວ່າງ',
                v => v.length >= 8 || 'ລະຫັດຢືນຢັນຕ້ອງຫຼາຍກວ່າ 8 ຕົວອັກສອນ',
            ],
            fullnameRule_LA:     [v => !!v || 'ຊື່ ແລະ ນາມສະກຸນຫ້າມວ່າງ'],
            telRule_LA:     [v => !!v || 'ເບີໂທລະສັບຫ້າມວ່າງ'],
            emailRule_LA: [
                    v => !!v || 'ອີເມວຫ້າມວ່າງ',
                    v => /.+@.+\..+/.test(v) || 'ອີເມວບໍ່ຖືກຕ້ອງ',
                ],
            countryCodeRule_LA:     [v => !!v || 'ປະເທດຫ້າມຫວ່າງ'],
            address_LA:     [v => !!v || 'ທີ່ຢູ່ຫ້າມວ່າງ'],
            city_LA:     [v => !!v || 'ຕົວເມືອງຫ້າມວ່າງ'],


             usernameRule_EN:     [v => !!v || 'Username is required'],
             passwordRule_EN:     [
                    v => !!v || 'Password  is required',
                    v => v.length >= 8 || 'Password must morthen 8 characters',
                ],
             ConfirmPasswordRule_EN:     [
                    v => !!v || 'Confirm  is required',
                    v => v.length >= 8 || 'Confirm password must more than 8 characters',
                ],
             fullnameRule_EN:     [v => !!v || 'FullName  is required'],
             telRule_EN:     [v => !!v || 'Tel  is required'],
             emailRule_EN: [
                    v => !!v || 'Email  is required',
                    v => /.+@.+\..+/.test(v) || 'Email is not valid',
                ],
            countryCodeRule_EN:     [v => !!v || 'Country  is required'],
            address_EN:     [v => !!v || 'Address  is required'],
            city_EN:     [v => !!v || 'City  is required'],

        }
    },

    computed: {
     ...mapGetters({
            Country:"generalInfo/Country",
            UserSide:'generalInfo/UserPageSide',
            // NextPageAffterLogin:'generalInfo/thePageAffterLogin'
        }),
        ValidateRegister(){
            return (
                this.isFormValid_Register && this.RegisterForm["emailNotify"] && this.policy_accept
            )
        }
    },

    created(){
        window.scrollTo(0,0);
    },
    methods: {
        ...mapActions("generalInfo",["SetUserPageSide","SetUserProfile", "SetToken"]),
        ...mapActions("user",["Register","UserDoLogin"]),
        ...mapActions("history",["GetHistoryByID"]),
        Accept7tsuPolicy(){
            this.policy_accept = !this.policy_accept;
        },
        ChangeToRegister(){
            this.SetUserPageSide("register");
        },
        ChangeToLogin(){
            this.SetUserPageSide("Login");
        },
        Cancel(router){
            this.$router.push({path: router})
        },
        OpenRegisterDialog(){
            this.RegisterDialog = true;
        },
        RegisterUser(){
            // console.log(this.RegisterForm);
            this.RegisterDialog = false;
            if(this.isFormValid_Register){
                this.PasswordComepareText_match='';
                this.PasswordComepareText='';
                    this.SignUpprogressLoading = true;
                if(this.RegisterForm.password == this.RegisterForm.confirmPass){
                    this.RegisterForm.username = this.RegisterForm.email;
                    this.Register(this.RegisterForm).then((res)=>{
                        if(res != null){
                            const Respone = res["data"];
                            if(Respone["success"] == true && Respone["code"] == 200){
                                this.LoginForm.uname =  this.RegisterForm.email;
                                this.LoginForm.pword =  this.RegisterForm.password;
                                this.EmptyForm();
                                this.LoginProcess();
                                this.registerSuccessText = this.$i18n.t("User.RegisterSuccessTxt");
                                this.SignUpprogressLoading = false;
                                this.registerSuccessDialog = true;
                                             
                            }else{
                                this.registerFailedText = this.$i18n.t("User.RegisterFailedTxt")
                                this.SignUpprogressLoading = false;
                                this.registerFailedDialog = true;
                            }
                        }
                    });
                }else{
                    this.SignUpprogressLoading = false;
                    this.PasswordComepareText_match = '';
                    this.PasswordComePareText = this.$i18n.t("User.PasswordComepareText")
                }
            }
        },

        EmptyForm(){
            this.RegisterForm.fullname = "";
            this.RegisterForm.firstName = "";
            this.RegisterForm.lastName = "";
            this.RegisterForm.username = "";
            this.RegisterForm.password = "";
            this.RegisterForm.confirmPass = "";
            this.RegisterForm.tel = "";
            this.RegisterForm.email = "";
            this.RegisterForm.countryCode = "";
            this.RegisterForm.address = ""; 
        },

        LoginPage(){
            this.registerSuccessDialog = false;
            this.ChangeToLogin();
        },
        DoLogin(){
            if(this.isFormValid_Login){
                this.LoginprogressLoading = true;
                this.LoginProcess();
            }
        },
        LoginProcess(){
            this.UserDoLogin(this.LoginForm).then((res)=>{
                const respone = res["data"];
                if(respone["success"] == true && respone["code"] == 200){
                    const userprofile = {
                        cus_id:respone["data"]["profile"]["cus_id"],
                        cus_name:respone["data"]["profile"]["cus_name"],
                        first_name:respone["data"]["profile"]["first_name"],
                        last_name:respone["data"]["profile"]["last_name"],
                        city:respone["data"]["profile"]["city"],
                        country_code:respone["data"]["profile"]["country_code"],
                        country_en:respone["data"]["profile"]["country_en"],
                        country_la:respone["data"]["profile"]["country_la"],
                        device_id:respone["data"]["profile"]["device_id"],
                        email:respone["data"]["profile"]["email"],
                        tel:respone["data"]["profile"]["tel"],
                        address:respone["data"]["profile"]["address"]
                    }
                    this.SetUserProfile(userprofile);
                    this.SetToken(respone["data"]["token"]);
                    this.GetHistoryByID(respone["data"]["token"]);
                    this.LoginprogressLoading = false;
                    this.LoginSuccessDialog = true;

                    // socket io here

                    // '/7tsu/real/notify/client/listening/' + username
                    tsu7socket.on('/7tsu/real/payment/client/listening/'+ userprofile["email"], 
                        (payResponse) => {
                            // tsu7socket.off();

                            if(payResponse) {
                                console.log(payResponse);
                            } else {
                                console.log("io errer");
                            }
                        })

                    setTimeout(() => {
                        this.LoginSuccessDialog = false;
                        this.$router.back();
                    }, 2000);
                
                /*
                    // ກວດສອບວ່າຈະໄປທີ່ຂະບວນການຊໍາລະ ຫຼື ຈະກັບໜ້າຫຼັກ
                    // ຖ້າ LoginStatus == CustomerAddedToCart (ສ້າງຕອນກົດປຸ່ມສັ່ງຊື້ໃນ productDetail ຫຼື ShoppingCard CheckOut Proccess)
                
                if(this.NextPageAffterLogin){
                        var bytes = CryptoJS.AES.decrypt(this.NextPageAffterLogin, this.decrypt_key);
                        var decrypt = bytes.toString(CryptoJS.enc.Utf8);
                        if(decrypt == 'CustomerAddedToCart'){
                            this.LoginSuccessDialog = true;
                            setTimeout(() => {
                                this.$router.back();
                            }, 2000);
                        }else{
                            this.$router.push('/');
                        }
                }else{
                        this.$router.push('/');
                }*/
                }else{
                    this.LoginprogressLoading = false;
                    this.LoginRespon_la = respone["message"]["la"];
                    this.LoginRespon_en = respone["message"]["en"];

                    /*
                    
                    data:
                        code: 401
                        message:
                        en: "Username or password is incorrect"
                        la: "ຊື່ເຂົ້າໃຊ້ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ"
                        __proto__: Object
                        success: false
                    
                    
                    
                    */ 
                }
            
            });
        },
        CheckUserPassword(){
            if(this.RegisterForm.password != this.RegisterForm.confirmPass){
                 this.PasswordComePareText = this.$i18n.t("User.PasswordComepareText");
                 this.PasswordComepareText_match = '';
            }else{
                this.PasswordComePareText = '';
                this.PasswordComepareText_match = this.$i18n.t("User.PasswordComepareText_match")
            }
        },
        NavigateToPage(route){
            this.$router.push({path:route})
        }
    },
}
</script>

<style scoped>
    .user-content{
        background-color: #f5f5f5;
        padding-bottom: 10px;
        }
    
    .register-side{
        background-color: white;
        position: relative;
        height: 900px;
    }

    /* #registerside{
        background-image: url("../../assets/images/Registerbg.jpg");
        background-size: cover;
    }
    
    #LoginSide{
        background-image: url("../../assets/images/loginbg.jpg");
        background-size: cover;
    } */
    
    .login-side{
        width: 100%;
        background-color: white;
        height: 750px;
    }
    /* .RegisterForm{
            background-color: white;
            padding: 10px;
            border-radius: 5px;
    } 
    .LoginForm{
        background-color: white;
        padding: 10px;
        border-radius: 5px;
    } */
    
    @media screen and (min-width : 700px) {
        .Top-Right-content{
            position: absolute;
            width: 45%;
            height: 500px;
            right: 0;
            z-index: 2;
            border-bottom-left-radius: 100%;
            background-color: #64b5f6;
            transition: ease-in-out 0.3s;            
        }
        
        .Top-Right-content > #login_page_btn{
            position: absolute;
            top: 310px;
            right: 0;
            margin-right:25%;
        }
        .Top-Right-content > .login-text{
            position: absolute;
            top: 100px;
            width:280px;
            right: 0;
            margin-right:10%;
        }
        .RegisterForm{
            width: 50%;
            opacity: 1;
            transition: opacity 0.3s;
        }

        /* Login */

        .Top-Left-Content{
            width: 35%;
            position: absolute;
            height: 500px;
            z-index: 2;
            border-bottom-right-radius: 100%;
            background-color: #64b5f6;
            transition: ease-in-out 0.3s;            
        }
        .login-form-content{
            width: 50%;
            margin-left: 50%;
            padding-right: 2%;
            padding-top: 20% !important;
            transition: ease-in-out 0.3s;
        }
        
        .Top-Left-Content > .register-text{
            position: absolute;
            top: 100px;
            width:300px;
            margin-left: 5%;

        }

        .Top-Left-Content > #logout_page_btn{
            position: absolute;
            top: 250px;
            margin-left: 15%;
            width: 200px;
        }

        
        .RegisterForm{
            width: 50%;
        }

    }
    
    @media screen and (max-width : 699px) {
        .register-form-content{
            padding-top: 150px !important;
            transition: ease-in-out 0.3s;
        }
        .Top-Right-content{
            position: absolute;
            width: 100%;
            height: 200px;
            right: 0;
            border-bottom-left-radius: 100%;
            background-color: #64b5f6;
            transition: ease-in-out 0.3s;
        }
        .Top-Right-content > #login_page_btn{
            position: absolute;
            top: 100px;
            right: 0;
            margin-right:25%;
        }
        .Top-Right-content > .login-text{
            position: absolute;
            top: 10px;
            width:200px;
            right: 0;
            margin-right:10%;
        }
        .RegisterForm{
            width: 100%;
        }

        /* Login */
        .Top-Left-Content{
            position: relative;
            width: 100%;
            height: 200px;
            border-bottom-right-radius: 100%;
            background-color: #64b5f6;
            transition: ease-in-out 0.3s;            
        }
        .login-form-content{
            width: 100%;
            margin-left: 2%;
            padding-right: 2%;
            padding-top: 10% !important;
            transition: ease-in-out 0.3s;
        }
        
        .Top-Left-Content > .register-text{
            position: absolute;
            top: 10px;
            width:280px;
            margin-left: 5%;
        }

        .Top-Left-Content > #logout_page_btn{
            position: absolute;
            top: 130px;
            width: 150px;
            left: 15%;
        }

    }

    .close-dilog-icon{
        position: absolute;
        right: 0;
        margin-right: 10px;
        z-index: 9;
    }
    .border-content{
        border: 1px solid grey;
    }

.v-input--selection-controls {
    margin-top: 0px !important;
}

</style>
