const switchPageModule = {
    namespaced: true,
    state: {
      ShowMainAppbar: true,
      ShowBack_btn: false,
      stepper: "1"
    },
    mutations: {
      SetShowMainAppbar(state, ShowMainAppbar) {
        state.ShowMainAppbar = ShowMainAppbar
      },
      SetShowBackbtn(state, ShowBack_btn) {
        state.ShowBack_btn = ShowBack_btn
      },
      CreateStepper(state, stepper) {
        state.stepper = stepper
      },
    },
    actions: {
      DoShowMainAppbar(context, data) {
        context.commit('SetShowMainAppbar', data);
      },
      DoShowBackbtn(context, data) {
        context.commit('SetShowBackbtn', data);
      },
      CreateStepper(context, data) {
        context.commit('CreateStepper', data);
      },
    },
    getters: {
      ShowMainAppbar: state => state.ShowMainAppbar,
      ShowBack_btn: state => state.ShowBack_btn,
      stepper: state => state.stepper,
    }
  };
  export default switchPageModule;
  