import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)

const MixProduct = {
  namespaced: true,
  state: {
    TotalProduct_Count:[],
    AdvertProduct:[],
    BestSellerProduct:[],
    LatestSellerProduct:[],
    RecommendProduct:[],
  },
  mutations: {
    Set_Mix_AdvertProduct(state, data){
      state.TotalProduct_Count = data["TotalProduct_count"],
      state.AdvertProduct = data["all"],
      state.BestSellerProduct = data["bestSeller"],
      state.LatestSellerProduct = data["latest"],
      state.RecommendProduct = data["recommend"]
    }
  },
  actions: {
    Get_Mix_AdvertProduct(context, idx){
         axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/mix/all/"+idx+"/limit",{
          headers:{
            'Content-Type': 'application/json',
            'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
            'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
            'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
          }
        }).then((res)=>{
            const Respon = res['data'];
            // console.log(Respon);
            if(Respon['success'] && Respon['code'] == 200){
              var AdProduct = Respon['data']['all'];
              var AdBestSellerProduct = Respon['data']['bestSeller'];
              var AdLatestProduct = Respon['data']['latest'];
              var AdRecommendProduct = Respon['data']['recommend'];
              
              // Convert AdProduct price 
              for(let i = 0; i < AdProduct.length; i++){
                Respon['data']['all'][i]["priceList"] = eval('['+AdProduct[i]["price_detail"]+']');
                Respon['data']['all'][i]["unitList"] = eval('['+AdProduct[i]["unit_detail"]+']');
                Respon['data']['all'][i]["imageList"] =  eval(AdProduct[i]["product_img"]);
              }

              // Convert AdBestSellerProduct price 
              for(let i = 0; i < AdBestSellerProduct.length; i++){
                Respon['data']['bestSeller'][i]["priceList"] = eval('['+AdBestSellerProduct[i]["price_detail"]+']');
                Respon['data']['bestSeller'][i]["unitList"] = eval('['+AdBestSellerProduct[i]["unit_detail"]+']');
                Respon['data']['bestSeller'][i]["imageList"] =  eval(AdBestSellerProduct[i]["product_img"]);
              }

              // Convert AdLatestProduct price 
              for(let i = 0; i < AdLatestProduct.length; i++){
                Respon['data']['latest'][i]["priceList"] = eval('['+AdLatestProduct[i]["price_detail"]+']');
                Respon['data']['latest'][i]["unitList"] = eval('['+AdLatestProduct[i]["unit_detail"]+']');
                Respon['data']['latest'][i]["imageList"] = eval(AdLatestProduct[i]["product_img"]);
              }

              // Convert AdRecommendProduct price 
              for(let i = 0; i < AdRecommendProduct.length; i++){
                Respon['data']['recommend'][i]["priceList"] = eval('['+AdRecommendProduct[i]["price_detail"]+']');
                Respon['data']['recommend'][i]["unitList"] = eval('['+AdRecommendProduct[i]["unit_detail"]+']');
                Respon['data']['recommend'][i]["imageList"] = eval(AdRecommendProduct[i]["product_img"]);
              }
              context.commit('Set_Mix_AdvertProduct',Respon['data']);


              // console.log(Respon);
              /*
              var AdProduct = Respon['data']['all'];
              var AdBestSellerProduct = Respon['data']['bestSeller'];
              var AdLatestProduct = Respon['data']['latest'];
              
              // Change AdProduct Product price to array
              for(let listIndex = 0 ; listIndex < AdProduct.length; listIndex++){
                var priceList = [];
                var PPrice = AdProduct[listIndex]['price_detail'].split(/\|/g);
                for (let p of PPrice) {
                var priceDetail = p.split(/;/g);
                  priceList.push({
                    price_id: priceDetail[0],
                    min_qty: priceDetail[1],
                    max_qty: priceDetail[2],
                    unit_no: priceDetail[3],
                    unit_la: priceDetail[4],
                    unit_en: priceDetail[5],
                    price: priceDetail[6],
                    advert_price: priceDetail[7],
                    detail: priceDetail[8],
                    profit_type: priceDetail[9],
                    profit_val: priceDetail[10]
                  })
                }
                // console.log(AdProduct);
                AdProduct[listIndex]['PriceList'] = priceList;
              }

              // Change AdBestSellerProduct price to array
              for(let listIndex = 0 ; listIndex < AdLatestProduct.length; listIndex++){
                var priceList = [];
                var PPrice = AdLatestProduct[listIndex]['price_detail'].split(/\|/g);
                for (let p of PPrice) {
                var priceDetail = p.split(/;/g);
                  priceList.push({
                    price_id: priceDetail[0],
                    min_qty: priceDetail[1],
                    max_qty: priceDetail[2],
                    unit_no: priceDetail[3],
                    unit_la: priceDetail[4],
                    unit_en: priceDetail[5],
                    price: priceDetail[6],
                    advert_price: priceDetail[7],
                    detail: priceDetail[8],
                    profit_type: priceDetail[9],
                    profit_val: priceDetail[10]
                  })
                }
                AdLatestProduct[listIndex]['PriceList'] = priceList;
              }

              // Change AdLatestProduct price to array
              for(let listIndex = 0 ; listIndex < AdBestSellerProduct.length; listIndex++){
                var priceList = [];
                var PPrice = AdBestSellerProduct[listIndex]['price_detail'].split(/\|/g);
                for (let p of PPrice) {
                var priceDetail = p.split(/;/g);
                  priceList.push({
                    price_id:priceDetail[0],
                    min_qty: priceDetail[1],
                    max_qty: priceDetail[2],
                    unit_no: priceDetail[3],
                    unit_la: priceDetail[4],
                    unit_en: priceDetail[5],
                    price:   priceDetail[6],
                    advert_price: priceDetail[7],
                    detail:       priceDetail[8],
                    profit_type:  priceDetail[9],
                    profit_val:   priceDetail[10]
                  })
                }
                AdBestSellerProduct[listIndex]['PriceList'] = priceList;
              }
              context.commit('Set_Mix_AdvertProduct',Respon['data'])
              */
            }
        })
    },
   
   
  },

  getters:{
    TotalProduct_count:state => state.TotalProduct_Count,
    AdvertProduct:state => state.AdvertProduct,
    bestSellerProduct: state => state.BestSellerProduct,
    latestSellerProduct: state => state.LatestSellerProduct,
    RecommendProduct: state => state.RecommendProduct
  }
}

export default MixProduct;
