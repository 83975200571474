<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>

export default {
  name: "App",
  data: () => ({}),
};
</script>

<style>
  /* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* All Product Card */
.product-Card{
    /* border-left: gray 1px solid !important;
    border-bottom: gray 1px solid !important; */
    height: 100%;
  }

/* Add card */
 .addCard{
        color: #64b5f6;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
        }
  
  
   .product-card-action{
        position: absolute;
        bottom: 2px;
        }
    .Detail-navigation{
        color: blue;
        text-align: right;
        margin-left: 100%;
    }
    .Detail-navigation:hover{
        cursor: pointer;
        text-decoration: underline;
    }


/* This is use when user hover product Card then green reveal div  */
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}


/* manage quantity input */

input#centered-input{
  text-align: center;
  font-size: 16;
  font-weight: bold;
}

 .v-text-field.v-text-field--enclosed .v-text-field__details {
        padding-top: 0px; 
        margin-bottom: 0px !important;
    }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0px !important;
    text-align: center;
}

.v-text-field__details {
    display: flex;
    flex: 1 0 auto;
    max-width: 100%;
     min-height: 0px !important; 
    overflow: hidden;
}

.v-messages {
    flex: 1 1 auto;
    font-size: 12px;
    min-height: 0px !important; 
    min-width: 1px; 
    position: relative;
}

   .v-text-field > label{
        color: red;
    }
    .v-text-field#centered-input input {
        text-align: center !important
    }

</style>


