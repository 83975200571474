
<template>
  <v-container fluid>
    <v-card class="history-card">
      <v-card-text>
        <div>
          <div class="text-center">
            <v-avatar color="red" size="50">
              <span class="white--text text-h5 font-weight-black">{{UserInfo["first_name"].charAt(0).toUpperCase()}}</span>
            </v-avatar>
            <br>
            <b>{{UserInfo["cus_name"]}}</b>
            <br>
            <b><v-icon lelft>location_on</v-icon> Country : </b> {{UserInfo["country_en"]}}
            <br>
            <b><v-icon lelft>call</v-icon> Tel: </b> {{UserInfo["tel"]}}  
            <b><v-icon lelft>email</v-icon>  Email : </b> {{UserInfo["email"]}}
          </div>
        </div>
      </v-card-text>
    </v-card>
    <br />
    
    <v-card class="history-card">
      <v-card-text>
        <v-container fluid>
          <div class="content-header">{{$t('History.header_Text')}}</div>
          <v-row justify="center">
            <v-col cols="12">
              <div v-if="myOrderHis.length">
                <v-divider></v-divider>
                <v-data-table :headers="TB_ByDeviceHeader" :items="myOrderHis">
                  <template v-slot:[`item.row_date`]="{ item }">
                    <span>{{getDate(item.row_date)}} {{item.row_time}}</span>
                  </template>
                  <template v-slot:[`item.dhl_date`]="{ item }">
                    <span v-if="item.dhl_date">{{getDate(item.dhl_date)}}</span>
                    <span v-else>...</span>
                  </template>
                  <template v-slot:[`item.approve_date`]="{ item }">
                    <span v-if="item.approve_date">{{getDate(item.approve_date)}} {{item.approve_time}}</span>
                    <span v-else>...</span>
                  </template>
                  <template v-slot:[`item.row_status`]="{ item }">
                    <v-tooltip bottom  v-if="item.row_status == 'p'" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning"  v-bind="attrs" v-on="on">sync</v-icon>
                      </template>
                      <span>{{$i18n.t('History.processing_tooltip')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom  v-else-if="item.row_status == 'pay'" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" @click=" NavigateToPayment('/order/payment', item.buy_no) "  v-bind="attrs" v-on="on" >payment</v-icon>
                      </template>
                      <span>{{$i18n.t('History.pay_tooltip')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom  v-else-if="item.row_status == 'paid'" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" v-bind="attrs" v-on="on" >done_all</v-icon>
                      </template>
                      <span>{{$i18n.t('History.paid_tooltip')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom  v-else-if="item.row_status == 'paif'" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red"  v-bind="attrs" v-on="on" @click="Repay(item.buy_no)">not_interested</v-icon>
                      </template>
                      <span>{{$i18n.t('History.paif_tooltip')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom  v-else-if="item.row_status == 't'" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning"  v-bind="attrs" v-on="on" >local_shipping</v-icon>
                      </template>
                      <span>{{$i18n.t('History.shipping_product')}}</span>
                    </v-tooltip>

                    <v-icon color="success" v-else-if="item.row_status == 'y'">done_all</v-icon>
                    <v-icon color="error" v-else>close</v-icon>
                  </template>
                  <template v-slot:[`item.detail`]="{item}">
                      <v-icon style="color: green" @click="ShowBuyDetail(item.buy_no)" v-if="item.BuyList.length">visibility</v-icon>
                      <v-icon style="color: orange" v-else>visibility_off</v-icon>
                  </template>
                </v-data-table>
              </div>
              <div v-else>
                <div v-if="CheckIf_LoadingData">
                  <v-sheet
                      color="rgb(241, 241, 241)">
                      <v-skeleton-loader
                          class="mx-auto"
                          max-width="300"
                          type="article"
                          style="padding-top: 20px"
                      ></v-skeleton-loader>
                  </v-sheet>
                </div>
                <div v-else>
                    <div class="text-center">
                        <h1 class="No-Data">{{$i18n.t('ProductType.No_Data')}}</h1>
                    </div>
                </div>
            </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog" fullscreen v-if="DetailList.length">
      <v-card>
        <v-card-title style="background: #1396AB;">
          <v-icon color="white" @click="dialog = false" left>arrow_back</v-icon>
          <h3 class="white--text"><strong>{{$t('History.Detail.Title')}}</strong></h3>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="dialog = false">close</v-icon>
        </v-card-title>
        <v-card-text style="position: relative;">
          <div>
            <div class="text-right pt-5 pb-5"><b>{{$i18n.t('History.Detail.approve_date')}}:</b>
              <span v-if="DetailList[0]['approve_date']">
                {{DetailList[0]["approve_date"].split('T')[0]}}
              </span> 
              <span v-if="DetailList[0]['approve_time']">
                : {{DetailList[0]["approve_time"].split('T')[0]}}
              </span>
            </div>
            <v-row>
              <v-col cols="12" md="8" sm="8" xs="12">
                <div>
                  <span><b>{{$i18n.t('History.Detail.cus_name')}}:</b></span>
                  <span class="pl-2">{{DetailList[0]['cus_name']}}</span>
                </div>
                <div>
                  <span><b>{{$i18n.t('History.Detail.cus_contry')}}:</b></span>
                  <span class="pl-2">{{($i18n.locale == 'la')?DetailList[0]['country_la']:DetailList[0]['country_en']}}</span>
                </div>
                <div>
                  <span><b>{{$i18n.t('History.Detail.cus_address')}}:</b></span>
                  <span class="pl-2">{{($i18n.locale == 'la')?DetailList[0]['country_la']:DetailList[0]['country_en']}}</span>
                </div>
                <div>
                  <span><b>{{$i18n.t('History.Detail.cus_tel')}}:</b></span>
                  <span class="pl-2">{{($i18n.locale == 'la')?DetailList[0]['tel']:DetailList[0]['tel']}}</span>
                </div>
                <div>
                  <span><b>{{$i18n.t('History.Detail.cus_email')}}:</b></span>
                  <span class="pl-2">{{($i18n.locale == 'la')?DetailList[0]['email']:DetailList[0]['email']}}</span>
                </div>
              </v-col>

              <v-col cols="12" md="4" sm="4" xs="12">
                <div class="dhl-item my-text-right">
                  <span><b>{{$i18n.t('History.Detail.dhl_destination')}}:</b></span>
                  <span class="pl-2">{{DetailList[0]['dhl_destination']}}</span>
                </div>
                <div class="dhl-item my-text-right">
                  <span><b>{{$i18n.t('History.Detail.dhl_tracking')}}:</b></span>
                  <span class="pl-2">{{DetailList[0]['dhl_tracking']}}</span>
                </div>
                <div class="dhl-item my-text-right">
                  <span><b>{{$i18n.t('History.Detail.dhl_fee')}}:</b></span>
                  <span class="pl-2">${{DetailList[0]['dhl_fee']}}</span>
                </div>
                <div class="dhl-item my-text-right">
                  <span><b>{{$i18n.t('History.Detail.credit_status')}}:</b></span>
                    <span  class="orange--text pl-3" v-if="DetailList[0]['credit_status'] == 'p'">{{$i18n.t('History.Detail.credit_process')}}</span>
                    <span  class="orange--text pl-3" v-else-if="DetailList[0]['credit_status'] == 't'">{{$i18n.t('History.Detail.credit_transport')}}</span>
                    <span class="green--text pl-3" v-else-if="DetailList[0]['credit_status'] == 'y'">{{$i18n.t('History.Detail.credit_success')}}</span>
                    <span class="red--text pl-3" v-else>{{$i18n.t('History.Detail.credit_cencel')}}</span>

                </div>
                <div class="dhl-item my-text-right">
                  <span><b>{{$i18n.t('History.Detail.approve_comment')}}:</b></span>
                  <span class="pl-2">{{DetailList[0]['approve_comment']}}</span>
                </div>
              </v-col>
            </v-row>
          </div>
         <div v-if="DetailList.length">
             <v-data-table :headers="TB_HisDetailHeader" :items="DetailList[0]['BuyList']">
                <template v-slot:[`item.subprice`]="{item}">
                    {{item.qty * item.price}}
                </template>
                <template v-slot:[`item.qty`]="{item}">
                    {{item.qty}} {{item.unit_la}}
                </template>
                <template v-slot:[`item.row_date`]="{item}">
                    {{item.row_date}}: {{item.row_time}}
                </template>
              </v-data-table>
         </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="paymentDialog" fullscreen v-if="OrderDetail.length">
      <v-card>
        <v-card-title style="background: #1396AB;">
          <v-icon color="white" @click="paymentDialog = false" left>arrow_back</v-icon>
          <h3 class="white--text"><strong>{{$t('History.payment.Title')}}</strong></h3>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="paymentDialog = false">close</v-icon>
        </v-card-title>
        <v-card-text >
          <v-stepper v-model="stepper" class="my-stepper">
              <v-stepper-header style="background-color: white">
                  <v-stepper-step :complete="stepper > 1" step="1">
                  {{$i18n.t('Delivery.step1.customerInfo')}}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepper > 2" step="2">
                    {{$i18n.t('Delivery.step2.normalization')}}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepper >= 3" step="3">
                    {{$i18n.t('Delivery.step3.payment')}}
                  </v-stepper-step>
              </v-stepper-header>
              
              <v-stepper-items class="v-stepper-item">
                  <v-stepper-content step="1" class="pa-0">
                      <div class="section">
                        <div style="position: relative;">
                          <div class="pa-5">
                            <h1 class="text-center">{{$t('History.payment.payment_title')}}</h1>
                          </div>
                          <div>
                            <div class="text-right pt-5 pb-5"><b>{{$i18n.t('History.payment.approve_date')}}:</b>
                              <span v-if="OrderDetail[0]['approve_date']">
                                {{OrderDetail[0]["approve_date"].split('T')[0]}}
                              </span>
                              <span v-else>
                              ---
                              </span>

                              <span v-if="OrderDetail[0]['approve_time']">
                                : {{OrderDetail[0]["approve_time"].split('T')[0]}}
                              </span>
                              <span v-else>
                                : ---
                              </span>
                            </div>
                            <v-row>
                              <v-col cols="12" md="8" sm="8" xs="12">
                                <div>
                                  <span><b>{{$i18n.t('History.payment.cus_name')}}:</b></span>
                                  <span class="pl-2">{{OrderDetail[0]['cus_name']}}</span>
                                </div>
                                <div>
                                  <span><b>{{$i18n.t('History.payment.cus_contry')}}:</b></span>
                                  <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['country_la']:OrderDetail[0]['country_en']}}</span>
                                </div>
                                <div>
                                  <span><b>{{$i18n.t('History.payment.cus_address')}}:</b></span>
                                  <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['country_la']:OrderDetail[0]['country_en']}}</span>
                                </div>
                                <div>
                                  <span><b>{{$i18n.t('History.payment.cus_tel')}}:</b></span>
                                  <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['tel']:OrderDetail[0]['tel']}}</span>
                                </div>
                                <div>
                                  <span><b>{{$i18n.t('History.payment.cus_email')}}:</b></span>
                                  <span class="pl-2">{{($i18n.locale == 'la')?OrderDetail[0]['email']:OrderDetail[0]['email']}}</span>
                                </div>
                              </v-col>

                              <v-col cols="12" md="4" sm="4" xs="12">
                                <div class="dhl-item my-text-right">
                                  <span><b>{{$i18n.t('History.payment.dhl_destination')}}:</b></span>
                                  <span class="pl-2">{{OrderDetail[0]['dhl_destination']}}</span>
                                </div>
                                <div class="dhl-item my-text-right">
                                  <span><b>{{$i18n.t('History.payment.dhl_tracking')}}:</b></span>
                                  <span class="pl-2">{{OrderDetail[0]['dhl_tracking']}}</span>
                                </div>
                                <div class="dhl-item my-text-right">
                                  <span><b>{{$i18n.t('History.payment.dhl_fee')}}:</b></span>
                                  <span class="pl-2 red--text"><u>${{OrderDetail[0]['dhl_fee']}}</u></span>
                                </div>
                                <div class="dhl-item my-text-right">
                                  <span><b>{{$i18n.t('History.payment.credit_status')}}:</b></span>
                                    <span  class="orange--text pl-3" v-if="OrderDetail[0]['row_status'] == 'pay'">{{$i18n.t('History.Detail.waitting_to_payment')}}</span>
                                    <span  class="orange--text pl-3" v-else-if="OrderDetail[0]['credit_status'] == 't'">{{$i18n.t('History.Detail.credit_transport')}}</span>
                                    <span class="green--text pl-3" v-else-if="OrderDetail[0]['credit_status'] == 'y'">{{$i18n.t('History.Detail.credit_success')}}</span>
                                    <span class="red--text pl-3" v-else>{{$i18n.t('History.Detail.credit_cencel')}}</span>

                                </div>
                                <div class="dhl-item my-text-right">
                                  <span><b>{{$i18n.t('History.Detail.approve_comment')}}:</b></span>
                                  <span class="pl-2">{{OrderDetail[0]['approve_comment']}}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        <div>
                            <v-data-table :headers="TB_HisDetailHeader" :items="OrderDetail[0]['BuyList']">
                                <template v-slot:[`item.subprice`]="{item}">
                                    {{item.qty * item.price}}
                                </template>
                                <template v-slot:[`item.qty`]="{item}">
                                    {{item.qty}} {{item.unit_la}}
                                </template>
                                <template v-slot:[`item.row_date`]="{item}">
                                    {{item.row_date}}: {{item.row_time}}
                                </template>
                              </v-data-table>
                        </div>
                        <div>
                          <form action="https://testsecureacceptance.cybersource.com/oneclick/pay" target="payment_iframe" method="post">
                        <div>
                            <input type="hidden" name="access_key" required :value="paymentForm.access_key">	
                            <input type="hidden" name="profile_id" required :value="paymentForm.profile_id"> 
                            <input type="hidden" name="transaction_uuid" required :value="paymentForm.transaction_uuid">	
                            <input type="hidden" name="signed_date_time" required :value="paymentForm.signed_date_time">	
                            <input type="hidden" name="locale" required :value="paymentForm.locale">	
                            <input type="hidden" name="transaction_type" required :value="paymentForm.transaction_type">	
                            <input type="hidden" name="reference_number" required :value="paymentForm.reference_number">	
                            <input type="hidden" name="currency" required :value="paymentForm.currency">	
                            <input type="hidden" name="device_fingerprint_id" required :value="paymentForm.device_fingerprint_id">	
                            <input type="hidden" name="amount" required :value="paymentForm.amount">	
                            <input type="hidden" name="bill_to_address_country" required :value="paymentForm.bill_to_address_country">	
                            <input type="hidden" name="bill_to_forename" required :value="paymentForm.bill_to_forename">	
                            <input type="hidden" name="bill_to_surname" required :value="paymentForm.bill_to_surname">	
                            <input type="hidden" name="bill_to_email" required :value="paymentForm.bill_to_email">	
                            <input type="hidden" name="bill_to_phone" required :value="paymentForm.bill_to_phone">	
                            <input type="hidden" name="bill_to_address_city" required :value="paymentForm.bill_to_address_city">	
                            <input type="hidden" name="bill_to_address_line1" required :value="paymentForm.bill_to_address_line1">	
                            <input type="hidden" name="bill_to_address_postal_code" required :value="paymentForm.bill_to_address_postal_code">	
                            <input type="hidden" name="merchant_secure_data1" required :value="paymentForm.merchant_secure_data1">	
                            <input type="hidden" name="merchant_secure_data2" required :value="paymentForm.merchant_secure_data2">	
                            <input type="hidden" name="merchant_secure_data3" required :value="paymentForm.merchant_secure_data3">	
                            <input type="hidden" name="signed_field_names" required :value="paymentForm.signed_field_names">	
                            <input type="hidden" name="signature" required :value="paymentForm.signature">	
                        </div>
                        <input ref="btnPayment" type="submit" value="" style="position: absolute; z-index: 0; opacity: 0;">
                    </form>
                        </div>
                        <div class="pt-5 pb-5 text-right">
                          <v-btn class="warning" style="width: 200px; margin-right: 20px" @click="paymentDialog = false"> {{$i18n.t("History.payment.cancel_btn")}} </v-btn>
                          <v-btn class="primary" style="width: 200px" @click="Paymentprocess()">{{$i18n.t("History.payment.pay_btn")}} </v-btn>
                        </div>
                        </div>
                      </div>
                  </v-stepper-content>

                  <v-stepper-content step="2" class="pa-0">
                      <div class="section">
                        <v-row style="background-color: #f5f5f5">
                          <v-col cols="12">
                              <div class="payment-content pl-2 pr-2">
                                  <h2 style="text-align: center; padding-top: 20px">{{$i18n.t('Delivery.step3.GuestCheckOut_title')}}</h2>
                                  <hr />
                                  <div class="pa-2" style="text-align: center;">
                                      <div>
                                          <img src="@/assets/card/visa.png" width="50px" style="margin-right: 10px;">
                                          <img src="@/assets/card/mastercard.png" width="50px" style="margin-right: 10px;">
                                          <img src="@/assets/card/jcb.png" width="50px" style="margin-right: 10px;">
                                          <img src="@/assets/card/amex.png" width="50px">
                                      </div>
                                      <iframe name="payment_iframe" style="width: 100%; height: 900px;"></iframe>
                                      <v-row v-if="paymentFailed">
                                          <v-col>
                                              <v-btn color="error" block large @click="cancelOrder()">
                                                  <v-icon left>delete_outline</v-icon>
                                                    {{$i18n.t('Delivery.step3.clearBtn')}}
                                              </v-btn>
                                          </v-col>
                                          <v-col>
                                              <v-btn color="warning" block large @click="repay()">
                                                  <v-icon left>replay</v-icon>
                                                    {{$i18n.t('Delivery.step3.repaymentBtn')}}
                                              </v-btn>
                                          </v-col>
                                      </v-row>
                                  </div>
                              </div>
                          </v-col>
                      </v-row>
                  </div>
                  </v-stepper-content>

                  <v-stepper-content step="3" class="pa-0">
                      <div class="section">
                        
                      </div>
                  </v-stepper-content>
              </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog> -->

     <v-dialog  persistent  v-model="resDialog" v-if="responeMessage['message']" width="350">
        <v-card>
            <v-card-title  :style="responeMessage['success'] ? 'background: #4caf50;color: white;' : 'background:#d32f2f;color: white;' " >Message</v-card-title>
            <v-card-text>
            <h3 class="pt-5">{{ responeMessage["message"]['la'] }}</h3>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                small
                color="success"
                @click="resDialog = !resDialog"
                style="width: 100px">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

     <!-- <v-dialog  persistent  v-model="payConfirmDialog" width="350">
        <v-card>
            <v-card-title style="background-color: orange; color: white">{{$i18n.t('History.repayTitle')}}</v-card-title>
            <v-card-text>
            <h2 class="pt-5 text-center">{{ $i18n.t('History.payment_confirm_text') }}</h2>
            <div class=" mt-5 text-center">
            <v-btn
                small
                color="primary"
                class="mr-3 rounded-0"
                @click="NavigateToPayment('/order/payment',pay_no)"
                style="width: 100px"> {{ $i18n.t('History.Ok_btn') }}</v-btn>

                <v-btn
                small
                color="error"
                @click="payConfirmDialog = !payConfirmDialog"
                class="rounded-0"
                style="width: 100px;">
                  {{ $i18n.t('History.cancel_btn') }}
                </v-btn>

            </div>
            </v-card-text>
        </v-card>
    </v-dialog> -->

     <v-dialog  persistent  v-model="RepayDialog" width="350">
        <v-card>
            <v-card-title style="background-color: orange; color: white">{{$i18n.t('History.repayTitle')}}</v-card-title>
            <v-card-text>
            <h2 class="pt-5 text-center">{{ $i18n.t('History.repayText') }}</h2>
            <div class=" mt-5 text-center">
            <v-btn
                small
                color="primary"
                class="mr-3 rounded-0"
                @click="NavigateToPayment('/order/payment',repay_no)"
                style="width: 100px"> {{ $i18n.t('History.Ok_btn') }}</v-btn>

                <v-btn
                small
                color="error"
                @click="RepayDialog = !RepayDialog"
                class="rounded-0"
                style="width: 100px;">
                  {{ $i18n.t('History.cancel_btn') }}
                </v-btn>

            </div>
            </v-card-text>
        </v-card>
    </v-dialog>
  </v-container>


</template>
<script>
import { mapActions, mapGetters } from "vuex";
import tsu7socket from '@/config/socketio.js';
import server from '@/config/server.js';
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
        dialog: false,
        DetailList:[],
        OrderDetail:[],
        stepper:1,
        CheckIf_LoadingData: true,
        paymentDialog: false,
        resDialog : false,
        progressLoading : false,
        paymentFailed: false,
        payConfirmDialog: false,
        RepayDialog: false,
        pay_no: '',
        repay_no: '',
        responeMessage: {},
        DeliveryInfo: {
                cusId:"",
                cusName:"",
                cusFirstName: "",
                cusLastName: "",
                cusCountryCode:"",
                cusCity: "",
                cusAddress:"",
                postalcode:"",
                cusTel:"",
                cusEmail:"",
                cusDeviceId:"",
                orderId:"",
                dhlFee:"",
                dhl_tracking:"",
                approve_date:"",
                approve_time:"",
                buyDetail: []
            },
        paymentForm: {
                "access_key": "uiueiurie",
                "profile_id": "",
                "transaction_uuid": "",
                "signed_date_time": "",
                "locale": "",
                "transaction_type": "",
                "reference_number": "",
                "currency": "",
                "device_fingerprint_id": "",
                "amount": "",
                "bill_to_address_country": "",
                "bill_to_forename": "",
                "bill_to_surname": "",
                "bill_to_email": "",
                "bill_to_phone": "",
                "bill_to_address_city": "",
                "bill_to_address_line1": "",
                "bill_to_address_postal_code": "",
                "merchant_secure_data1": "",
                "merchant_secure_data2": "",
                "merchant_secure_data3": "",
                "signed_field_names": "",
                "signature": ""
            },
    };
  },
    created(){
      window.scrollTo(0,0);
      if(this.HistoryById.length == 0){ this.GetHistoryByID(this.token);}
      if(window.innerWidth <= 599){  $('.dhl-item').removeClass('my-text-right');} else{$('.dhl-item').addClass('my-text-right');}
    },

    mounted (){
        window.addEventListener("resize", function(){
            if(window.innerWidth <= 599){
               $('.dhl-item').removeClass('my-text-right');
            }else{
               $('.dhl-item').addClass('my-text-right');
            }
        });
  },

  computed: {
    ...mapGetters({
            HistoryById:"history/HistoryById",
            HistoryByDevice:"history/HistoryByDevice",
            CustomerProfile: 'generalInfo/CustomerProfile',
            token:"generalInfo/Token",
        }),

    TB_ByDeviceHeader() {
      var headers = [
        { text: this.$t("History.buyNo"), value: "buy_no" },
        { text: this.$t("History.buyProduct"), value: "product_detail", sortable: false},
        { text: this.$t("History.totalQty"), value: "total_qty"},
        { text: this.$t("History.totalFee"), value: "total_price"},
        { text: this.$t("History.orderDate"), value: "row_date", sortable: false },
        { text: this.$t("History.dhlDate"), value: "dhl_date", sortable: false },
        { text: this.$t("History.approve_date"), value: "approve_date", sortable: false},
        { text: this.$t("History.row_status"), value: "row_status", sortable: false },
        { text: this.$t("History.Option"), value: "detail", sortable: false },
      ];
      return headers;
    },

    TB_HisDetailHeader() {
      var headers = [
        { text: this.$t("History.Detail.ShopName"), value: "shop_la" },
        { text: this.$t("History.Detail.Product"), value: "product_la", sortable: false},
        { text: this.$t("History.Detail.Category"), value: "category_la"},
        { text: this.$t("History.Detail.Type"), value: "type_la", sortable: false},
        { text: this.$t("History.Detail.Quantity"), value: "qty", sortable: false },
        { text: this.$t("History.Detail.Price"), value: "price", sortable: false },
        { text: this.$t("History.Detail.SubPrice"), value: "subprice", sortable: false },
        { text: this.$t("History.Detail.approve_date"), value: "row_date", sortable: false },
      ];
      return headers;
    },

    myOrderHis() {
      var _his = [];
      if (this.HistoryById.length > 0) {
            _his = this.HistoryById;
      }else{
          if (this.HistoryByDevice.length > 0) {
                _his = this.HistoryByDevice;
            }
      }
      this.CheckIf_LoadingData = false;
      return _his;
    },

    UserInfo(){
      if(this.CustomerProfile.length){
          var bytes = CryptoJS.AES.decrypt(this.CustomerProfile, server['cryptoJSkey']);
          var CusInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          // console.log(CusInfo);
          if(CusInfo["cus_id"]){
            return CusInfo;
          }else{
              return '';
          }
      }
    },
  },

  methods: {
    ...mapActions("history",["GetHistoryByID"]),
    ...mapActions("payment",["OrderPayment"]),
      ShowBuyDetail(buy_id) {
          this.DetailList = [];
          this.DetailList = this.myOrderHis.filter((obj)=>obj.buy_no == buy_id);
          this.dialog = true;
      },
    getDate(rowDate) {
        if(!rowDate) {
          return '...';
        }
        var date = new Date(rowDate);
        return (
            (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            (date.getMonth() + 1 >= 10
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
            "/" + date.getFullYear()
        );
    },
    getTime(timestamp){
        if(!timestamp) {
          return '...';
        }
        var date = new Date(timestamp);
        return ((date.getHours() >= 10)?date.getHours():'0'+date.getHours()) +":"+
            ((date.getMinutes() >= 10)?date.getMinutes():'0'+date.getMinutes()) +":"+
            ((date.getSeconds() >= 10)?date.getSeconds():'0'+date.getSeconds());
    },
    navigateToDHL_Tracking(_tracking) {
      window.open('https://www.dhl.com/en/express/tracking.html?AWB='+_tracking+'&brand=DHL', "_blank");
    },

    NavigateToPayment(url, buy_no){
           this.OrderDetail = [];
          this.OrderDetail = this.myOrderHis.filter((obj)=>obj.buy_no == buy_no);
          this.DeliveryInfo.cusId = this.OrderDetail[0]["cus_id"];
          this.DeliveryInfo.cusName = this.OrderDetail[0]["cus_name"];
          this.DeliveryInfo.cusFirstName = this.OrderDetail[0]["cus_name"].split(' ')[0];
          this.DeliveryInfo.cusLastName = this.OrderDetail[0]["cus_name"].split(' ')[1];
          this.DeliveryInfo.cusCountryCode = this.OrderDetail[0]["country_code"];
          this.DeliveryInfo.cusCity = "1";
          this.DeliveryInfo.cusAddress = this.OrderDetail[0]["address"];
          this.DeliveryInfo.postalcode = "123";
          this.DeliveryInfo.cusTel = this.OrderDetail[0]["tel"];
          this.DeliveryInfo.cusEmail = this.OrderDetail[0]["email"];
          this.DeliveryInfo.cusDeviceId = "nsdkjfksjsdkfsfsdfnvskdf";
          this.DeliveryInfo.orderId = this.OrderDetail[0]["buy_no"];
          this.DeliveryInfo.dhlFee = this.OrderDetail[0]["dhl_fee"];
          this.DeliveryInfo.dhl_tracking = this.OrderDetail[0]["dhl_tracking"];
          this.DeliveryInfo.buyDetail = this.OrderDetail[0]["BuyList"];
          localStorage.setItem('orderDetail', JSON.stringify(this.OrderDetail));
          localStorage.setItem('deliveryinfo', JSON.stringify(this.DeliveryInfo));
          this.$router.push({path: url});
    },

    ShowPaymentDialog(buy_no){
      this.pay_no = buy_no;
      this.payConfirmDialog = true
    },
    Repay(buy_no){
      this.repay_no = buy_no;
      this.RepayDialog = true
    },
    
  },
};
</script>

<style scoped>
.content-header {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
}

.profile-side {
  text-align: center;
  padding: 5px;
  border: solid rgb(199, 198, 198) 1px;
}
.profile-info {
  font-size: 16px;
  padding: 5px;
  text-align: left;
}
.shop-img1 {
  text-align: center;
  width: 100%;
}

.person-icon {
  font-size: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profile-property {
  color: green;
  margin-left: 5px;
  font-size: 14px;

}

.my-text-right{
  text-align: right;
}

.No-Data{
      padding: 20%;
      opacity: 0.2;
  }
  

@media screen and (max-width: 500px) {
  .person-icon {
    font-size: 100px;
  }
  .shop-img1 {
    text-align: center !important;
    width: 100%;
  }
  .profile-info {
    font-size: 14px;
    padding: 5px;
    text-align: left;
  }
}
.my-stepper{
  background-color: #f5f5f5;
  margin-bottom: 60px;
  height: 100%;
  margin-top:10px
}

.section{
        background-color: white;
        margin-top: 20px;
        padding: 10px;
        box-shadow: 0px 1px 3px 0px grey;
    }
</style>
