import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'


import device from '@/config/device.js'
device.registerDevice()

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: " AIzaSyBXoeOmRRzSl0b4iUBadAz8M-_Zva0DpfQ",
    libraries: "places", // necessary for places input
  }
});

import 'material-design-icons-iconfont/dist/material-design-icons.css' // Icons:  https://fonts.google.com/icons?selected=Material+Icons
import './styles/global.css'
import VueMobileDetection from 'vue-mobile-detection'


Vue.use(VueAxios, axios);
Vue.use(VueMobileDetection)
Vue.config.productionTip = false

new Vue({
  icons: {
    iconfont: 'md',
  },
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
