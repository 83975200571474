import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import ProductDetail from '../components/Product/ProductDetail.vue'
import Delivery from '../components/Delivery/Delivery.vue'
import ShoppingCart from '../components/Cart/ShoppingCart.vue'
import AllProduct from '../components/Product/AllProduct.vue'
import ProductType from '../components/Product/ProductType.vue'
import BestSellerProduct from '../components/Product/BestSellerProduct.vue'
import LatestProduct from '../components/Product/LatestProduct.vue'
import AllRecommendProduct from '../components/Product/AllRecommendProduct.vue'
import User from '../components/User/User.vue'
import OrderHistory from '../components/History/OrderHistory.vue'
import SearchProduct from '../components/Search/SearchProduct.vue'
import AllShop from '../components/Shop/AllShop.vue'
import MyShop from '../components/Shop/MyShop.vue'
import AboutUs from '../components/AboutUs/AboutUs.vue'
import Policy from '../components/Policy/Policy.vue'
import Payment from '../components/payment/Payment.vue'

Vue.use(VueRouter)
const routes = [
  {
    path:"/",
    component: Main,
    children:[
      {path:"", component: Home},
      { path:"product/detail/:productId", component: ProductDetail},
      { path:"product/all", component: AllProduct},
      { path:"product/type", component: ProductType},
      { path:"product/best/seller", component: BestSellerProduct},
      { path:"product/latest", component: LatestProduct},
      { path:"product/recommend", component: AllRecommendProduct},
      { path:"order/process", component: Delivery},
      { path:"order/cart", component: ShoppingCart},
      { path:"order/history", component: OrderHistory},
      { path:"order/payment/", component: Payment},
      { path:"search/result", component: SearchProduct},
      { path:"shop", component: AllShop},
      { path:"shop/:shop_id", component: MyShop},
      { path:"user", component: User},
      { path:"about", component: AboutUs},
      { path:"policy", component: Policy},
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
