<template>
    <div>
        <v-container>
            <div class="pa-2">
                <h2 class="TimeNewRoman-Phetsarath-OT"><u>{{$i18n.t('Policy.title')}}</u></h2>
                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">1. {{$i18n.t('Policy.introduction')}}</h3>
                    <div class="text-wrap">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.introd_paragraph')}}
                        </div>
                    </div>
                </div>
                <br>
                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">2. {{$i18n.t('Policy.Use_website')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.the_website_des')}}
                        </div>
                    </div>
                    <div class="text-wrap pt-1 pl-5">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT':'TimeNewRoman-Phetsarath-OT'">
                           <b class="TimeNewRoman-Phetsarath-OT">1)</b> {{$i18n.t('Policy.the_website_item1')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT':'TimeNewRoman-Phetsarath-OT'">
                           <b class="TimeNewRoman-Phetsarath-OT">2)</b> {{$i18n.t('Policy.the_website_item2')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT':'TimeNewRoman-Phetsarath-OT'">
                           <b class="TimeNewRoman-Phetsarath-OT">3)</b> {{$i18n.t('Policy.the_website_item3')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT':'TimeNewRoman-Phetsarath-OT'">
                           <b class="TimeNewRoman-Phetsarath-OT">4)</b> {{$i18n.t('Policy.the_website_item4')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">3. {{$i18n.t('Policy.user_input_info')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.user_input_item1')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.user_input_item2')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">4. {{$i18n.t('Policy.Acceptance')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Acceptance_item1')}}
                        </div>
                    </div>
                     <div class="text-wrap pt-1">
                         <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Acceptance_item2')}}
                        </div>
                    </div>
                </div>
                <br>
                
                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">5. {{$i18n.t('Policy.Price')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Price_item1')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">6. {{$i18n.t('Policy.Product_description')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Product_des_item1')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">7. {{$i18n.t('Policy.Payment_and_refund')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.payment_refund_item1')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.payment_refund_item2')}}
                        </div>
                        <br>

                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.payment_refund_item3')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">8. {{$i18n.t('Policy.Commercial_website')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Commercial_web_item1')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">9. {{$i18n.t('Policy.Conflict_resolution')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Conflict_res_item1')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">10. {{$i18n.t('Policy.Governing_Law')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Governing_Law_item')}}
                        </div>
                    </div>
                </div>
                <br>

                <div class="pl-5">
                    <h3 class="TimeNewRoman-Phetsarath-OT">11. {{$i18n.t('Policy.Termination')}}</h3>
                    <div class="text-wrap pt-1">
                        <div :class="($i18n.locale == 'la')?'TimeNewRoman-Phetsarath-OT tab-first-line':'TimeNewRoman-Phetsarath-OT'">
                            {{$i18n.t('Policy.Termination_item1')}}
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </v-container>
        <div>
            <Footer />
        </div>
    </div>
</template>
<script>
import Footer from'../../views/Footer'
export default {
     components:{
        Footer
    },
  data() {
    return {}
  },
  created(){
      window.scrollTo(0,0);
  }
}
</script>
<style scoped>
    .stsu-logo{
        margin-left: 40%;
    }
    .stsu-logo b{
        /* margin-left: 9px; */
        font-size: 20px;
    }
     .tab-first-line{
         text-indent: 25px;
    }
</style>