<template>
    <div  style="background-color: #f5f5f5">
        <v-container>
                <v-stepper v-model="stepper"  style="background-color: #f5f5f5; margin-bottom: 60px; height: 100%;">
                    <v-stepper-header style="background-color: white">
                        <v-stepper-step :complete="stepper > 1" step="1">
                        {{$i18n.t('Delivery.step1.customerInfo')}}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="stepper > 2" step="2">
                         {{$i18n.t('Delivery.step2.normalization')}}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="stepper >= 3" step="3">
                         {{$i18n.t('Delivery.step3.orderSuccess')}}
                        </v-stepper-step>
                    </v-stepper-header>
                    
                    <v-stepper-items class="pa-0">
                        <v-stepper-content step="1" class="pa-0">
                            <div>
                                <div class="section">
                                    <h3 class="TimeNewRoman-Phetsarath-OT">{{$i18n.t('Delivery.step1.Delivery_Option')}}</h3>
                                    <div class="pt-2 pb-0 text-center">
                                        <v-img src="@/assets/images/dhl-logo.jpg" width="100px" style="margin-left: 45%"></v-img>
                                    </div>
                                    <div class="pl-5 pr-5 pt-2 TimeNewRoman-Phetsarath-OT text-center">
                                        {{$i18n.t("Delivery.step1.Delivery_description")}}
                                    </div>
                                </div>
                                <div class="section">
                                    <h3>{{$i18n.t('Delivery.step1.delivery_info')}}</h3>
                                    <div class="container">
                                        <v-row dense>
                                            <v-col cols="12" md="12">
                                                <v-row dense>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field  
                                                            v-model="DeliveryInfo.cusFirstName"
                                                            :label="$i18n.t('Delivery.step1.firstName')"
                                                            :rules="($i18n.locale == 'la')?cusFirstName_LA:cusFirstName_EN"
                                                            outlined
                                                            dense
                                                            require
                                                            ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field 
                                                            v-model="DeliveryInfo.cusLastName"
                                                            :label="$i18n.t('Delivery.step1.lastName')" 
                                                            :rules="($i18n.locale == 'la')?cusLastName_LA:cusLastName_EN"
                                                            outlined  
                                                            dense 
                                                            require
                                                            ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            v-model="DeliveryInfo.cusCountryCode"
                                                            :items="Country?Country:[]" 
                                                            :item-text="($i18n.locale == 'la')?'country_la':'country_en'"
                                                            item-value="country_code" 
                                                            :label="$i18n.t('Delivery.step1.Country')"
                                                            :rules="($i18n.locale == 'la')?cusCountryCode_LA:cusCountryCode_EN"
                                                            class="product-unit-box"
                                                            outlined
                                                            dense  
                                                            required
                                                            >
                                                        </v-autocomplete> 
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field 
                                                            v-model="DeliveryInfo.cusCity"
                                                            :label="$i18n.t('Delivery.step1.city')"
                                                            :rules="($i18n.locale == 'la')?cusCity_LA:cusCity_EN" 
                                                            outlined
                                                            dense
                                                            require
                                                            ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-textarea
                                                            v-model="DeliveryInfo.cusAddress"
                                                            :label="$i18n.t('Delivery.step1.Address')"
                                                            :rules="($i18n.locale == 'la')?cusAddress_LA:cusAddress_EN"
                                                            outlined
                                                            dense
                                                            require
                                                            rows="2"
                                                            row-height="20"
                                                             ></v-textarea>
                                                    </v-col>
                                                    <v-col>
                                                         <v-text-field 
                                                            v-model="DeliveryInfo.postalcode"
                                                            :label="$i18n.t('Delivery.step1.postalcode')" 
                                                            :rules="($i18n.locale == 'la')?postalCode_LA:postalCode_EN"
                                                            outlined 
                                                            dense 
                                                            require
                                                            ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field 
                                                             v-model="DeliveryInfo.cusTel" 
                                                            :label="$i18n.t('Delivery.step1.Phone')" 
                                                            :rules="($i18n.locale == 'la')?cusTel_LA:cusTel_EN"
                                                            outlined
                                                            dense 
                                                            require
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field 
                                                            v-model="DeliveryInfo.cusEmail"
                                                            :label="$i18n.t('Delivery.step1.Email')" 
                                                            :rules="($i18n.locale == 'la')?cusEmail_LA:cusEmail_EN"
                                                            outlined
                                                            dense
                                                            require
                                                            ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <form action="https://testsecureacceptance.cybersource.com/oneclick/pay" target="payment_iframe" method="post">
                                                            <div>
                                                                <input type="hidden" name="access_key" required :value="paymentForm.access_key">	
                                                                <input type="hidden" name="profile_id" required :value="paymentForm.profile_id"> 
                                                                <input type="hidden" name="transaction_uuid" required :value="paymentForm.transaction_uuid">	
                                                                <input type="hidden" name="signed_date_time" required :value="paymentForm.signed_date_time">	
                                                                <input type="hidden" name="locale" required :value="paymentForm.locale">	
                                                                <input type="hidden" name="transaction_type" required :value="paymentForm.transaction_type">	
                                                                <input type="hidden" name="reference_number" required :value="paymentForm.reference_number">	
                                                                <input type="hidden" name="currency" required :value="paymentForm.currency">	
                                                                <input type="hidden" name="device_fingerprint_id" required :value="paymentForm.device_fingerprint_id">	
                                                                <input type="hidden" name="amount" required :value="paymentForm.amount">	
                                                                <input type="hidden" name="bill_to_address_country" required :value="paymentForm.bill_to_address_country">	
                                                                <input type="hidden" name="bill_to_forename" required :value="paymentForm.bill_to_forename">	
                                                                <input type="hidden" name="bill_to_surname" required :value="paymentForm.bill_to_surname">	
                                                                <input type="hidden" name="bill_to_email" required :value="paymentForm.bill_to_email">	
                                                                <input type="hidden" name="bill_to_phone" required :value="paymentForm.bill_to_phone">	
                                                                <input type="hidden" name="bill_to_address_city" required :value="paymentForm.bill_to_address_city">	
                                                                <input type="hidden" name="bill_to_address_line1" required :value="paymentForm.bill_to_address_line1">	
                                                                <input type="hidden" name="bill_to_address_postal_code" required :value="paymentForm.bill_to_address_postal_code">	
                                                                <input type="hidden" name="merchant_secure_data1" required :value="paymentForm.merchant_secure_data1">	
                                                                <input type="hidden" name="merchant_secure_data2" required :value="paymentForm.merchant_secure_data2">	
                                                                <input type="hidden" name="merchant_secure_data3" required :value="paymentForm.merchant_secure_data3">	
                                                                <input type="hidden" name="signed_field_names" required :value="paymentForm.signed_field_names">	
                                                                <input type="hidden" name="signature" required :value="paymentForm.signature">	
                                                            </div>
                                                            <input ref="btnPayment" type="submit" value="" style="position: absolute; z-index: 0; opacity: 0;">
                                                        </form>
                                                    </v-col>
                                                    <v-col md="12">
                                                        <v-row dense>
                                                            <v-col  md="6" sm="6" xs="6">
                                                                <v-btn color="warning" class="order-btn rounded-0" @click="$router.back()" block small> 
                                                                    <v-icon left>arrow_back</v-icon> 
                                                                    {{$i18n.t('Delivery.step1.Back_btn')}}                                                                        
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col  md="6" sm="6" xs="6">
                                                                <v-btn color="primary" class="order-btn rounded-0" 
                                                                    @click="NextStep(2)"  
                                                                    :disabled="!validStep1"
                                                                    :loading="progressLoading"
                                                                    small
                                                                    block>
                                                                    <v-icon left>arrow_forward</v-icon>
                                                                    {{$i18n.t('Delivery.step1.next_btn')}}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="pa-0">
                            <div class="section">
                                <div>
                                    <div class="shop-info">
                                        <div style="font-size: 20px;">
                                            <b>{{$i18n.t('Delivery.step2.7tsuInfo')}}</b>
                                            <br />
                                        </div>
                                        <div class="_border">
                                            <v-row>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.7tsuAddress')}}: </b> {{$i18n.t('Delivery.step2.Address')}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.7tsu_tel')}}: </b>
                                                    (+856) 20 55 529 908
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.7tsu_fax')}}: </b>
                                                    (+856) 55529908
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.7tsu_email')}}: </b>
                                                    7Tsu_company@gmail.com
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="order-info">
                                        <div style="font-size: 20px;">
                                            <b>{{$i18n.t('Delivery.step2.delivery_info')}}</b>
                                        </div>
                                        <div class="_border">
                                            <v-row v-if="DeliveryInfo.cusId && Country.length > 0">
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.cus_fullName')}}: </b> {{DeliveryInfo.cusFirstName +' '+ DeliveryInfo.cusLastName}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12" v-if="Country.length > 0">
                                                    <b>{{$i18n.t('Delivery.step2.country')}}: </b>
                                                    {{ ($i18n.locale == 'la')?Country.filter((c)=> c.country_code == DeliveryInfo.cusCountryCode)[0]["country_la"]:Country.filter((c)=> c.country_code == DeliveryInfo.cusCountryCode)[0]["country_en"] }}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.cus_city')}}: </b>
                                                    {{DeliveryInfo.cusCity}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.cus_address')}}: </b>
                                                    {{DeliveryInfo.cusAddress}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.postalCode')}}: </b>
                                                    {{DeliveryInfo.postalcode}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.cus_tel')}}: </b>
                                                    {{DeliveryInfo.cusTel}}
                                                </v-col>
                                                <v-col cols="12" md="6" sm="6" xs="12">
                                                    <b>{{$i18n.t('Delivery.step2.cus_email')}}: </b>
                                                    {{DeliveryInfo.cusEmail}}
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="order-info">
                                        <div style="font-size: 20px;">
                                            <b>{{$i18n.t('Delivery.step2.cus_cartList')}}</b>
                                        </div>
                                        <div>
                                            <v-data-table :headers="tbl_header" :items="OrderList"  hide-default-footer>
                                                <template v-slot:[`item.productId`]="{ item }">
                                                    <v-img :aspect-ratio="16/9"  :src="server_ip + item['imageList'][0]" width="50px"></v-img>
                                                </template>
                                                    <template v-slot:[`item.productName`]="{ item }">
                                                        <span>
                                                            {{($i18n.locale == 'la')?item["product_la"]:item["product_en"]}}
                                                        </span>
                                                </template>
                                                <template v-slot:[`item.unit`]="{ item }">
                                                    <span v-if="item['unitList'].length == 1">{{($i18n.locale=='la')?item['unitList'][0]['unit_la']:item['unitList'][0]['unit_en']}}</span>
                                                    <span v-else>...</span>
                                                </template>
                                                <template v-slot:[`item.price`]="{ item }">
                                                    <span>${{item["price"]}}</span>
                                                </template>
                                                <template v-slot:[`item.subTotalPrice`]="{item}">
                                                    <span class="green--text">${{item["quantity"] * item["price"]}}</span>
                                                </template>
                                            </v-data-table>
                                            <div class="text-right pr-10">
                                                <b>{{$i18n.t('Delivery.step2.totalPrice')}} : <span class="green--text"> ${{AllProductPrice}}</span></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="pl-2 pr-2">
                                    <div class="pa-5">
                                        <v-row dense>
                                            <v-col  md="6" sm="6" xs="6">
                                                <v-btn 
                                                    color="warning" 
                                                    class="order-btn rounded-0" 
                                                    @click="stepper = 1" 
                                                    block 
                                                    small
                                                    :disabled="progressLoading"
                                                    > 
                                                        <v-icon left>arrow_back</v-icon> 
                                                        {{$i18n.t('Delivery.step1.Back_btn')}}                                                                        
                                                </v-btn>
                                            </v-col>
                                            <v-col  md="6" sm="6" xs="6">
                                                <v-btn 
                                                    color="primary" 
                                                    class="order-btn rounded-0" 
                                                    @click="doConfirm()"  
                                                    :disabled="!validStep1"
                                                    small
                                                    block
                                                    :loading="progressLoading"
                                                    >
                                                        <v-icon left>touch_app</v-icon>
                                                        {{$i18n.t('Delivery.step2.order_btn')}}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="pa-0">
                            <div class="section">
                                <div class="text-center">
                                    <v-icon style="color: orange; font-size:50px">circle_notifications</v-icon>
                                    <p>
                                        {{$i18n.t('Delivery.step3.Noti_title')}}
                                    </p>
                                </div>
                                <div>
                                      {{$i18n.t('Delivery.step3.Noti_text')}}
                                </div>

                                 <div class="section">
                                    <div style="padding: 10px">
                                        <div class="">
                                            <h2 style="text-align: center">{{$i18n.t('Delivery.step4.OrderTitle')}}</h2>
                                            <v-row>
                                                <v-col cols="6">
                                                    <div class="TimeNewRoman-Phetsarath-OT"><b><u>7TSU</u></b></div>
                                                    <div class="TimeNewRoman-Phetsarath-OT">{{$i18n.t("Delivery.step4.tsu7Pro")}}</div>
                                                    <div>{{$i18n.t("Delivery.step4.tsu7Dis")}}</div>
                                                    <div>{{$i18n.t("Delivery.step4.tsu7Vil")}}</div>
                                                </v-col>
                                                <v-col class="text-right" cols="6">
                                                    <div style="position: relative; text-align: right">
                                                       <b><u>{{$i18n.t('Delivery.step4.CusInfo')}}</u></b>
                                                    </div>
                                                    <div style="position: relative; text-align: right">
                                                        {{$i18n.t("Delivery.step4.OrderDate")}}: {{getDate(Date.now())}} {{getTime(Date.now())}}
                                                    </div>

                                                    <div style="position: relative; text-align: right">
                                                        {{$i18n.t("Delivery.step4.cusname")}}:
                                                        {{DeliveryInfo.cusName}}
                                                    </div>
                                                    <div style="position: relative; text-align: right">
                                                        {{$i18n.t("Delivery.step4.custel")}}:
                                                        {{DeliveryInfo.cusTel}}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_no")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productName")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productGroup")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productType")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productQuantity")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productPrice")}}</th>
                                                        <th class="text-center">{{$i18n.t("Delivery.step4.tb_productSubPrice")}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="Category.length > 0 && Submenu.length > 0">
                                                    <tr v-for="(cardList, index) in ShoppingCart" :key="index">
                                                        <td class="text-center">{{index + 1}}</td>
                                                        <td class="text-center">{{($i18n.locale=='la')?cardList["product_la"]:cardList["product_en"]}}</td>
                                                        <td class="text-center">{{($i18n.locale=='la')?Category.filter((obj)=>obj.cate_id == cardList['category_id'])[0]["category_la"]:Category.filter((obj)=>obj.cate_id == cardList['category_id'])[0]["category_en"]}}</td>
                                                        <td class="text-center">{{($i18n.locale=='la')?Submenu.filter((obj)=>obj.type_id == cardList['type_id'])[0]["type_la"]:Submenu.filter((obj)=>obj.type_id == cardList['type_id'])[0]["type_en"]}}</td>
                                                        <td class="text-center">{{ cardList["quantity"] }}  {{($i18n.locale=='la')?cardList["unitList"].filter((obj)=>obj.unit_no === cardList['unitNo'])[0]["unit_la"]:cardList["unitList"].filter((obj)=>obj.unit_no === cardList['unitNo'])[0]["unit_en"]}}</td>
                                                        <td class="text-center">{{ cardList["price"] }}</td>
                                                        <td class="text-center">{{ parseInt(cardList["quantity"]) * parseInt(cardList["price"]) }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <hr />
                                        <div class="text-right mt-5">
                                            <span class="mr-15 "><b>{{$i18n.t('Delivery.step4.TotalPrice')}}:</b> <span class="red--text">${{AllProductPrice}}</span></span>
                                        </div>
                                        <div class="text-right pr-2 pt-10">
                                            <v-btn 
                                                @click="doPrint_PDF('#print_content', '#printBill')" 
                                                color="primary" outlined  width="150"
                                                class="mr-2">
                                                {{$i18n.t('Delivery.step4.PrintPDF')}}
                                            </v-btn>
                                            <v-btn @click="orderSuccess()" color="success" outlined width="150">
                                                {{$i18n.t('Delivery.step4.successBtn')}}
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>

                                <div id="print_content">
                                    <div id="printBill" style="padding: 10px">
                                        <div>
                                            <div>
                                                <h2 style="text-align: center">{{$i18n.t('Delivery.step4.OrderTitle')}}</h2>
                                                <v-row>
                                                    <v-col cols="6">
                                                        <div class="TimeNewRoman-Phetsarath-OT"><b><u>7TSU</u></b></div>
                                                        <div class="TimeNewRoman-Phetsarath-OT">{{$i18n.t("Delivery.step4.tsu7Pro")}}</div>
                                                        <div>{{$i18n.t("Delivery.step4.tsu7Dis")}}</div>
                                                        <div>{{$i18n.t("Delivery.step4.tsu7Vil")}}</div>
                                                    </v-col>
                                                    <v-col class="text-right" cols="6">
                                                        <div style="position: relative; text-align: right">
                                                        <b><u>{{$i18n.t('Delivery.step4.CusInfo')}}</u></b>
                                                        </div>
                                                        <div style="position: relative; text-align: right">
                                                            {{$i18n.t("Delivery.step4.OrderDate")}}: {{getDate(Date.now())}} {{getTime(Date.now())}}
                                                        </div>

                                                        <div style="position: relative; text-align: right">
                                                            {{$i18n.t("Delivery.step4.cusname")}}:
                                                            {{DeliveryInfo.cusName}}
                                                        </div>
                                                        <div style="position: relative; text-align: right">
                                                            {{$i18n.t("Delivery.step4.custel")}}:
                                                            {{DeliveryInfo.cusTel}}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_no")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productName")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productGroup")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productType")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productQuantity")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productPrice")}}</th>
                                                            <th class="text-center">{{$i18n.t("Delivery.step4.tb_productSubPrice")}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="Category.length > 0 && Submenu.length > 0">
                                                        <tr v-for="(cardList, index) in ShoppingCart" :key="index">
                                                            <td class="text-center">{{index + 1}}</td>
                                                            <td class="text-center">{{($i18n.locale=='la')?cardList["product_la"]:cardList["product_en"]}}</td>
                                                            <td class="text-center">{{($i18n.locale=='la')?Category.filter((obj)=>obj.cate_id == cardList['category_id'])[0]["category_la"]:Category.filter((obj)=>obj.cate_id == cardList['category_id'])[0]["category_en"]}}</td>
                                                            <td class="text-center">{{($i18n.locale=='la')?Submenu.filter((obj)=>obj.type_id == cardList['type_id'])[0]["type_la"]:Submenu.filter((obj)=>obj.type_id == cardList['type_id'])[0]["type_en"]}}</td>
                                                            <td class="text-center">{{ cardList["quantity"] }}  {{($i18n.locale=='la')?cardList["unitList"].filter((obj)=>obj.unit_no === cardList['unitNo'])[0]["unit_la"]:cardList["unitList"].filter((obj)=>obj.unit_no === cardList['unitNo'])[0]["unit_en"]}}</td>
                                                            <td class="text-center">{{ cardList["price"] }}</td>
                                                            <td class="text-center">{{ parseInt(cardList["quantity"]) * parseInt(cardList["price"]) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <hr />
                                            <div class="mt-5" style="text-align: right;">
                                                <span class="mr-15 "><b>{{$i18n.t('Delivery.step4.TotalPrice')}}:</b> <span class="red--text">${{AllProductPrice}}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                
                
                <v-dialog :width="$isMobile()?'350':'320'" v-model="OrderConfirmDialog" >
                    <v-card class="rounded-0">
                        <v-card-title style="background-color: #1396ab; color:white">
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="OrderConfirmDialog = !OrderConfirmDialog">close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div class="text-center pa-7">
                                <h2>{{$i18n.t('Delivery.step2.ConfirmText')}}</h2>
                                
                            </div>
                            <hr>
                            <div class="text-center mt-2">
                                <v-btn color="warning" width="100" class="rounded-0 mr-2" @click="OrderConfirmDialog = !OrderConfirmDialog">{{$i18n.t('Delivery.step2.cancel_btn')}}</v-btn>
                                <v-btn color="primary" width="100" class="rounded-0" @click="doSaveOrder()">{{$i18n.t('Delivery.step2.confirm_btn')}}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog width="300" v-model="OrderFailedDialog">
                    <v-card >
                        <v-card-title style="background-color: red; color:white">
                            {{$i18n.t('AddCardDialog.CardTitle')}}
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="OrderFailedDialog = !OrderFailedDialog" left>mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pa-10 text-center">
                            <v-icon color="red" class="mt-3 mb-3">warning</v-icon>
                            <h3 class="red--text">{{$i18n.t("Delivery.ErrorText")}}</h3>
                            <div class="pt-5">
                                <v-btn color="red" outlined @click="OrderFailedDialog = !OrderFailedDialog">{{$i18n.t("Delivery.dialog_btn")}}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog width="300" v-model="OrderSuccessDialog">
                    <v-card >
                        <v-card-title style="background-color: green; color:white">
                            <!-- {{$i18n.t('Delivery.step2.order_success_dialog_title')}} -->
                            <v-spacer></v-spacer>
                            <v-icon color="white"  @click="OrderSuccessDialog = !OrderSuccessDialog" left >mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pa-10 text-center">
                            <v-icon color="green" class="mt-3 mb-3" style="font-size: 60px">done_all</v-icon>
                            <h2 class="green--text">{{$i18n.t("Delivery.step2.orderSuccess")}}</h2>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog  persistent  v-model="resDialog" v-if="responeMessage['message']" width="350">
                    <v-card>
                        <v-card-title  :style="responeMessage['success'] ? 'background: #4caf50;color: white;' : 'background:#d32f2f;color: white;' " >Message</v-card-title>
                        <v-card-text>
                        <h3 class="pt-5">{{ responeMessage["message"]['la'] }}</h3>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            small
                            color="success"
                            @click="closeResDialog()"
                            style="width: 100px">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-container>
    </div>
</template>
<script>
import { mapActions ,mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import server from '@/config/server.js'
import tsu7socket from '@/config/socketio.js'
export default {
    data() {
        return {
            date: new Date().toISOString().substr(0, 7),
            menu: false,
            modal: false,
            stepper:1,
            server_ip: server['_7tsuAPI'],
            isFormValid: false,
            OrderSuccessDialog: false,
            OrderFailedDialog: false,
            OrderConfirmDialog: false,
            CheckOutSuccess: false,
            checkDialog: false,
            resDialog: false,
            progressLoading: false,
            resRequest: false,
            checkRequest: false,
            responeMessage: {},
            paymentFailed: false,
            orderStatusSuccess:false,
            userInfo:{},
            DeliveryInfo: {
                cusId:"",
                cusName:"",
                cusFirstName: "",
                cusLastName: "",
                cusCountryCode:"",
                cusCity: "",
                cusAddress:"",
                postalcode:"",
                cusTel:"",
                cusEmail:"",
                cusDeviceId:"",
                buyDetail:[]
            },
            DirectBuyCart:[],
            paymentForm: {
                "access_key": "uiueiurie",
                "profile_id": "",
                "transaction_uuid": "",
                "signed_date_time": "",
                "locale": "",
                "transaction_type": "",
                "reference_number": "",
                "currency": "",
                "device_fingerprint_id": "",
                "amount": "",
                "bill_to_address_country": "",
                "bill_to_forename": "",
                "bill_to_surname": "",
                "bill_to_email": "",
                "bill_to_phone": "",
                "bill_to_address_city": "",
                "bill_to_address_line1": "",
                "bill_to_address_postal_code": "",
                "merchant_secure_data1": "",
                "merchant_secure_data2": "",
                "merchant_secure_data3": "",
                "signed_field_names": "",
                "signature": ""
            },


            cusFirstName_LA:       [v => !!v || 'ຊື່ຫ້າມວ່າງ'],
            cusLastName_LA:       [v => !!v || 'ນາມສະກຸນຫ້າມວ່າງ'],
            cusCountryCode_LA: [v => !!v || 'ປະເທດຫ້າມວ່າງ'],
            cusCity_LA: [v => !!v || 'ຕົວເມືອງຫ້າມຫວ່າງ'],
            cusAddress_LA:       [v => !!v || 'ທີ່ຢູ່ຫ້າມຫວ່າງ'],
            postalCode_LA:       [v => !!v || 'ລະຫັດປາຍສະນີຫ້າມຫວ່າງ'],
            cusTel_LA:          [v => !!v || 'ໂທລະສັບຫ້າມວ່າງ'],
            cusEmail_LA: [
                    v => !!v || 'ອີເມວຫ້າມວ່າງ',
                    v => /.+@.+\..+/.test(v) || 'ບໍ່ຖືກຕ້ອງ',
                ],

            cusFirstName_EN:       [v => !!v || 'First name is required'],
            cusLastName_EN:       [v => !!v || 'Last name is required'],
            cusCountryCode_EN: [v => !!v || 'Delever country required'],
            cusCity_EN: [v => !!v || 'City is required'],
            cusAddress_EN:       [v => !!v || 'Address is required'],
            postalCode_EN:           [v => !!v || 'Postal code is required'],
            cusTel_EN:          [v => !!v || 'Phone is required'],
            cusEmail_EN: [
                    v => !!v || 'Email Is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
            model: 1,
        }
    },

     computed: {
     ...mapGetters({
            ShoppingCart:"shoppingCart/ShoppingCart",
            DirectCart:"directcheckOut/MyDirectCart",
            Country:"generalInfo/Country",
            Token: 'generalInfo/Token',
            CustomerProfile: 'generalInfo/CustomerProfile',
            Category:"menus/category",
            Submenu:"menus/submenu",
        }),
      
        validStep1(){
            return  (
                    this.DeliveryInfo["cusCountryCode"].trim() &&
                    // this.DeliveryInfo["cusName"].trim() &&
                    this.DeliveryInfo["cusFirstName"].trim() &&
                    this.DeliveryInfo["cusLastName"].trim() &&
                    this.DeliveryInfo["cusCity"].trim() &&
                    this.DeliveryInfo["cusAddress"].trim() &&
                    this.DeliveryInfo["postalcode"].trim() &&
                    this.DeliveryInfo["cusTel"].trim() &&
                    this.DeliveryInfo["cusEmail"].trim())
            },
        AllProductPrice(){
            var totalPrice = 0;
            if(this.ShoppingCart.length > 0){
                this.DirectBuyCart = [];
                this.ClearDirectCart('');
                for(var i=0; i < this.ShoppingCart.length; i++){
                    totalPrice += this.ShoppingCart[i]["quantity"] * this.ShoppingCart[i]["price"]
                }
            }else{
                if(this.DirectCart.length > 0){
                    const DCart = JSON.parse(CryptoJS.AES.decrypt(this.DirectCart, server["cryptoJSkey"]).toString(CryptoJS.enc.Utf8));
                    this.DirectBuyCart = DCart;
                    for(var i=0; i < DCart.length; i++){
                        totalPrice += DCart[i]["quantity"] * DCart[i]["price"]
                    }
                }
            }
            return totalPrice;
        },
        OrderList(){
            var List = [];
            if(this.ShoppingCart.length > 0){
                this.DirectBuyCart = [];
                this.ClearDirectCart('');
                List = this.ShoppingCart;
            }else{
                if(this.DirectCart.length > 0){
                    List = JSON.parse(CryptoJS.AES.decrypt(this.DirectCart, server["cryptoJSkey"]).toString(CryptoJS.enc.Utf8));
                }
            }
            return List;
        },
        tbl_header(){
            const header = [
                { text: this.$t("Delivery.step2.cus_pro_img"), value: "productId" , sortable: false},
                { text: this.$t("Delivery.step2.pro_name"), value: "productName", sortable: false},
                { text: this.$t("Delivery.step2.unit"), value: "unit", sortable: false},
                { text: this.$t("Delivery.step2.quantity"), value: "quantity", sortable: false},
                { text: this.$t("Delivery.step2.price"), value: "price", sortable: false },
                { text: this.$t("Delivery.step2.subTotalPrice"), value: "subTotalPrice", sortable: false }
            ];
            return header;
        }
    },

    created(){
        window.scrollTo(0,0);
        if(!this.ShoppingCart.length && !this.DirectCart.length) {
            this.$router.back();
            return;
        }
        if(this.Token && this.CustomerProfile){
            var bytes = CryptoJS.AES.decrypt(this.CustomerProfile, server['cryptoJSkey']);
            if(bytes){
                this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                this.DeliveryInfo.cusId = this.userInfo["cus_id"];
                this.DeliveryInfo.cusCountryCode = this.userInfo["country_code"];
                this.DeliveryInfo.cusName = this.userInfo["cus_name"];
                this.DeliveryInfo.cusFirstName = this.userInfo["first_name"];
                this.DeliveryInfo.cusLastName = this.userInfo["last_name"];
                this.DeliveryInfo.cusCity = this.userInfo["city"];
                this.DeliveryInfo.cusTel = this.userInfo["tel"];
                this.DeliveryInfo.cusAddress = this.userInfo["address"];
                this.DeliveryInfo.cusEmail = this.userInfo["email"];
                this.DeliveryInfo.cusDeviceId = this.userInfo["device_id"]?this.userInfo["device_id"]: (localStorage.getItem("uuid")?localStorage.getItem("uuid"):'');
            }
        }
        
        /*
        else{
            var bytes = CryptoJS.AES.decrypt(this.CustomerProfile, server['cryptoJSkey']);
            this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(this.userInfo)
            this.DeliveryInfo.cusId = this.userInfo["cus_id"]?this.userInfo["cus_id"]:"";
            this.DeliveryInfo.cusCountryCode = this.userInfo["country_code"]?this.userInfo["country_code"]:"";
            this.DeliveryInfo.cusName = this.userInfo["cus_name"]?this.userInfo["cus_name"]:"";
            this.DeliveryInfo.cusFirstName = this.userInfo["first_name"]?this.userInfo["first_name"]:"";
            this.DeliveryInfo.cusLastName = this.userInfo["last_name"]?this.userInfo["last_name"]:"";
            this.DeliveryInfo.cusCity = this.userInfo["city"]?this.userInfo["city"]:"";
            this.DeliveryInfo.cusTel = this.userInfo["tel"]?this.userInfo["tel"]:"";
            this.DeliveryInfo.cusAddress = this.userInfo["address"]?this.userInfo["address"]:"";
            this.DeliveryInfo.cusEmail = this.userInfo["email"]?this.userInfo["email"]:"";
            this.DeliveryInfo.cusDeviceId = this.userInfo["device_id"]?this.userInfo["device_id"]:localStorage.getItem("uuid")?localStorage.getItem("uuid"):'';
        }
        
        */ 
        // this.DeliveryInfo.cusDeviceId = localStorage.getItem("uuid")?localStorage.getItem("uuid"):'';
        // tsu7socket.on('/7tsu/real/payment/client/listening/123',(payResponse) => {
        //     console.log(payResponse)
        // })
    },

    destroyed(){
        if(this.orderStatusSuccess ==  true){
            if(this.ShoppingCart.length > 0){
                this.ClearCard([]);
            }else{
                this.ClearDirectCart('');
            }
        }
    },

    
    methods: {
        ...mapActions("order",["OrderProduct"]),
        ...mapActions("shoppingCart",["ClearCard","DoCartStatus"]),
        ...mapActions("generalInfo",["SetUserPageSide","SetUserProfile"]),
        ...mapActions("directcheckOut",["ClearDirectCart"]),
        MyStepper(stepper) {
            this.stepper = stepper;
        },
        orderSuccess() {
            this.paymentFailed = false;
            this.ClearCard([]);
            this.$router.push({ path: '/'});
            this.stepper = 1;
        },
        cancelOrder() {
            this.paymentFailed = false;
            this.ClearCard([]);
            this.$router.push({ path: '/'})
        },
        repay() {
            // this.$refs.btnPayment.click();
            this.paymentFailed = false;
            this.stepper = 1;
        },

        doConfirm() {
            this.OrderConfirmDialog = true;
        },

        doSaveOrder() {
            if(this.validStep1) {
                if(!this.checkRequest) {
                    this.checkRequest = true;
                    this.progressLoading = true;
                    this.OrderConfirmDialog = false;
                    this.DeliveryInfo.cusName = this.DeliveryInfo.cusFirstName + ' ' + this.DeliveryInfo.cusLastName;
                    if(this.ShoppingCart.length > 0){
                        this.DeliveryInfo.buyDetail = this.ShoppingCart;
                    }
                    else if(this.DirectBuyCart.length > 0){
                        this.DeliveryInfo.buyDetail = this.DirectBuyCart;
                    }
                    this.OrderProduct(this.DeliveryInfo).then((res)=>{
                        const Respon = res["data"];
                        this.responeMessage = res["data"];
                        if(Respon["success"] == true && Respon["code"]  == 200){
                            this.OrderSuccessDialog = true;
                            this.DoCartStatus(false); // hide badge cart and display empty cart icon
                             setTimeout(() => {
                                this.OrderSuccessDialog = false;
                                this.stepper = 3;
                                this.orderStatusSuccess = true;   // this check if order success then clear cart on destroyed
                            }, 1500);

                        }else{
                            this.OrderFailedDialog = false;
                            this.resDialog = true;
                            this.progressLoading = false;
                            this.checkRequest = false;
                        }
                    }).catch(() => {
                        this.responeMessage = {
                            success: false, 
                            code: 500, 
                            massage:{
                                la:'ຂາດການເຊື່ອມຕໍ່ລະບົບ',
                                en: 'Connect to server failed'
                            }
                        };
                        this.resDialog = true;
                        this.progressLoading = false;
                        this.checkRequest = false;
                    });
                }
            } else {
                this.responeMessage = {
                    success: false, 
                    code: 401, 
                    massage:{
                        la:'ຂໍ້ມູນບໍ່ຄົບຖ້ວນ',
                        en: 'Invalid some information'
                    }
                };
                this.resDialog = true;
            }
        },
        closeResDialog() {
            if(this.paymentFailed) {
                this.stepper = 1;
            }
            this.responeMessage = {};
            this.resDialog = false;
        },
        getDate(rowDate) {
            if(!rowDate) {
            return '...';
            }
            var date = new Date(rowDate);
            return (
                (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()) +
                "/" +
                (date.getMonth() + 1 >= 10
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1)) +
                "/" + date.getFullYear()
            );
        },
        getTime(timestamp){
            if(!timestamp) {
            return '...';
            }
            var date = new Date(timestamp);
            return ((date.getHours() >= 10)?date.getHours():'0'+date.getHours()) +":"+
                ((date.getMinutes() >= 10)?date.getMinutes():'0'+date.getMinutes()) +":"+
                ((date.getSeconds() >= 10)?date.getSeconds():'0'+date.getSeconds());
        },
        Submit(){
            this.DeliveryInfo.creditExp = this.date;
           // this.DeliveryInfo.buyDetail = this.ShoppingCart; // ນີືອາດແມ່ນຄຳສັ່ງດຽວກັນກັບຢູ່ໃນ doSaveOrder()
            this.OrderProduct(this.DeliveryInfo).then((res)=>{
                const Respon = res["data"];
                if(Respon["success"] == true && Respon["code"]  == 200){
                    this.CheckOutSuccess = true;
                    this.stepper = 3;
                    this.OrderConfirmDialog = false;
                }else{
                    this.OrderFailedDialog = false;
                }
            })
        },

        NextStep(step){
            this.progressLoading = true;
            setTimeout(() => {
                this.progressLoading = false;
                this.stepper = step
            }, 500);
        },

        doPrint_PDF(print_content, printBill) {
            // eslint-disable-next-line no-undef
            $(print_content).css("display", "block");
            // eslint-disable-next-line no-undef
            $(printBill).print({
                globalStyles: true,
                mediaPrint: false,
                stylesheet: null,
                noPrintSelector: ".no-print",
                iframe: true,
                append:  null,
                base: true,
                prepend: null,
                manuallyCopyFormValues: true,
                // eslint-disable-next-line no-undef
                deferred: $.Deferred(),
                timeout: 750,
                title: null,
                doctype: "<!doctype html>",
            });
            // eslint-disable-next-line no-undef
            $(print_content).css("display", "none");
            },

    },
}
</script>

<style scoped>

    .section{
        background-color: white;
        margin-top: 20px;
        padding: 10px;
        box-shadow: 0px 1px 3px 0px grey;
    }

    .divide-div {
        height: 10px;
        background-color: rgba(209, 209, 209, 0.5);
        width: 100%;
        }

    .payment-content{
        background-color: white;
    }

    ._border {
        border: 1px #b8b6b6 solid;
        padding: 10px;
        background-color: rgb(250, 248, 248);
        }

    .summary-amount{
        padding: 10px;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0px 0px 4px grey;
        text-align: center;
    }

    #print_content {
        display: none;
    }

    /* .close-dilog-icon{
        position: absolute;
        right: 0;
        margin-right: 10px;
        z-index: 9;
    } */

</style>