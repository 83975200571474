import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import bcryptjs from 'bcryptjs'
import server from '@/config/server.js'

Vue.use(Vuex)

const ProductDetail = {
  namespaced: true,
  state: {
    ProductDetail:{},
    },
  mutations: {
    SetProductDetail(state, data){
      state.ProductDetail = data
    }
  },
  actions: {
    GetProductDetail(context, pro_id){
       return axios.get( server['_7tsuAPI'] + "/api/7tsu/customer/product/detail/" + pro_id, {
          headers:{
            'Content-Type': 'application/json',
            'secrettoken': bcryptjs.hashSync(server['secrettoken'], 10),
            'maintoken7tsu': bcryptjs.hashSync(server['maintoken7tsu'], 10),
            'atoken7tsu': bcryptjs.hashSync(server['atoken7tsu'], 10),
          }
        })
    }
  },
  modules: {
  },
  getters:{
    productDetail: state => state.ProductDetail
  }
}

export default ProductDetail;
